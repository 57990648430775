import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTimeISO: { input: string; output: string };
  JSON: { input: unknown; output: unknown };
  JSONObject: { input: unknown; output: unknown };
  Upload: { input: unknown; output: unknown };
};

export type Addon = {
  __typename?: 'Addon';
  amount: Scalars['Float']['output'];
  currency: CurrencyEnum;
  id: Scalars['String']['output'];
  metered_quantity?: Maybe<Scalars['Float']['output']>;
};

export type ApiInformation = {
  __typename?: 'ApiInformation';
  key: Scalars['String']['output'];
  uri: Scalars['String']['output'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  api_key: Scalars['String']['output'];
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ApiLog = {
  __typename?: 'ApiLog';
  api_key: Scalars['String']['output'];
  date: Scalars['DateTimeISO']['output'];
  endpoint: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  request: Scalars['String']['output'];
  response: Scalars['String']['output'];
  response_code: Scalars['Float']['output'];
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  country_code?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  municipality?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type BillingAddressInput = {
  country_code?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  municipality?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type BiogenicBreakdown = {
  __typename?: 'BiogenicBreakdown';
  co2: EmissionNumber;
};

export type BuyOffsetting = {
  __typename?: 'BuyOffsetting';
  paymentPageLink: Scalars['String']['output'];
};

export type BuyOffsettingInput = {
  issued_on_behalf_of?: InputMaybe<Scalars['String']['input']>;
  portfolio_id: Scalars['String']['input'];
  redirect_path: Scalars['String']['input'];
  weight_tonnes: Scalars['String']['input'];
};

export enum CalculatorFreightFuelTypeEnum {
  Cng = 'CNG',
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  Lng = 'LNG',
  Lpg = 'LPG',
  Other = 'OTHER',
  Petrol = 'PETROL',
}

export type CalculatorInput = {
  __typename?: 'CalculatorInput';
  id: Scalars['String']['output'];
  input: ComputeFreightEmissions;
};

export enum CalendarInterval {
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR',
}

export type CancelOffsettingInput = {
  order_id: Scalars['String']['input'];
};

export type CarbonplanAssessment = {
  __typename?: 'CarbonplanAssessment';
  created_date: Scalars['DateTimeISO']['output'];
  data_stringify?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type Card = {
  __typename?: 'Card';
  brand: CardBrandEnum;
  expiry_month: Scalars['Int']['output'];
  expiry_year: Scalars['Int']['output'];
  last_four_digits: Scalars['String']['output'];
};

export enum CardBrandEnum {
  AmericanExpress = 'american_express',
  Bancontact = 'bancontact',
  DinersClub = 'diners_club',
  Discover = 'discover',
  Jcb = 'jcb',
  Mastercard = 'mastercard',
  NotApplicable = 'not_applicable',
  Other = 'other',
  Visa = 'visa',
}

export type Client = {
  __typename?: 'Client';
  address_1?: Maybe<Scalars['String']['output']>;
  address_2?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<S3Object>;
  billing_email_address?: Maybe<Scalars['String']['output']>;
  branding?: Maybe<ClientBranding>;
  city?: Maybe<Scalars['String']['output']>;
  client_function?: Maybe<ClientFunctionEnum>;
  config: ClientConfig;
  country?: Maybe<Country>;
  domains: Array<ClientDomain>;
  id: Scalars['ID']['output'];
  legal_name?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<S3Object>;
  offsetting_commission_percentage: Scalars['Float']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  shipment_reference_number_metadata_key: Scalars['String']['output'];
  tax_id_type?: Maybe<TaxIdTypeEnum>;
  tax_id_value?: Maybe<Scalars['String']['output']>;
  users: Array<ClientUser>;
  website?: Maybe<Scalars['String']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type ClientBranding = {
  __typename?: 'ClientBranding';
  accentColour: Scalars['String']['output'];
  brandColour: Scalars['String']['output'];
  /** @deprecated No longer used */
  linkTextColour?: Maybe<Scalars['String']['output']>;
};

export type ClientBrandingInput = {
  accentColour: Scalars['String']['input'];
  brandColour: Scalars['String']['input'];
};

export type ClientConfig = {
  __typename?: 'ClientConfig';
  auto_transshipment: Scalars['Boolean']['output'];
  client: Client;
  created_date: Scalars['DateTimeISO']['output'];
  emission_sharing_visibility: EmissionVisibilityEnum;
  ferry_load_characteristics: FerryLoadCharacteristicsEnum;
  id: Scalars['ID']['output'];
  road_routing_ferry_preference: RoadRoutingFerryPreferenceEnum;
  sea_routing_restricted_areas: Array<SeaRoutingAreaEnum>;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type ClientDomain = {
  __typename?: 'ClientDomain';
  client: Client;
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  team_access: TeamAccessEnum;
};

export enum ClientFunctionEnum {
  Carrier = 'CARRIER',
  FreightForwarder = 'FREIGHT_FORWARDER',
  IndependentSoftwareVendor = 'INDEPENDENT_SOFTWARE_VENDOR',
  Lsp = 'LSP',
  Other = 'OTHER',
  Shipper = 'SHIPPER',
}

export type ClientIntegration = {
  __typename?: 'ClientIntegration';
  id: Scalars['ID']['output'];
  public_id: Scalars['ID']['output'];
  secondary_system?: Maybe<ClientIntegrationSecondarySystemEnum>;
  status: ClientIntegrationStatusEnum;
  status_update_date: Scalars['DateTimeISO']['output'];
  status_update_requested_by: ClientUser;
  system: ClientIntegrationSystemEnum;
};

export type ClientIntegrationLogs = {
  __typename?: 'ClientIntegrationLogs';
  nextToken?: Maybe<Scalars['String']['output']>;
  streams: Array<LogStream>;
};

export enum ClientIntegrationSecondarySystemEnum {
  ChainioCargowise = 'CHAINIO_CARGOWISE',
  ChainioDescartes = 'CHAINIO_DESCARTES',
  ChainioFlexport = 'CHAINIO_FLEXPORT',
  ChainioMagaya = 'CHAINIO_MAGAYA',
  ChainioOther = 'CHAINIO_OTHER',
}

export enum ClientIntegrationStatusEnum {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export enum ClientIntegrationSystemEnum {
  Cargowise = 'CARGOWISE',
  CargowiseProxy = 'CARGOWISE_PROXY',
  Chainio = 'CHAINIO',
  EmailImport = 'EMAIL_IMPORT',
  IleapHost = 'ILEAP_HOST',
  ServerToServerFileExchange = 'SERVER_TO_SERVER_FILE_EXCHANGE',
}

export type ClientMetadataDisplayName = {
  __typename?: 'ClientMetadataDisplayName';
  id: Scalars['ID']['output'];
  metadata_key: Scalars['String']['output'];
  metadata_value: Scalars['String']['output'];
  value_display_name: Scalars['String']['output'];
};

export type ClientPaymentSourceStatusFilter = {
  condition: FilterConditionEnum;
  values: Array<PaymentSourceStatusEnum>;
};

export type ClientRole = {
  __typename?: 'ClientRole';
  id: Scalars['ID']['output'];
  name: ClientRoleEnum;
};

export enum ClientRoleEnum {
  Admin = 'ADMIN',
  Developer = 'DEVELOPER',
  Operations = 'OPERATIONS',
  Owner = 'OWNER',
  Viewer = 'VIEWER',
}

export type ClientSubscription = {
  __typename?: 'ClientSubscription';
  addons?: Maybe<Array<Addon>>;
  id?: Maybe<Scalars['ID']['output']>;
  next_billing_at?: Maybe<Scalars['DateTimeISO']['output']>;
  plan_item_price?: Maybe<PlanItemPrice>;
  status?: Maybe<ClientSubscriptionStatusEnum>;
  trial_end_date?: Maybe<Scalars['DateTimeISO']['output']>;
};

export enum ClientSubscriptionStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Deleted = 'DELETED',
  Future = 'FUTURE',
  InTrial = 'IN_TRIAL',
  NonRenewing = 'NON_RENEWING',
  Paused = 'PAUSED',
}

export type ClientUser = {
  __typename?: 'ClientUser';
  account_state: ClientUserAccountStateEnum;
  avatar?: Maybe<S3Object>;
  created_date: Scalars['DateTimeISO']['output'];
  deleted_date?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  role: ClientRole;
  source: ClientUserSourceEnum;
  updated_date: Scalars['DateTimeISO']['output'];
};

export enum ClientUserAccountStateEnum {
  Authorized = 'AUTHORIZED',
  CreateProfile = 'CREATE_PROFILE',
  Invited = 'INVITED',
  Provisioned = 'PROVISIONED',
  Registered = 'REGISTERED',
  Unauthorized = 'UNAUTHORIZED',
}

export type ClientUserEmailPreference = {
  __typename?: 'ClientUserEmailPreference';
  enabled: Scalars['Boolean']['output'];
  type: ClientUserEmailPreferenceTypeEnum;
};

export type ClientUserEmailPreferenceInput = {
  enabled: Scalars['Boolean']['input'];
  type: ClientUserEmailPreferenceTypeEnum;
};

export enum ClientUserEmailPreferenceTypeEnum {
  WeeklyActivityReport = 'WEEKLY_ACTIVITY_REPORT',
}

export enum ClientUserJobFunctionEnum {
  CustomerServiceAndSupport = 'CUSTOMER_SERVICE_AND_SUPPORT',
  EsgAndRisk = 'ESG_AND_RISK',
  Executive = 'EXECUTIVE',
  Finance = 'FINANCE',
  HrAndOperations = 'HR_AND_OPERATIONS',
  LegalAndCompliance = 'LEGAL_AND_COMPLIANCE',
  Other = 'OTHER',
  Owner = 'OWNER',
  Procurement = 'PROCUREMENT',
  ProductAndTechnology = 'PRODUCT_AND_TECHNOLOGY',
  SalesAndMarketing = 'SALES_AND_MARKETING',
}

export type ClientUserMfa = {
  __typename?: 'ClientUserMFA';
  mfa_enabled?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export enum ClientUserSourceEnum {
  DataCollectionRequest = 'DATA_COLLECTION_REQUEST',
  GoogleSignUp = 'GOOGLE_SIGN_UP',
  Invited = 'INVITED',
  SelfSignUp = 'SELF_SIGN_UP',
}

export type ClientUserTip = {
  __typename?: 'ClientUserTip';
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  status: ClientUserTipStatusEnum;
  type: ClientUserTipTypeEnum;
  updated_date: Scalars['DateTimeISO']['output'];
};

export enum ClientUserTipStatusEnum {
  Active = 'ACTIVE',
  Dismissed = 'DISMISSED',
  Done = 'DONE',
}

export enum ClientUserTipTypeEnum {
  Discover = 'DISCOVER',
  LiveModePrompt = 'LIVE_MODE_PROMPT',
  SwitchToLiveMode = 'SWITCH_TO_LIVE_MODE',
}

export type ComputeFreightEmissions =
  | ComputeFreightEmissionsWithDistanceAndWeightAndVehicle
  | ComputeFreightEmissionsWithLocationsAndWeightAndVehicle;

export type ComputeFreightEmissionsWithDistanceAndWeightAndVehicle = {
  __typename?: 'ComputeFreightEmissionsWithDistanceAndWeightAndVehicle';
  config?: Maybe<FreightShipmentCalculationConfig>;
  containers?: Maybe<Array<FreightContainer>>;
  date?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  movements: Array<MovementWithDistance>;
  track?: Maybe<Scalars['Boolean']['output']>;
  weight: Weight;
};

export type ComputeFreightEmissionsWithLocationsAndWeightAndVehicle = {
  __typename?: 'ComputeFreightEmissionsWithLocationsAndWeightAndVehicle';
  config?: Maybe<FreightShipmentCalculationConfig>;
  containers?: Maybe<Array<FreightContainer>>;
  date?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  movements: Array<MovementWithLocation>;
  track?: Maybe<Scalars['Boolean']['output']>;
  weight: Weight;
};

export type Configuration = {
  __typename?: 'Configuration';
  emission_sharing_visibility?: Maybe<EmissionVisibilityEnum>;
  routing?: Maybe<RoutingConfiguration>;
};

export type ConfirmCsvFileForCalculation = {
  __typename?: 'ConfirmCSVFileForCalculation';
  import_id: Scalars['String']['output'];
};

export type ConfirmOffsettingInput = {
  order_id: Scalars['String']['input'];
  redirect_path: Scalars['String']['input'];
};

export type ContainerWeight = {
  __typename?: 'ContainerWeight';
  unit?: Maybe<ContainerWeightUnit>;
  value: Scalars['Float']['output'];
};

export enum ContainerWeightUnit {
  Kg = 'KG',
  Lbs = 'LBS',
  Tonnes = 'TONNES',
  Tons = 'TONS',
}

export type Country = {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  code_alpha_2: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CountryInput = {
  code: Scalars['String']['input'];
};

export type CreateClient = {
  __typename?: 'CreateClient';
  client: Client;
  redirect_to?: Maybe<Scalars['String']['output']>;
};

export type CreateClientInput = {
  client_function?: InputMaybe<ClientFunctionEnum>;
  google_analytics_client_id?: InputMaybe<Scalars['String']['input']>;
  legal_name: Scalars['String']['input'];
};

export type CreateDataCollectionInput = {
  additional_message?: InputMaybe<Scalars['String']['input']>;
  period_end_date: Scalars['DateTimeISO']['input'];
  period_start_date: Scalars['DateTimeISO']['input'];
  recipients: Array<Scalars['String']['input']>;
  reminders_enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateProfile = {
  __typename?: 'CreateProfile';
  redirect_to?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type CreateProfileInput = {
  first_name: Scalars['String']['input'];
  job_function: ClientUserJobFunctionEnum;
  last_name: Scalars['String']['input'];
  marketing_emails: Scalars['Boolean']['input'];
  origin_url_parameters?: InputMaybe<CreateProfileOriginUrlParameters>;
};

export type CreateProfileOriginUrlParameters = {
  gclid?: InputMaybe<Scalars['String']['input']>;
  li_fat_id?: InputMaybe<Scalars['String']['input']>;
  msclkid?: InputMaybe<Scalars['String']['input']>;
};

export enum CurrencyEnum {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD',
}

/** Embeddable response for dashboards */
export type Dashboards = {
  __typename?: 'Dashboards';
  token: Scalars['String']['output'];
};

export type DataCollection = {
  __typename?: 'DataCollection';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  latest_request_updated_date: Scalars['DateTimeISO']['output'];
  period_end_date: Scalars['DateTimeISO']['output'];
  period_start_date: Scalars['DateTimeISO']['output'];
  public_id: Scalars['String']['output'];
  requests: Array<DataCollectionRequest>;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type DataCollectionRequest = {
  __typename?: 'DataCollectionRequest';
  activities: Array<DataCollectionRequestActivity>;
  created_date: Scalars['DateTimeISO']['output'];
  data_collection: DataCollection;
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  public_id: Scalars['String']['output'];
  recipients: Array<ClientUser>;
  state: DataCollectionRequestStateEnum;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type DataCollectionRequestAcceptedActivity = {
  __typename?: 'DataCollectionRequestAcceptedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestActivity =
  | DataCollectionRequestAcceptedActivity
  | DataCollectionRequestApprovedActivity
  | DataCollectionRequestCancelledActivity
  | DataCollectionRequestDataStagedActivity
  | DataCollectionRequestDeclinedActivity
  | DataCollectionRequestDeliveryFailedActivity
  | DataCollectionRequestRejectedActivity
  | DataCollectionRequestRemindedActivity
  | DataCollectionRequestRequestFailedActivity
  | DataCollectionRequestRequestedActivity
  | DataCollectionRequestSubmittedActivity
  | DataCollectionRequestViewedActivity;

export type DataCollectionRequestApprovedActivity = {
  __typename?: 'DataCollectionRequestApprovedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestCancelledActivity = {
  __typename?: 'DataCollectionRequestCancelledActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestDataStagedActivity = {
  __typename?: 'DataCollectionRequestDataStagedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  emissions_aggregations?: Maybe<EmissionsAggregations>;
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestDeclinedActivity = {
  __typename?: 'DataCollectionRequestDeclinedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestDeliveryFailedActivity = {
  __typename?: 'DataCollectionRequestDeliveryFailedActivity';
  client?: Maybe<Client>;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export enum DataCollectionRequestEventEnum {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  DataStaged = 'DATA_STAGED',
  Declined = 'DECLINED',
  DeliveryFailed = 'DELIVERY_FAILED',
  Rejected = 'REJECTED',
  Reminded = 'REMINDED',
  Requested = 'REQUESTED',
  RequestFailed = 'REQUEST_FAILED',
  Submitted = 'SUBMITTED',
  Viewed = 'VIEWED',
}

export type DataCollectionRequestRejectedActivity = {
  __typename?: 'DataCollectionRequestRejectedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestRemindedActivity = {
  __typename?: 'DataCollectionRequestRemindedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestRequestFailedActivity = {
  __typename?: 'DataCollectionRequestRequestFailedActivity';
  client?: Maybe<Client>;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestRequestedActivity = {
  __typename?: 'DataCollectionRequestRequestedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestReviewData = {
  __typename?: 'DataCollectionRequestReviewData';
  activity_end_date: Scalars['DateTimeISO']['output'];
  activity_start_date: Scalars['DateTimeISO']['output'];
  data_collection_request: DataCollectionRequest;
  emissions_by_lifecycle: Array<EmissionsLifecycleBreakdown>;
  emissions_by_mode: Array<EmissionsModeBreakdown>;
  overall_quality: Scalars['Float']['output'];
  total_activity: Scalars['Float']['output'];
  total_activity_unit: Scalars['String']['output'];
  total_co2e: Scalars['Float']['output'];
  total_intensity: Scalars['Float']['output'];
  total_intensity_unit: Scalars['String']['output'];
  total_shipments: Scalars['Int']['output'];
};

export type DataCollectionRequestReviewInventory = {
  __typename?: 'DataCollectionRequestReviewInventory';
  items: Array<EmissionDocument>;
  pagination_limit: Scalars['Int']['output'];
  total_co2e: Scalars['Float']['output'];
  total_count: Scalars['Int']['output'];
};

export enum DataCollectionRequestStateEnum {
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Done = 'DONE',
  Initial = 'INITIAL',
  InProgress = 'IN_PROGRESS',
  PendingReview = 'PENDING_REVIEW',
  Requested = 'REQUESTED',
}

export type DataCollectionRequestSubmittedActivity = {
  __typename?: 'DataCollectionRequestSubmittedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  emissions_aggregations?: Maybe<EmissionsAggregations>;
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type DataCollectionRequestViewedActivity = {
  __typename?: 'DataCollectionRequestViewedActivity';
  client: Client;
  client_user: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  event: DataCollectionRequestEventEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export enum DataCollectionRequestsSortedColumnEnum {
  Client = 'CLIENT',
  PeriodEndDate = 'PERIOD_END_DATE',
  PeriodStartDate = 'PERIOD_START_DATE',
  State = 'STATE',
  UpdatedDate = 'UPDATED_DATE',
}

export enum DataCollectionSortedColumnEnum {
  Declined = 'DECLINED',
  InProgress = 'IN_PROGRESS',
  PendingReview = 'PENDING_REVIEW',
  PeriodEndDate = 'PERIOD_END_DATE',
  PeriodStartDate = 'PERIOD_START_DATE',
  Status = 'STATUS',
  Suppliers = 'SUPPLIERS',
  UpdatedDate = 'UPDATED_DATE',
}

export type DateRangeInput = {
  endDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  startDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type DirectoryContact = {
  __typename?: 'DirectoryContact';
  directory_entry?: Maybe<DirectoryEntry>;
  email: Scalars['String']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
};

export type DirectoryDomain = {
  __typename?: 'DirectoryDomain';
  domain: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type DirectoryEntry = {
  __typename?: 'DirectoryEntry';
  contacts: Array<DirectoryContact>;
  domains: Array<DirectoryDomain>;
  meta_data: Array<Metadata>;
  name: Scalars['String']['output'];
  network_member?: Maybe<Client>;
  public_id: Scalars['String']['output'];
  references: Array<DirectoryReference>;
};

export type DirectoryEntryMetadataInput = {
  display?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type DirectoryReference = {
  __typename?: 'DirectoryReference';
  id: Scalars['String']['output'];
  reference: Scalars['String']['output'];
};

export type Emission = {
  __typename?: 'Emission';
  activity_normalized?: Maybe<Scalars['Float']['output']>;
  activity_normalized_unit?: Maybe<EmissionFactorUnitEnum>;
  client: Client;
  client_user?: Maybe<ClientUser>;
  co2e: Scalars['Float']['output'];
  context_stringify?: Maybe<Scalars['String']['output']>;
  created_date: Scalars['DateTimeISO']['output'];
  date: Scalars['DateTimeISO']['output'];
  details: Array<EmissionDetail>;
  distance_km?: Maybe<Scalars['Float']['output']>;
  emission_children: Array<Emission>;
  flights?: Maybe<EmissionFlights>;
  freight?: Maybe<EmissionFreight>;
  freight_aggregated?: Maybe<EmissionFreightAggregated>;
  from_display?: Maybe<Scalars['String']['output']>;
  has_parent: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  import_id?: Maybe<Scalars['String']['output']>;
  intensity_normalized?: Maybe<Scalars['Float']['output']>;
  issues?: Maybe<Array<EmissionIssue>>;
  last_mile?: Maybe<EmissionLastMile>;
  level?: Maybe<Scalars['Float']['output']>;
  meta_data?: Maybe<Array<Metadata>>;
  mode: EmissionModeEnum;
  no_of_pax?: Maybe<Scalars['Float']['output']>;
  parent_child_count?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['ID']['output']>;
  parent_public_id?: Maybe<Scalars['String']['output']>;
  parent_quality?: Maybe<EmissionParentQuality>;
  parent_share_token?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  quality?: Maybe<EmissionQuality>;
  ride_hailing?: Maybe<EmissionRideHailing>;
  sequence_no?: Maybe<Scalars['Int']['output']>;
  share_token: Scalars['String']['output'];
  shipment_reference_number_metadata_key: Scalars['String']['output'];
  shipment_reference_number_metadata_value?: Maybe<Scalars['String']['output']>;
  source_type_name: EmissionSourceTypeEnum;
  teu?: Maybe<Scalars['Float']['output']>;
  to_display?: Maybe<Scalars['String']['output']>;
  type: EmissionTypeEnum;
  visibility?: Maybe<EmissionVisibilityEnum>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
};

export type EmissionChildDocument = {
  __typename?: 'EmissionChildDocument';
  activity?: Maybe<Scalars['Float']['output']>;
  activity_normalized?: Maybe<Scalars['Float']['output']>;
  activity_unit?: Maybe<EmissionFactorUnitEnum>;
  activity_unit_normalized?: Maybe<EmissionFactorUnitEnum>;
  co2e: Scalars['Float']['output'];
  date: Scalars['DateTimeISO']['output'];
  distance_km?: Maybe<Scalars['Float']['output']>;
  freight?: Maybe<EmissionFreightDocument>;
  id: Scalars['ID']['output'];
  intensity?: Maybe<Scalars['Float']['output']>;
  intensity_normalized?: Maybe<Scalars['Float']['output']>;
  mode: EmissionModeEnum;
  parent_quality?: Maybe<EmissionParentQuality>;
  public_id: Scalars['ID']['output'];
  quality?: Maybe<EmissionQuality>;
  sequence_no?: Maybe<Scalars['Int']['output']>;
  teu?: Maybe<Scalars['Float']['output']>;
  type: EmissionTypeEnum;
  visibility?: Maybe<EmissionVisibilityEnum>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
};

export type EmissionDetail = {
  __typename?: 'EmissionDetail';
  category?: Maybe<EmissionDetailCategoryEnum>;
  ch4?: Maybe<Scalars['Float']['output']>;
  co2?: Maybe<Scalars['Float']['output']>;
  co2e: Scalars['Float']['output'];
  emission_factor: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  n2o?: Maybe<Scalars['Float']['output']>;
  nmhc?: Maybe<Scalars['Float']['output']>;
  nox?: Maybe<Scalars['Float']['output']>;
  pm?: Maybe<Scalars['Float']['output']>;
  scope?: Maybe<EmissionDetailScopeEnum>;
  so2?: Maybe<Scalars['Float']['output']>;
  subtype?: Maybe<EmissionDetailSubtypeEnum>;
  type?: Maybe<EmissionDetailTypeEnum>;
};

export enum EmissionDetailCategoryEnum {
  BusinessTravel = 'BUSINESS_TRAVEL',
  CapitalGoods = 'CAPITAL_GOODS',
  DownstreamLeasedAssets = 'DOWNSTREAM_LEASED_ASSETS',
  DownstreamTransportAndDistribution = 'DOWNSTREAM_TRANSPORT_AND_DISTRIBUTION',
  EmployeeCommuting = 'EMPLOYEE_COMMUTING',
  EolTreatmentOfSoldProducts = 'EOL_TREATMENT_OF_SOLD_PRODUCTS',
  Franchises = 'FRANCHISES',
  FuelAndEnergyRelated = 'FUEL_AND_ENERGY_RELATED',
  GeneratedWaste = 'GENERATED_WASTE',
  Investments = 'INVESTMENTS',
  ProcessingOfSoldProducts = 'PROCESSING_OF_SOLD_PRODUCTS',
  PurchasedGoodsAndServices = 'PURCHASED_GOODS_AND_SERVICES',
  UpstreamLeasedAssets = 'UPSTREAM_LEASED_ASSETS',
  UpstreamTransportAndDistribution = 'UPSTREAM_TRANSPORT_AND_DISTRIBUTION',
  UseOfSoldProducts = 'USE_OF_SOLD_PRODUCTS',
}

export enum EmissionDetailScopeEnum {
  Scope_1 = 'SCOPE_1',
  Scope_2 = 'SCOPE_2',
  Scope_3 = 'SCOPE_3',
}

export enum EmissionDetailSubtypeEnum {
  Eg = 'EG',
  Etdl = 'ETDL',
  Production = 'PRODUCTION',
}

export enum EmissionDetailTypeEnum {
  Other = 'OTHER',
  Ttw = 'TTW',
  Wtt = 'WTT',
  Wtw = 'WTW',
}

export type EmissionDocument = {
  __typename?: 'EmissionDocument';
  activity?: Maybe<Scalars['Float']['output']>;
  activity_normalized?: Maybe<Scalars['Float']['output']>;
  activity_unit?: Maybe<EmissionFactorUnitEnum>;
  activity_unit_normalized?: Maybe<EmissionFactorUnitEnum>;
  children: Array<EmissionChildDocument>;
  co2e: Scalars['Float']['output'];
  date: Scalars['DateTimeISO']['output'];
  distance_km?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  intensity?: Maybe<Scalars['Float']['output']>;
  intensity_normalized?: Maybe<Scalars['Float']['output']>;
  metadata: Array<Metadata>;
  mode: EmissionModeEnum;
  parent_quality?: Maybe<EmissionParentQuality>;
  public_id: Scalars['ID']['output'];
  quality?: Maybe<EmissionQuality>;
  shipment_reference_number_metadata_key: Scalars['String']['output'];
  shipment_reference_number_metadata_value?: Maybe<Scalars['String']['output']>;
  teu?: Maybe<Scalars['Float']['output']>;
  type: EmissionTypeEnum;
  visibility?: Maybe<EmissionVisibilityEnum>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
  well_known_metadata: WellKnownMetadata;
};

export enum EmissionFactorUnitEnum {
  ContainerMoved = 'CONTAINER_MOVED',
  Km = 'KM',
  PassengerKm = 'PASSENGER_KM',
  TeuKm = 'TEU_KM',
  Tonne = 'TONNE',
  TonneKm = 'TONNE_KM',
}

export enum EmissionFilterPropertiesEnum {
  ActivityType = 'ACTIVITY_TYPE',
  Co2E = 'CO2E',
  DateRange = 'DATE_RANGE',
  DistanceKm = 'DISTANCE_KM',
  ImportId = 'IMPORT_ID',
  Mode = 'MODE',
  PublicId = 'PUBLIC_ID',
  VehicleCode = 'VEHICLE_CODE',
  WeightKg = 'WEIGHT_KG',
}

export type EmissionFlights = {
  __typename?: 'EmissionFlights';
  aircraft?: Maybe<Scalars['String']['output']>;
  airline?: Maybe<Scalars['String']['output']>;
  cabin_class?: Maybe<EmissionFlightsClassEnum>;
  flight_no?: Maybe<Scalars['String']['output']>;
  from_code: Scalars['String']['output'];
  from_coordinates?: Maybe<Geography>;
  from_display?: Maybe<Scalars['String']['output']>;
  no_of_pax: Scalars['Float']['output'];
  route_details?: Maybe<RouteDetails>;
  to_code: Scalars['String']['output'];
  to_coordinates?: Maybe<Geography>;
  to_display?: Maybe<Scalars['String']['output']>;
};

export enum EmissionFlightsClassEnum {
  Business = 'BUSINESS',
  Economy = 'ECONOMY',
  First = 'FIRST',
  PremiumEconomy = 'PREMIUM_ECONOMY',
}

export type EmissionFreight = {
  __typename?: 'EmissionFreight';
  aircraft_code?: Maybe<Scalars['String']['output']>;
  cargo_type: EmissionFreightCargoTypeEnum;
  carriage: EmissionFreightCarriageEnum;
  carrier_code?: Maybe<Scalars['String']['output']>;
  carrier_name?: Maybe<Scalars['String']['output']>;
  emission_standard?: Maybe<EmissionFreightVehicleEmissionStandardEnum>;
  empty_running?: Maybe<Scalars['Float']['output']>;
  flight_no?: Maybe<Scalars['String']['output']>;
  from_address?: Maybe<Scalars['String']['output']>;
  from_code?: Maybe<Scalars['String']['output']>;
  from_coordinates?: Maybe<Geography>;
  from_country_code?: Maybe<Scalars['String']['output']>;
  from_country_name?: Maybe<Scalars['String']['output']>;
  from_display?: Maybe<Scalars['String']['output']>;
  fuel_consumption?: Maybe<Scalars['Float']['output']>;
  fuel_consumption_unit?: Maybe<EmissionFreightFuelConsumptionUnitNormalisedEnum>;
  fuel_identifier?: Maybe<EmissionFreightFuelIdentifier>;
  fuel_type?: Maybe<EmissionFreightFuelTypeEnum>;
  is_refrigerated: Scalars['Boolean']['output'];
  load_factor?: Maybe<Scalars['Float']['output']>;
  load_type?: Maybe<EmissionFreightLoadTypeEnum>;
  route_details?: Maybe<RouteDetails>;
  to_address?: Maybe<Scalars['String']['output']>;
  to_code?: Maybe<Scalars['String']['output']>;
  to_coordinates?: Maybe<Geography>;
  to_country_code?: Maybe<Scalars['String']['output']>;
  to_display?: Maybe<Scalars['String']['output']>;
  vehicle: EmissionFreightVehicleEnum;
  vehicle_category: EmissionFreightVehicleCategoryEnum;
  vehicle_code?: Maybe<Scalars['String']['output']>;
  vessel_id?: Maybe<Scalars['String']['output']>;
  vessel_name?: Maybe<Scalars['String']['output']>;
};

export type EmissionFreightAggregated = {
  __typename?: 'EmissionFreightAggregated';
  distance_km: Scalars['Float']['output'];
  end_date: Scalars['DateTimeISO']['output'];
  fleet_id?: Maybe<Scalars['String']['output']>;
  fuel_consumption: Scalars['Float']['output'];
  fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
  fuel_type: EmissionFreightAggregatedFuelTypeEnum;
  fuel_used: Scalars['Float']['output'];
  fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
  load_factor: Scalars['Float']['output'];
  no_of_vehicles?: Maybe<Scalars['Float']['output']>;
  start_date: Scalars['DateTimeISO']['output'];
  vehicle: EmissionFreightAggregatedVehicleEnum;
  vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
  vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
  vehicle_id?: Maybe<Scalars['String']['output']>;
  weight_kg: Scalars['Float']['output'];
};

export enum EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum {
  KgPer_100Km = 'KG_PER_100_KM',
  KwhPer_100Km = 'KWH_PER_100_KM',
  LPer_100Km = 'L_PER_100_KM',
}

export enum EmissionFreightAggregatedFuelTypeEnum {
  Cng = 'CNG',
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  Lng = 'LNG',
  Lpg = 'LPG',
  Petrol = 'PETROL',
}

export enum EmissionFreightAggregatedFuelUsedUnitNormalisedEnum {
  Kg = 'KG',
  Kwh = 'KWH',
  L = 'L',
}

export enum EmissionFreightAggregatedVehicleCategoryEnum {
  Articulated = 'ARTICULATED',
  AutoCarrier = 'AUTO_CARRIER',
  Default = 'DEFAULT',
  Dray = 'DRAY',
  Expedited = 'EXPEDITED',
  Flatbed = 'FLATBED',
  General = 'GENERAL',
  HeavyBulk = 'HEAVY_BULK',
  Hgv = 'HGV',
  Ltl = 'LTL',
  Mgv = 'MGV',
  Mixed = 'MIXED',
  Moving = 'MOVING',
  Package = 'PACKAGE',
  Refrigerated = 'REFRIGERATED',
  Rigid = 'RIGID',
  Specialized = 'SPECIALIZED',
  Tanker = 'TANKER',
  Tl = 'TL',
  UrbanTruck = 'URBAN_TRUCK',
}

export enum EmissionFreightAggregatedVehicleCodeEnum {
  H = 'H',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  Ha1 = 'HA1',
  Ha2 = 'HA2',
  Ha3 = 'HA3',
  Ha4 = 'HA4',
  Ha5 = 'HA5',
  Hr1 = 'HR1',
  Hr2 = 'HR2',
  Hr3 = 'HR3',
  Hr4 = 'HR4',
  Hr5 = 'HR5',
  NaHac = 'NA_HAC',
  NaHd = 'NA_HD',
  NaHe = 'NA_HE',
  NaHf = 'NA_HF',
  NaHg = 'NA_HG',
  NaHhb = 'NA_HHB',
  NaHltl = 'NA_HLTL',
  NaHmi = 'NA_HMI',
  NaHmo = 'NA_HMO',
  NaHp = 'NA_HP',
  NaHs = 'NA_HS',
  NaHt = 'NA_HT',
  NaHtl = 'NA_HTL',
  V = 'V',
}

export enum EmissionFreightAggregatedVehicleEnum {
  Truck = 'TRUCK',
  Van = 'VAN',
}

export enum EmissionFreightCargoTypeEnum {
  Average = 'AVERAGE',
  Heavy = 'HEAVY',
  Light = 'LIGHT',
}

export enum EmissionFreightCarriageEnum {
  Main = 'MAIN',
  On = 'ON',
  Pre = 'PRE',
}

export enum EmissionFreightDistanceUnitEnum {
  Km = 'KM',
  Mi = 'MI',
  Nm = 'NM',
}

export type EmissionFreightDocument = {
  __typename?: 'EmissionFreightDocument';
  from_address?: Maybe<Scalars['String']['output']>;
  from_code?: Maybe<Scalars['String']['output']>;
  from_coordinates?: Maybe<LatLong>;
  from_display?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  to_address?: Maybe<Scalars['String']['output']>;
  to_code?: Maybe<Scalars['String']['output']>;
  to_coordinates?: Maybe<LatLong>;
  to_display?: Maybe<Scalars['String']['output']>;
};

export enum EmissionFreightFuelConsumptionUnitEnum {
  ImpGalPer_100Mi = 'IMP_GAL_PER_100_MI',
  KgPer_100Km = 'KG_PER_100_KM',
  KwhPer_100Km = 'KWH_PER_100_KM',
  KwhPer_100Mi = 'KWH_PER_100_MI',
  LbsPer_100Mi = 'LBS_PER_100_MI',
  LPer_100Km = 'L_PER_100_KM',
  UsGalPer_100Mi = 'US_GAL_PER_100_MI',
}

export enum EmissionFreightFuelConsumptionUnitNormalisedEnum {
  KgPer_100Km = 'KG_PER_100_KM',
  KwhPer_100Km = 'KWH_PER_100_KM',
  LPer_100Km = 'L_PER_100_KM',
}

export type EmissionFreightFuelIdentifier = {
  __typename?: 'EmissionFreightFuelIdentifier';
  code: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
};

export enum EmissionFreightFuelTypeEnum {
  AviationFuel = 'AVIATION_FUEL',
  Cng = 'CNG',
  Custom = 'CUSTOM',
  Diesel = 'DIESEL',
  Diesel_5Biodiesel = 'DIESEL_5_BIODIESEL',
  Electricity = 'ELECTRICITY',
  Hfo = 'HFO',
  Hybrid = 'HYBRID',
  Lng = 'LNG',
  Lpg = 'LPG',
  Mgo = 'MGO',
  Other = 'OTHER',
  Petrol = 'PETROL',
  PluginHybrid = 'PLUGIN_HYBRID',
}

export enum EmissionFreightLoadTypeEnum {
  Ftl = 'FTL',
  Ltl = 'LTL',
}

export enum EmissionFreightVehicleCategoryEnum {
  Articulated = 'ARTICULATED',
  AutoCarrier = 'AUTO_CARRIER',
  BulkCarrier = 'BULK_CARRIER',
  ContainerShip = 'CONTAINER_SHIP',
  ContainerTerminal = 'CONTAINER_TERMINAL',
  ContainerVessel = 'CONTAINER_VESSEL',
  CoupledConvoy = 'COUPLED_CONVOY',
  Default = 'DEFAULT',
  Dray = 'DRAY',
  Expedited = 'EXPEDITED',
  Flatbed = 'FLATBED',
  Freighter = 'FREIGHTER',
  General = 'GENERAL',
  GeneralCargo = 'GENERAL_CARGO',
  HeavyBulk = 'HEAVY_BULK',
  Hgv = 'HGV',
  LiquidBulkTerminal = 'LIQUID_BULK_TERMINAL',
  Ltl = 'LTL',
  Mgv = 'MGV',
  Mixed = 'MIXED',
  MotorVessel = 'MOTOR_VESSEL',
  Moving = 'MOVING',
  OilTanker = 'OIL_TANKER',
  Package = 'PACKAGE',
  Passenger = 'PASSENGER',
  PushedConvoy = 'PUSHED_CONVOY',
  Refrigerated = 'REFRIGERATED',
  RefrigeratedBulk = 'REFRIGERATED_BULK',
  Rigid = 'RIGID',
  Ropax = 'ROPAX',
  Roro = 'RORO',
  Specialized = 'SPECIALIZED',
  StorageAndTranshipment = 'STORAGE_AND_TRANSHIPMENT',
  Tanker = 'TANKER',
  TankerVessel = 'TANKER_VESSEL',
  Tl = 'TL',
  Transhipment = 'TRANSHIPMENT',
  UrbanTruck = 'URBAN_TRUCK',
  Vehicle = 'VEHICLE',
  Warehouse = 'WAREHOUSE',
}

export enum EmissionFreightVehicleEmissionStandardEnum {
  Euro_1 = 'EURO_1',
  Euro_2 = 'EURO_2',
  Euro_3 = 'EURO_3',
  Euro_4 = 'EURO_4',
  Euro_5 = 'EURO_5',
  Euro_6 = 'EURO_6',
}

export enum EmissionFreightVehicleEnum {
  Aircraft = 'AIRCRAFT',
  Barge = 'BARGE',
  LogisticsSite = 'LOGISTICS_SITE',
  Ship = 'SHIP',
  Train = 'TRAIN',
  Truck = 'TRUCK',
  Van = 'VAN',
}

export enum EmissionFreightWeightUnitEnum {
  Kg = 'KG',
  Lbs = 'LBS',
  Teu = 'TEU',
  Tonnes = 'TONNES',
  Tons = 'TONS',
}

export type EmissionImportDefaultFormats = {
  __typename?: 'EmissionImportDefaultFormats';
  date_format: Scalars['String']['output'];
  number_format: Scalars['String']['output'];
};

export type EmissionIssue = {
  __typename?: 'EmissionIssue';
  assumed_value?: Maybe<Scalars['String']['output']>;
  input_value?: Maybe<Scalars['String']['output']>;
  param: Scalars['String']['output'];
  recommendations: Array<EmissionIssueRecommendation>;
};

export type EmissionIssueRecommendation = {
  __typename?: 'EmissionIssueRecommendation';
  action: RecommendationAction;
  params: Array<Scalars['String']['output']>;
};

export type EmissionLastMile = {
  __typename?: 'EmissionLastMile';
  from_address?: Maybe<Scalars['String']['output']>;
  from_coordinates?: Maybe<Geography>;
  from_display?: Maybe<Scalars['String']['output']>;
  fuel_type?: Maybe<EmissionLastMileFuelTypeEnum>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  route_details?: Maybe<RouteDetails>;
  to_address?: Maybe<Scalars['String']['output']>;
  to_coordinates?: Maybe<Geography>;
  to_display?: Maybe<Scalars['String']['output']>;
  vehicle: EmissionLastMileVehicleEnum;
  vehicle_category: EmissionLastMileVehicleCategoryEnum;
};

export enum EmissionLastMileFuelTypeEnum {
  Cng = 'CNG',
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  Hybrid = 'HYBRID',
  Lng = 'LNG',
  Lpg = 'LPG',
  Other = 'OTHER',
  Petrol = 'PETROL',
  PluginHybrid = 'PLUGIN_HYBRID',
}

export enum EmissionLastMileVehicleCategoryEnum {
  Default = 'DEFAULT',
  DualPurpose_4X4 = 'DUAL_PURPOSE_4X4',
  Executive = 'EXECUTIVE',
  Large = 'LARGE',
  LowerMedium = 'LOWER_MEDIUM',
  Luxury = 'LUXURY',
  Medium = 'MEDIUM',
  Mini = 'MINI',
  Mpv = 'MPV',
  Small = 'SMALL',
  Sports = 'SPORTS',
  Supermini = 'SUPERMINI',
  UpperMedium = 'UPPER_MEDIUM',
}

export enum EmissionLastMileVehicleEnum {
  Bicycle = 'BICYCLE',
  Car = 'CAR',
  Motorbike = 'MOTORBIKE',
  Van = 'VAN',
}

export type EmissionMetadataKeysAndCountWithUniqueValues = {
  __typename?: 'EmissionMetadataKeysAndCountWithUniqueValues';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
};

export enum EmissionModeEnum {
  Air = 'AIR',
  Bicycle = 'BICYCLE',
  Car = 'CAR',
  Flight = 'FLIGHT',
  InlandWaterway = 'INLAND_WATERWAY',
  LogisticsSite = 'LOGISTICS_SITE',
  Motorbike = 'MOTORBIKE',
  Rail = 'RAIL',
  Road = 'ROAD',
  Sea = 'SEA',
  Van = 'VAN',
}

export type EmissionNumber = {
  __typename?: 'EmissionNumber';
  unit: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type EmissionParentQuality = {
  __typename?: 'EmissionParentQuality';
  overall?: Maybe<Scalars['Float']['output']>;
};

export type EmissionQuality = {
  __typename?: 'EmissionQuality';
  emission_intensity_factor?: Maybe<EmissionQualityScoreEnum>;
  origin_destination?: Maybe<EmissionQualityScoreEnum>;
  overall?: Maybe<EmissionQualityScoreEnum>;
  weight?: Maybe<EmissionQualityScoreEnum>;
};

export enum EmissionQualityScoreEnum {
  Excellent = 'EXCELLENT',
  Good = 'GOOD',
  Sufficient = 'SUFFICIENT',
  Unsatisfactory = 'UNSATISFACTORY',
}

export type EmissionRideHailing = {
  __typename?: 'EmissionRideHailing';
  from_address?: Maybe<Scalars['String']['output']>;
  from_coordinates?: Maybe<Geography>;
  from_display?: Maybe<Scalars['String']['output']>;
  fuel_type?: Maybe<EmissionRideHailingFuelTypeEnum>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  no_of_pax?: Maybe<Scalars['Float']['output']>;
  route_details?: Maybe<RouteDetails>;
  to_address?: Maybe<Scalars['String']['output']>;
  to_coordinates?: Maybe<Geography>;
  to_display?: Maybe<Scalars['String']['output']>;
  vehicle: EmissionRideHailingVehicleEnum;
  vehicle_category: EmissionRideHailingVehicleCategoryEnum;
};

export enum EmissionRideHailingFuelTypeEnum {
  Cng = 'CNG',
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  Hybrid = 'HYBRID',
  Lng = 'LNG',
  Lpg = 'LPG',
  Other = 'OTHER',
  Petrol = 'PETROL',
  PluginHybrid = 'PLUGIN_HYBRID',
}

export enum EmissionRideHailingVehicleCategoryEnum {
  Default = 'DEFAULT',
  DualPurpose_4X4 = 'DUAL_PURPOSE_4X4',
  Executive = 'EXECUTIVE',
  Large = 'LARGE',
  LowerMedium = 'LOWER_MEDIUM',
  Luxury = 'LUXURY',
  Medium = 'MEDIUM',
  Mini = 'MINI',
  Mpv = 'MPV',
  Small = 'SMALL',
  Sports = 'SPORTS',
  Supermini = 'SUPERMINI',
  UpperMedium = 'UPPER_MEDIUM',
}

export enum EmissionRideHailingVehicleEnum {
  Bicycle = 'BICYCLE',
  Car = 'CAR',
  Motorbike = 'MOTORBIKE',
  Van = 'VAN',
}

export enum EmissionSortedColumnEnum {
  Activity = 'ACTIVITY',
  Co2E = 'CO2E',
  Date = 'DATE',
  DistanceKm = 'DISTANCE_KM',
  Intensity = 'INTENSITY',
  ParentQuality = 'PARENT_QUALITY',
  Type = 'TYPE',
}

export enum EmissionSourceTypeEnum {
  Calculator = 'CALCULATOR',
  Cargowise = 'CARGOWISE',
  Chainio = 'CHAINIO',
  DataCollection = 'DATA_COLLECTION',
  Import = 'IMPORT',
  PublicApi = 'PUBLIC_API',
  ShopifyWebhook = 'SHOPIFY_WEBHOOK',
}

export enum EmissionTypeEnum {
  Flights = 'FLIGHTS',
  Freight = 'FREIGHT',
  FreightAggregated = 'FREIGHT_AGGREGATED',
  LastMile = 'LAST_MILE',
  RideHailing = 'RIDE_HAILING',
}

export enum EmissionVisibilityEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type EmissionsAggregations = {
  __typename?: 'EmissionsAggregations';
  activity_end_date: Scalars['DateTimeISO']['output'];
  activity_start_date: Scalars['DateTimeISO']['output'];
  emissions_by_lifecycle: Array<EmissionsLifecycleBreakdown>;
  emissions_by_mode: Array<EmissionsModeBreakdown>;
  overall_quality: Scalars['Float']['output'];
  total_activity: Scalars['Float']['output'];
  total_activity_unit: Scalars['String']['output'];
  total_co2e: Scalars['Float']['output'];
  total_intensity: Scalars['Float']['output'];
  total_intensity_unit: Scalars['String']['output'];
  total_shipments: Scalars['Int']['output'];
};

export type EmissionsFileError = {
  __typename?: 'EmissionsFileError';
  column?: Maybe<Scalars['String']['output']>;
  error: Scalars['String']['output'];
  row?: Maybe<Scalars['Int']['output']>;
  type: EmissionsFileErrorTypeEnum;
};

export enum EmissionsFileErrorTypeEnum {
  ColumnHeaderError = 'COLUMN_HEADER_ERROR',
  FieldError = 'FIELD_ERROR',
  FileEncodingError = 'FILE_ENCODING_ERROR',
  FileNameError = 'FILE_NAME_ERROR',
  FileSizeError = 'FILE_SIZE_ERROR',
  FileTypeError = 'FILE_TYPE_ERROR',
}

export type EmissionsLifecycleBreakdown = {
  __typename?: 'EmissionsLifecycleBreakdown';
  activity?: Maybe<Scalars['Float']['output']>;
  activity_unit?: Maybe<Scalars['String']['output']>;
  co2e: Scalars['Float']['output'];
  intensity?: Maybe<Scalars['Float']['output']>;
  intensity_unit?: Maybe<Scalars['String']['output']>;
  label: EmissionDetailTypeEnum;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type EmissionsModeBreakdown = {
  __typename?: 'EmissionsModeBreakdown';
  activity?: Maybe<Scalars['Float']['output']>;
  activity_unit?: Maybe<Scalars['String']['output']>;
  co2e: Scalars['Float']['output'];
  count?: Maybe<Scalars['Float']['output']>;
  intensity?: Maybe<Scalars['Float']['output']>;
  intensity_unit?: Maybe<Scalars['String']['output']>;
  label: EmissionModeEnum;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export enum FerryLoadCharacteristicsEnum {
  Accompanied = 'ACCOMPANIED',
  Unaccompanied = 'UNACCOMPANIED',
}

export enum FilterConditionEnum {
  In = 'in',
  Is = 'is',
  IsNot = 'is_not',
  NotIn = 'not_in',
}

export type FilterValues = {
  __typename?: 'FilterValues';
  count: Scalars['Float']['output'];
  display: Scalars['String']['output'];
  group_value?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type FreightContainer = {
  __typename?: 'FreightContainer';
  iso_type_code: Scalars['String']['output'];
  net_weight: ContainerWeight;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type FreightDistance = {
  __typename?: 'FreightDistance';
  unit?: Maybe<EmissionFreightDistanceUnitEnum>;
  value: Scalars['Float']['output'];
};

export type FreightFuelConsumption = {
  __typename?: 'FreightFuelConsumption';
  unit?: Maybe<EmissionFreightFuelConsumptionUnitEnum>;
  value: Scalars['Float']['output'];
};

export type FreightRoute = FreightRouteStops | FreightRouteVia;

export type FreightRouteStops = {
  __typename?: 'FreightRouteStops';
  stops: Array<LocationCode>;
};

export type FreightRouteVia = {
  __typename?: 'FreightRouteVia';
  via: Array<ViaLocation>;
};

export type FreightShipmentCalculationConfig = {
  __typename?: 'FreightShipmentCalculationConfig';
  auto_transshipment?: Maybe<Scalars['Boolean']['output']>;
  ferry_load_characteristics?: Maybe<FerryLoadCharacteristicsEnum>;
};

export type FreightVehicleDetails = {
  __typename?: 'FreightVehicleDetails';
  aircraft_code?: Maybe<Scalars['String']['output']>;
  carrier_code?: Maybe<Scalars['String']['output']>;
  carrier_name?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  emission_standard?: Maybe<EmissionFreightVehicleEmissionStandardEnum>;
  empty_running?: Maybe<Scalars['Float']['output']>;
  flight_no?: Maybe<Scalars['String']['output']>;
  fuel_consumption?: Maybe<FreightFuelConsumption>;
  fuel_type?: Maybe<CalculatorFreightFuelTypeEnum>;
  is_refrigerated?: Maybe<Scalars['Boolean']['output']>;
  load_factor?: Maybe<Scalars['Float']['output']>;
  load_type?: Maybe<EmissionFreightLoadTypeEnum>;
  vessel_id?: Maybe<Scalars['String']['output']>;
  vessel_name?: Maybe<Scalars['String']['output']>;
  voyage_no?: Maybe<Scalars['String']['output']>;
};

export type Geography = {
  __typename?: 'Geography';
  coordinates: Array<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type GetApiLogs = {
  __typename?: 'GetApiLogs';
  items: Array<ApiLog>;
  last_evaluated_key?: Maybe<Scalars['String']['output']>;
};

export type GetClientBillingDetails = {
  __typename?: 'GetClientBillingDetails';
  address?: Maybe<BillingAddress>;
  email?: Maybe<Scalars['String']['output']>;
  vat?: Maybe<VatIdentificationNumber>;
};

export type GetClientPaymentSourcesOutput = {
  __typename?: 'GetClientPaymentSourcesOutput';
  next_offset?: Maybe<Scalars['String']['output']>;
  payment_sources: Array<PaymentSource>;
};

export type GetDataCollectionRequestTotalAndIndexedEmissionsCount = {
  __typename?: 'GetDataCollectionRequestTotalAndIndexedEmissionsCount';
  total_indexed_copied_emissions_count: Scalars['Float']['output'];
  total_submitted_emissions_count: Scalars['Float']['output'];
};

export type GetDataCollectionRequests = {
  __typename?: 'GetDataCollectionRequests';
  items: Array<DataCollectionRequest>;
  total_count: Scalars['Int']['output'];
};

export type GetDataCollections = {
  __typename?: 'GetDataCollections';
  items: Array<DataCollection>;
  total_count: Scalars['Int']['output'];
};

export type GetEmissions = {
  __typename?: 'GetEmissions';
  items: Array<EmissionDocument>;
  pagination_limit: Scalars['Int']['output'];
  total_co2e: Scalars['Float']['output'];
  total_count: Scalars['Int']['output'];
  total_count_unfiltered: Scalars['Int']['output'];
};

export type GetEmissionsCo2ERange = {
  __typename?: 'GetEmissionsCo2ERange';
  histogram: Array<HistogramBucket>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type GetEmissionsCount = {
  __typename?: 'GetEmissionsCount';
  total_count_unfiltered: Scalars['Int']['output'];
};

export type GetEmissionsDistanceRange = {
  __typename?: 'GetEmissionsDistanceRange';
  histogram: Array<HistogramBucket>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type GetEmissionsWeightRange = {
  __typename?: 'GetEmissionsWeightRange';
  histogram: Array<HistogramBucket>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type GetImports = {
  __typename?: 'GetImports';
  items: Array<Import>;
  total_count: Scalars['Int']['output'];
};

export type GetInvoiceDownloadUrl = {
  __typename?: 'GetInvoiceDownloadUrl';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
  valid_until: Scalars['DateTimeISO']['output'];
};

export type GetInvoices = {
  __typename?: 'GetInvoices';
  items: Array<Invoice>;
  next_offset?: Maybe<Scalars['String']['output']>;
};

export type GetOffsettingLinks = {
  __typename?: 'GetOffsettingLinks';
  items: Array<OffsettingLink>;
};

export type GetOrders = {
  __typename?: 'GetOrders';
  items: Array<Order>;
  pagination_limit: Scalars['Int']['output'];
  total_co2e: Scalars['Float']['output'];
  total_count: Scalars['Int']['output'];
};

export type GetOrdersCo2ERange = {
  __typename?: 'GetOrdersCo2ERange';
  histogram: Array<HistogramBucket>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type GetPortfolio = {
  __typename?: 'GetPortfolio';
  keyFacts?: Maybe<PortfolioKeyFact>;
  portfolio: Portfolio;
};

export type GetPortfolios = {
  __typename?: 'GetPortfolios';
  items: Array<Portfolio>;
  total_count: Scalars['Int']['output'];
};

export type GetProjects = {
  __typename?: 'GetProjects';
  items: Array<Project>;
  total_count: Scalars['Int']['output'];
};

export type GhgBreakdown = {
  __typename?: 'GhgBreakdown';
  scope_1?: Maybe<Array<GhgBreakdownItem>>;
  scope_2?: Maybe<Array<GhgBreakdownItem>>;
  scope_3?: Maybe<Array<GhgBreakdownItem>>;
};

export type GhgBreakdownItem = {
  __typename?: 'GhgBreakdownItem';
  co2e: EmissionNumber;
  ghg: EmissionNumber;
  label: Scalars['String']['output'];
};

export type GhgMethodology = {
  __typename?: 'GhgMethodology';
  geocoding_breakdown: Array<GhgPercentageBreakdown>;
  methodology_breakdown: Array<GhgPercentageBreakdown>;
  routing_breakdown: Array<GhgPercentageBreakdown>;
  sources: Array<GhgSource>;
};

export type GhgPercentageBreakdown = {
  __typename?: 'GhgPercentageBreakdown';
  percentage: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

export type GhgReport = {
  __typename?: 'GhgReport';
  client: Client;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  filters: GhgReportFilters;
  id: Scalars['ID']['output'];
  is_branded?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  prepared_for?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  results: GhgReportResults;
  share_token: Scalars['ID']['output'];
  type: ReportTypeEnum;
  version_number: Scalars['Int']['output'];
  visibility: ReportVisibilityEnum;
};

export type GhgReportFilters = {
  __typename?: 'GhgReportFilters';
  client_identifier?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['DateTimeISO']['output']>;
  import_id?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type GhgReportFiltersInput = {
  client_identifier?: InputMaybe<Scalars['String']['input']>;
  end_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  import_id?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type GhgReportResults = {
  __typename?: 'GhgReportResults';
  emissions_range_end_date?: Maybe<Scalars['DateTimeISO']['output']>;
  emissions_range_start_date?: Maybe<Scalars['DateTimeISO']['output']>;
  ghg_breakdown: GhgBreakdown;
  scope_breakdown: Array<ScopeBreakdown>;
  total_co2e: EmissionNumber;
  type_breakdown: TypeBreakdown;
};

export type GhgSource = {
  __typename?: 'GhgSource';
  name: Scalars['String']['output'];
  revision?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

export type GlecReport = {
  __typename?: 'GlecReport';
  client: Client;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  filters: GlecReportFilters;
  id: Scalars['ID']['output'];
  is_branded?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  prepared_for?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  results: GlecReportResults;
  share_token: Scalars['ID']['output'];
  type: ReportTypeEnum;
  version_number: Scalars['Int']['output'];
  visibility: ReportVisibilityEnum;
};

export type GlecReportBreakdown = {
  __typename?: 'GlecReportBreakdown';
  activity?: Maybe<Scalars['Float']['output']>;
  activity_unit?: Maybe<Scalars['String']['output']>;
  co2e: Scalars['Float']['output'];
  intensity?: Maybe<Scalars['Float']['output']>;
  intensity_unit?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
};

export type GlecReportByMode = {
  __typename?: 'GlecReportByMode';
  items: Array<GlecReportByModeItem>;
  main_data_type?: Maybe<Scalars['String']['output']>;
  mode: EmissionModeEnum;
};

export type GlecReportByModeItem = {
  __typename?: 'GlecReportByModeItem';
  activity?: Maybe<Scalars['Float']['output']>;
  activity_unit?: Maybe<Scalars['String']['output']>;
  co2e: Scalars['Float']['output'];
  intensity?: Maybe<Scalars['Float']['output']>;
  intensity_unit?: Maybe<Scalars['String']['output']>;
};

export type GlecReportFilters = {
  __typename?: 'GlecReportFilters';
  client_identifier?: Maybe<Scalars['String']['output']>;
  confirmed: Scalars['Boolean']['output'];
  confirmed_text?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['DateTimeISO']['output']>;
  import_id?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['DateTimeISO']['output']>;
  supply_chain_coverage: Scalars['String']['output'];
};

export type GlecReportFiltersInput = {
  client_identifier?: InputMaybe<Scalars['String']['input']>;
  confirmed: Scalars['Boolean']['input'];
  confirmed_text?: InputMaybe<Scalars['String']['input']>;
  end_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  import_id?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  supply_chain_coverage: Scalars['String']['input'];
};

export type GlecReportResults = {
  __typename?: 'GlecReportResults';
  breakdown_by_carriage: Array<GlecReportBreakdown>;
  breakdown_by_data_type: Array<GlecReportBreakdown>;
  breakdown_by_mode: Array<GlecReportBreakdown>;
  breakdown_by_scope: Array<GlecReportBreakdown>;
  breakdown_by_type: Array<GlecReportBreakdown>;
  emissions_range_end_date?: Maybe<Scalars['DateTimeISO']['output']>;
  emissions_range_start_date?: Maybe<Scalars['DateTimeISO']['output']>;
  report_by_mode: Array<GlecReportByMode>;
  total_activity?: Maybe<Scalars['Float']['output']>;
  total_activity_unit?: Maybe<Scalars['String']['output']>;
  total_co2e: Scalars['Float']['output'];
  total_intensity?: Maybe<Scalars['Float']['output']>;
  total_intensity_unit?: Maybe<Scalars['String']['output']>;
};

export type HistogramBucket = {
  __typename?: 'HistogramBucket';
  doc_count: Scalars['Float']['output'];
  key: Scalars['Float']['output'];
};

export type HomeEmissionPerDate = {
  __typename?: 'HomeEmissionPerDate';
  breakdown?: Maybe<Array<HomeEmissionPerDateBreakdown>>;
  co2e?: Maybe<Scalars['Float']['output']>;
  date: Scalars['DateTimeISO']['output'];
};

export type HomeEmissionPerDateBreakdown = {
  __typename?: 'HomeEmissionPerDateBreakdown';
  co2e?: Maybe<Scalars['Float']['output']>;
  grouping?: Maybe<Array<HomeEmissionPerDateGrouping>>;
};

export type HomeEmissionPerDateGrouping = {
  __typename?: 'HomeEmissionPerDateGrouping';
  emissionMode?: Maybe<EmissionModeEnum>;
};

export enum HomeEmissionPerDateViewEnum {
  Cumulative = 'CUMULATIVE',
  Intervals = 'INTERVALS',
}

export type HomeEmissionsPerDate = {
  __typename?: 'HomeEmissionsPerDate';
  calendarInterval: CalendarInterval;
  items: Array<HomeEmissionPerDate>;
};

export type HostedPage = {
  __typename?: 'HostedPage';
  business_entity_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Int']['output']>;
  expires_at?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  pass_thru_content?: Maybe<Scalars['String']['output']>;
  resource_version?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Import = {
  __typename?: 'Import';
  client_user?: Maybe<ClientUser>;
  co2e_total?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['DateTimeISO']['output']>;
  emission_count?: Maybe<Scalars['Int']['output']>;
  emissions_by_client_key?: Maybe<Array<ImportEmissionsByClientKey>>;
  emissions_by_supplier_key?: Maybe<Array<ImportEmissionsBySupplierKey>>;
  filename: Scalars['String']['output'];
  filesize: Scalars['Float']['output'];
  has_error_file: Scalars['Boolean']['output'];
  has_error_file_xlsx: Scalars['Boolean']['output'];
  has_success_file: Scalars['Boolean']['output'];
  has_success_file_xlsx: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  processed_rows_error_count?: Maybe<Scalars['Int']['output']>;
  processed_rows_error_file_url?: Maybe<Scalars['String']['output']>;
  processed_rows_error_file_url_xlsx?: Maybe<Scalars['String']['output']>;
  processed_rows_success_count?: Maybe<Scalars['Int']['output']>;
  processed_rows_success_file_url?: Maybe<Scalars['String']['output']>;
  processed_rows_success_file_url_xlsx?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  state: ImportStateEnum;
  vertical: ImportVerticalEnum;
};

export type ImportProcessed_Rows_Success_File_UrlArgs = {
  custom_schema_id?: InputMaybe<Scalars['String']['input']>;
};

export type ImportProcessed_Rows_Success_File_Url_XlsxArgs = {
  custom_schema_id?: InputMaybe<Scalars['String']['input']>;
};

export type ImportEmissionsByClientKey = {
  __typename?: 'ImportEmissionsByClientKey';
  client_key: Scalars['String']['output'];
  co2e: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
};

export type ImportEmissionsBySupplierKey = {
  __typename?: 'ImportEmissionsBySupplierKey';
  co2e: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  supplier_key: Scalars['String']['output'];
};

export enum ImportSortedColumnEnum {
  DateUploaded = 'DATE_UPLOADED',
  FileName = 'FILE_NAME',
  UploadedBy = 'UPLOADED_BY',
}

export enum ImportStateEnum {
  Error = 'ERROR',
  Failed = 'FAILED',
  Indexing = 'INDEXING',
  Running = 'RUNNING',
  Starting = 'STARTING',
  Stopped = 'STOPPED',
  Stopping = 'STOPPING',
  Succeeded = 'SUCCEEDED',
  Timeout = 'TIMEOUT',
}

export enum ImportVerticalEnum {
  Flights = 'FLIGHTS',
  Freight = 'FREIGHT',
  FreightAggregated = 'FREIGHT_AGGREGATED',
  LastMile = 'LAST_MILE',
  RideHailing = 'RIDE_HAILING',
}

export type InviteClientUsersInput = {
  emails: Array<Scalars['String']['input']>;
  role: ClientRoleEnum;
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID']['output'];
  issued_at: Scalars['DateTimeISO']['output'];
  status: InvoiceStatus;
  total: MonetaryValue;
};

export enum InvoiceStatus {
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
  PaymentDue = 'PAYMENT_DUE',
  Pending = 'PENDING',
  Posted = 'POSTED',
  Voided = 'VOIDED',
}

export type ItemisedEmissionsReport = {
  __typename?: 'ItemisedEmissionsReport';
  client: Client;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  filters: ItemisedEmissionsReportFilters;
  id: Scalars['ID']['output'];
  is_branded?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  prepared_for?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  results: ItemisedEmissionsReportResults;
  share_token: Scalars['ID']['output'];
  type: ReportTypeEnum;
  version_number: Scalars['Int']['output'];
  visibility: ReportVisibilityEnum;
};

export type ItemisedEmissionsReportBreakdownByShipment = {
  __typename?: 'ItemisedEmissionsReportBreakdownByShipment';
  activity: Scalars['Float']['output'];
  activity_unit: Scalars['String']['output'];
  breakdown_by_type: Array<ItemisedEmissionsReportBreakdownByType>;
  co2e: Scalars['Float']['output'];
  date: Scalars['DateTimeISO']['output'];
  distance_km?: Maybe<Scalars['Float']['output']>;
  from_address?: Maybe<Scalars['String']['output']>;
  from_code?: Maybe<Scalars['String']['output']>;
  from_coordinates?: Maybe<Geography>;
  from_display?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  intensity: Scalars['Float']['output'];
  intensity_unit: Scalars['String']['output'];
  mode: EmissionModeEnum;
  quality?: Maybe<EmissionParentQuality>;
  shipment_identifier?: Maybe<Scalars['String']['output']>;
  teu?: Maybe<Scalars['Float']['output']>;
  to_address?: Maybe<Scalars['String']['output']>;
  to_code?: Maybe<Scalars['String']['output']>;
  to_coordinates?: Maybe<Geography>;
  to_display?: Maybe<Scalars['String']['output']>;
  weight_kg: Scalars['Float']['output'];
};

export type ItemisedEmissionsReportBreakdownByType = {
  __typename?: 'ItemisedEmissionsReportBreakdownByType';
  co2e: Scalars['Float']['output'];
  label: EmissionDetailTypeEnum;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type ItemisedEmissionsReportFilters = {
  __typename?: 'ItemisedEmissionsReportFilters';
  client_identifier: Scalars['String']['output'];
  end_date?: Maybe<Scalars['DateTimeISO']['output']>;
  import_id?: Maybe<Scalars['String']['output']>;
  shipment_identifier: Scalars['String']['output'];
  start_date?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ItemisedEmissionsReportFiltersInput = {
  client_identifier: Scalars['String']['input'];
  end_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  import_id?: InputMaybe<Scalars['String']['input']>;
  shipment_identifier: Scalars['String']['input'];
  start_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type ItemisedEmissionsReportResults = {
  __typename?: 'ItemisedEmissionsReportResults';
  breakdown_by_shipment: Array<ItemisedEmissionsReportBreakdownByShipment>;
  breakdown_by_type: Array<ItemisedEmissionsReportBreakdownByType>;
  emissions_range_end_date?: Maybe<Scalars['DateTimeISO']['output']>;
  emissions_range_start_date?: Maybe<Scalars['DateTimeISO']['output']>;
  overall_quality: Scalars['Float']['output'];
  total_activity: Scalars['Float']['output'];
  total_activity_unit: Scalars['String']['output'];
  total_co2e: Scalars['Float']['output'];
  total_intensity: Scalars['Float']['output'];
  total_intensity_unit: Scalars['String']['output'];
  total_shipment: Scalars['Int']['output'];
  total_shipment_leg: Scalars['Int']['output'];
};

export enum LanguageEnum {
  EnGb = 'EN_GB',
  FrFr = 'FR_FR',
}

export type LatLong = {
  __typename?: 'LatLong';
  lat?: Maybe<Scalars['Float']['output']>;
  lon?: Maybe<Scalars['Float']['output']>;
};

export type LocationAddress = {
  __typename?: 'LocationAddress';
  address: Scalars['String']['output'];
  country_code?: Maybe<Scalars['String']['output']>;
};

export type LocationCode = {
  __typename?: 'LocationCode';
  code: Scalars['String']['output'];
};

export type LocationCoordinates = {
  __typename?: 'LocationCoordinates';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type LocationCountry = {
  __typename?: 'LocationCountry';
  country_code: Scalars['String']['output'];
};

export type LocationFreight = LocationAddress | LocationCode | LocationCoordinates;

export type LogEvent = {
  __typename?: 'LogEvent';
  context: Scalars['JSON']['output'];
  level: LogLevel;
  message: Scalars['String']['output'];
  timestamp: Scalars['Float']['output'];
};

export enum LogLevel {
  Debug = 'DEBUG',
  Error = 'ERROR',
  Info = 'INFO',
  Warn = 'WARN',
}

export type LogStream = {
  __typename?: 'LogStream';
  events: Array<LogEvent>;
  firstEventTimestamp: Scalars['Float']['output'];
  lastEventTimestamp: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type Mfa = {
  __typename?: 'MFA';
  status: Scalars['String']['output'];
};

export type MfaCheckVerification = {
  code: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type MfaSendVerification = {
  phone: Scalars['String']['input'];
};

export type Metadata = {
  __typename?: 'Metadata';
  display: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MetadataInput = {
  key: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type MonetaryValue = {
  __typename?: 'MonetaryValue';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

export type MovementWithDistance = {
  __typename?: 'MovementWithDistance';
  cargo_type?: Maybe<EmissionFreightCargoTypeEnum>;
  carriage?: Maybe<EmissionFreightCarriageEnum>;
  category?: Maybe<Scalars['Float']['output']>;
  config?: Maybe<Configuration>;
  date?: Maybe<Scalars['String']['output']>;
  distance: FreightDistance;
  from?: Maybe<LocationCountry>;
  scope?: Maybe<Scalars['Float']['output']>;
  to?: Maybe<LocationCountry>;
  vehicle: FreightVehicleDetails;
};

export type MovementWithLocation = {
  __typename?: 'MovementWithLocation';
  cargo_type?: Maybe<EmissionFreightCargoTypeEnum>;
  carriage?: Maybe<EmissionFreightCarriageEnum>;
  category?: Maybe<Scalars['Float']['output']>;
  config?: Maybe<Configuration>;
  date?: Maybe<Scalars['String']['output']>;
  from: LocationFreight;
  route?: Maybe<FreightRoute>;
  scope?: Maybe<Scalars['Float']['output']>;
  to: LocationFreight;
  vehicle: FreightVehicleDetails;
};

export type Mutation = {
  __typename?: 'Mutation';
  approve_client_user_access: Scalars['String']['output'];
  associate_with_network_member: DirectoryEntry;
  buy_offsetting: BuyOffsetting;
  cache_transaction_id: Scalars['String']['output'];
  cancel_offsetting: Scalars['String']['output'];
  check_verification_token_mfa: Mfa;
  compute_freight_emission: Emission;
  confirm_csv_file_for_calculation: ConfirmCsvFileForCalculation;
  confirm_offsetting: BuyOffsetting;
  create_client: CreateClient;
  create_client_contact: DirectoryContact;
  create_client_directory: DirectoryEntry;
  create_data_collection: DataCollection;
  create_profile: CreateProfile;
  create_supplier_contact: DirectoryContact;
  create_supplier_directory: DirectoryEntry;
  delete_client_contact?: Maybe<Scalars['String']['output']>;
  delete_client_user: Scalars['String']['output'];
  delete_me: Scalars['Boolean']['output'];
  delete_metadata_display_name: ClientMetadataDisplayName;
  delete_report: Report;
  delete_supplier_contact?: Maybe<Scalars['String']['output']>;
  delete_test_mode_data: Scalars['Boolean']['output'];
  deny_client_user_access: Scalars['String']['output'];
  dismiss_client_user_tip: ClientUserTip;
  invite_client_users: Array<ClientUser>;
  populate_test_mode_data: Scalars['Boolean']['output'];
  review_data_collection_recipients: Scalars['Boolean']['output'];
  roll_api_key: ApiKey;
  save_ghg_report: GhgReport;
  save_glec_report: GlecReport;
  save_itemised_emissions_report: ItemisedEmissionsReport;
  save_offsetting_link: OffsettingLink;
  send_verification_token_mfa: Mfa;
  transition_data_collection_request: DataCollectionRequest;
  update_client: Client;
  update_client_after_payment_method_update: Scalars['Boolean']['output'];
  update_client_billing_details: Scalars['Boolean']['output'];
  update_client_branding_settings: Client;
  update_client_contact: DirectoryContact;
  update_client_directory: DirectoryEntry;
  update_client_directory_metadata: DirectoryEntry;
  update_client_organization_preferences: Client;
  update_client_team_access: Client;
  update_client_user_role: ClientUser;
  update_default_offsetting_link: OffsettingLink;
  update_email_preferences: Array<ClientUserEmailPreference>;
  update_emission_import_defaults: EmissionImportDefaultFormats;
  update_emission_visibility: Emission;
  update_language?: Maybe<LanguageEnum>;
  update_me: User;
  update_metadata_value_display_name: ClientMetadataDisplayName;
  update_mfa: ClientUserMfa;
  update_offsetting_link: OffsettingLink;
  update_report_name: Report;
  update_report_visibility: Report;
  update_supplier_contact: DirectoryContact;
  update_supplier_directory: DirectoryEntry;
  update_supplier_directory_metadata: DirectoryEntry;
  upload_emissions_file: UploadEmissionsFile;
  upload_file: S3Object;
};

export type MutationApprove_Client_User_AccessArgs = {
  id: Scalars['String']['input'];
  role: ClientRoleEnum;
};

export type MutationAssociate_With_Network_MemberArgs = {
  directory_entry_public_id: Scalars['String']['input'];
  network_member_public_id: Scalars['String']['input'];
};

export type MutationBuy_OffsettingArgs = {
  data: BuyOffsettingInput;
};

export type MutationCache_Transaction_IdArgs = {
  hosted_page_id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCancel_OffsettingArgs = {
  data: CancelOffsettingInput;
};

export type MutationCheck_Verification_Token_MfaArgs = {
  data: MfaCheckVerification;
};

export type MutationCompute_Freight_EmissionArgs = {
  input: Scalars['JSON']['input'];
};

export type MutationConfirm_Csv_File_For_CalculationArgs = {
  fileKey: Scalars['String']['input'];
};

export type MutationConfirm_OffsettingArgs = {
  data: ConfirmOffsettingInput;
};

export type MutationCreate_ClientArgs = {
  data: CreateClientInput;
};

export type MutationCreate_Client_ContactArgs = {
  directory_entry_public_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreate_Client_DirectoryArgs = {
  clientKey?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
};

export type MutationCreate_Data_CollectionArgs = {
  input: CreateDataCollectionInput;
};

export type MutationCreate_ProfileArgs = {
  data: CreateProfileInput;
};

export type MutationCreate_Supplier_ContactArgs = {
  directory_entry_public_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreate_Supplier_DirectoryArgs = {
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  supplierKey?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDelete_Client_ContactArgs = {
  contact_public_id: Scalars['String']['input'];
};

export type MutationDelete_Client_UserArgs = {
  id: Scalars['String']['input'];
};

export type MutationDelete_MeArgs = {
  pass: Scalars['String']['input'];
};

export type MutationDelete_Metadata_Display_NameArgs = {
  metadata_key: Scalars['String']['input'];
  metadata_value: Scalars['String']['input'];
};

export type MutationDelete_ReportArgs = {
  public_id: Scalars['String']['input'];
};

export type MutationDelete_Supplier_ContactArgs = {
  contact_public_id: Scalars['String']['input'];
};

export type MutationDeny_Client_User_AccessArgs = {
  id: Scalars['String']['input'];
};

export type MutationDismiss_Client_User_TipArgs = {
  type: ClientUserTipTypeEnum;
};

export type MutationInvite_Client_UsersArgs = {
  data: InviteClientUsersInput;
};

export type MutationReview_Data_Collection_RecipientsArgs = {
  input: ReviewDataCollectionRecipientsInput;
};

export type MutationRoll_Api_KeyArgs = {
  id: Scalars['String']['input'];
  test: Scalars['Boolean']['input'];
};

export type MutationSave_Ghg_ReportArgs = {
  baseFilters: GhgReportFiltersInput;
  generatedDate: Scalars['DateTimeISO']['input'];
  input: SaveReportInput;
};

export type MutationSave_Glec_ReportArgs = {
  baseFilters: GlecReportFiltersInput;
  generatedDate: Scalars['DateTimeISO']['input'];
  input: SaveReportInput;
};

export type MutationSave_Itemised_Emissions_ReportArgs = {
  baseFilters: ItemisedEmissionsReportFiltersInput;
  generatedDate: Scalars['DateTimeISO']['input'];
  input: SaveReportInput;
};

export type MutationSave_Offsetting_LinkArgs = {
  settings: OffsettingLinkSettingsInput;
};

export type MutationSend_Verification_Token_MfaArgs = {
  data: MfaSendVerification;
};

export type MutationTransition_Data_Collection_RequestArgs = {
  event: DataCollectionRequestEventEnum;
  note?: InputMaybe<Scalars['String']['input']>;
  public_id: Scalars['String']['input'];
  supplier_key?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdate_ClientArgs = {
  data: UpdateClientInput;
};

export type MutationUpdate_Client_Billing_DetailsArgs = {
  data: UpdateClientBillingDetailsInput;
};

export type MutationUpdate_Client_Branding_SettingsArgs = {
  data: UpdateClientBrandingSettingsInput;
};

export type MutationUpdate_Client_ContactArgs = {
  contact_public_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdate_Client_DirectoryArgs = {
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  public_id: Scalars['String']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdate_Client_Directory_MetadataArgs = {
  meta_data: Array<DirectoryEntryMetadataInput>;
  public_id: Scalars['String']['input'];
};

export type MutationUpdate_Client_Organization_PreferencesArgs = {
  data: UpdateClientOrganizationPreferencesInput;
};

export type MutationUpdate_Client_Team_AccessArgs = {
  data: Array<UpdateClientTeamAccessInput>;
};

export type MutationUpdate_Client_User_RoleArgs = {
  data: UpdateClientUserRoleInput;
};

export type MutationUpdate_Default_Offsetting_LinkArgs = {
  public_id: Scalars['String']['input'];
};

export type MutationUpdate_Email_PreferencesArgs = {
  data: Array<ClientUserEmailPreferenceInput>;
};

export type MutationUpdate_Emission_Import_DefaultsArgs = {
  date_format: Scalars['String']['input'];
  number_format: Scalars['String']['input'];
};

export type MutationUpdate_Emission_VisibilityArgs = {
  public_id: Scalars['String']['input'];
  visibility: EmissionVisibilityEnum;
};

export type MutationUpdate_LanguageArgs = {
  language?: InputMaybe<LanguageEnum>;
};

export type MutationUpdate_MeArgs = {
  data: UpdateUserInput;
};

export type MutationUpdate_Metadata_Value_Display_NameArgs = {
  metadata_key: Scalars['String']['input'];
  metadata_value: Scalars['String']['input'];
  value_display_name: Scalars['String']['input'];
};

export type MutationUpdate_MfaArgs = {
  data: UpdateMfaInput;
};

export type MutationUpdate_Offsetting_LinkArgs = {
  public_id: Scalars['String']['input'];
  settings: OffsettingLinkSettingsInput;
};

export type MutationUpdate_Report_NameArgs = {
  public_id: Scalars['String']['input'];
  report_name: Scalars['String']['input'];
};

export type MutationUpdate_Report_VisibilityArgs = {
  public_id: Scalars['String']['input'];
  visibility: ReportVisibilityEnum;
};

export type MutationUpdate_Supplier_ContactArgs = {
  contact_public_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdate_Supplier_DirectoryArgs = {
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  public_id: Scalars['String']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdate_Supplier_Directory_MetadataArgs = {
  meta_data: Array<DirectoryEntryMetadataInput>;
  public_id: Scalars['String']['input'];
};

export type MutationUpload_Emissions_FileArgs = {
  date_format: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  number_format: Scalars['String']['input'];
  vertical: ImportVerticalEnum;
};

export type MutationUpload_FileArgs = {
  file: Scalars['Upload']['input'];
  folder: S3FolderEnum;
};

export type OffsettingLink = {
  __typename?: 'OffsettingLink';
  available_tonnes: Scalars['Float']['output'];
  created_by: ClientUser;
  created_date: Scalars['DateTimeISO']['output'];
  currency: CurrencyEnum;
  id: Scalars['String']['output'];
  is_active: Scalars['Boolean']['output'];
  is_default: Scalars['Boolean']['output'];
  portfolios: Array<OffsettingLinkPortfolio>;
  portfolios_public_ids: Array<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  redirect_url?: Maybe<Scalars['String']['output']>;
  show_confirmation_page: Scalars['Boolean']['output'];
  type: OffsettingLinkTypeEnum;
  updated_by: ClientUser;
  updated_date: Scalars['DateTimeISO']['output'];
  url: Scalars['String']['output'];
};

export type OffsettingLinkPortfolio = {
  __typename?: 'OffsettingLinkPortfolio';
  allocated_weight_kg: Scalars['Float']['output'];
  allocations: Array<PortfolioAllocation>;
  available_tonnes: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  is_published: Scalars['Boolean']['output'];
  is_sold_out: Scalars['Boolean']['output'];
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  portfolio_version: Scalars['String']['output'];
  prices: Array<OffsettingLinkPortfolioPrices>;
  public_id: Scalars['String']['output'];
  reserved_weight_kg: Scalars['Float']['output'];
  status: Scalars['String']['output'];
};

export type OffsettingLinkPortfolioPrices = {
  __typename?: 'OffsettingLinkPortfolioPrices';
  currency: Scalars['String']['output'];
  price_per_kg: Scalars['Float']['output'];
};

export type OffsettingLinkSettingsInput = {
  currency: Scalars['String']['input'];
  is_active: Scalars['Boolean']['input'];
  is_default?: InputMaybe<Scalars['Boolean']['input']>;
  portfolios: Array<OffsettingLinkSettingsPortfolioInput>;
  redirect_url?: InputMaybe<Scalars['String']['input']>;
  show_confirmation_page: Scalars['Boolean']['input'];
  type: Scalars['String']['input'];
};

export type OffsettingLinkSettingsPortfolioInput = {
  public_id: Scalars['String']['input'];
};

export enum OffsettingLinkTypeEnum {
  Amount = 'AMOUNT',
  Weight = 'WEIGHT',
}

export type Order = {
  __typename?: 'Order';
  amount: Scalars['Float']['output'];
  certificate_url: Scalars['String']['output'];
  client: Client;
  client_user?: Maybe<ClientUser>;
  commission: Scalars['Float']['output'];
  commission_percentage: Scalars['Float']['output'];
  created_date: Scalars['DateTimeISO']['output'];
  currency: Scalars['String']['output'];
  issued_on_behalf_of?: Maybe<Scalars['String']['output']>;
  meta_data?: Maybe<Array<Metadata>>;
  portfolio: Portfolio;
  portfolio_id: Scalars['String']['output'];
  portfolio_name: Scalars['String']['output'];
  price_per_kg: Scalars['Float']['output'];
  public_id: Scalars['String']['output'];
  small_order_fee?: Maybe<Scalars['Float']['output']>;
  small_order_threshold?: Maybe<Scalars['Float']['output']>;
  source_type_name: OrderSourceTypeEnum;
  status: OrderStatusEnum;
  status_timeline?: Maybe<Array<StatusTimeline>>;
  updated_date: Scalars['DateTimeISO']['output'];
  weight_kg: Scalars['Float']['output'];
};

export enum OrderFilterPropertiesEnum {
  Co2E = 'CO2E',
  DateRange = 'DATE_RANGE',
  InvoiceNumber = 'INVOICE_NUMBER',
  PublicId = 'PUBLIC_ID',
  Status = 'STATUS',
}

export enum OrderSortedColumnEnum {
  CreatedDate = 'CREATED_DATE',
  WeightKg = 'WEIGHT_KG',
}

export enum OrderSourceTypeEnum {
  ClientApp = 'CLIENT_APP',
  OffsettingLink = 'OFFSETTING_LINK',
  PublicApi = 'PUBLIC_API',
  ShopifyWebhook = 'SHOPIFY_WEBHOOK',
}

export enum OrderStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Invoiced = 'INVOICED',
  Paid = 'PAID',
  Placed = 'PLACED',
  Processing = 'PROCESSING',
  Quoted = 'QUOTED',
  Rejected = 'REJECTED',
}

export type PaymentSource = {
  __typename?: 'PaymentSource';
  card?: Maybe<Card>;
  deleted: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  status: PaymentSourceStatusEnum;
  type: PaymentSourceTypeEnum;
};

export enum PaymentSourceStatusEnum {
  Expired = 'expired',
  Expiring = 'expiring',
  Invalid = 'invalid',
  PendingVerification = 'pending_verification',
  Valid = 'valid',
}

export enum PaymentSourceTypeEnum {
  Card = 'card',
}

export type Plan = {
  __typename?: 'Plan';
  available_end_date?: Maybe<Scalars['DateTimeISO']['output']>;
  available_start_date: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  is_enhanced: Scalars['Boolean']['output'];
  item_prices: Array<PlanItemPrice>;
};

export type PlanItemPrice = {
  __typename?: 'PlanItemPrice';
  /** @deprecated Use list price or sale price instead. */
  amount?: Maybe<Scalars['Float']['output']>;
  currency: CurrencyEnum;
  discount_percentage?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  is_discontinued: Scalars['Boolean']['output'];
  list_price?: Maybe<Scalars['String']['output']>;
  period: PlanItemPricePeriodEnum;
  sale_price?: Maybe<Scalars['String']['output']>;
};

export enum PlanItemPricePeriodEnum {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export type Portfolio = {
  __typename?: 'Portfolio';
  allocated_weight_kg: Scalars['Float']['output'];
  allocations: Array<PortfolioAllocation>;
  created_date: Scalars['DateTimeISO']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  distribution: PortfolioDistribution;
  is_published: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price_per_kg: Scalars['Float']['output'];
  public_id: Scalars['String']['output'];
  reserved_weight_kg: Scalars['Float']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type PortfolioAllocation = {
  __typename?: 'PortfolioAllocation';
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  percentage: Scalars['Float']['output'];
  portfolio: Portfolio;
  project: Project;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type PortfolioDistribution = {
  __typename?: 'PortfolioDistribution';
  avoidance: Scalars['Float']['output'];
  removal: Scalars['Float']['output'];
  totalAllocated: Scalars['Float']['output'];
};

export type PortfolioKeyFact = {
  __typename?: 'PortfolioKeyFact';
  id: Scalars['String']['output'];
  key_facts: Array<Scalars['String']['output']>;
};

export type Project = {
  __typename?: 'Project';
  avoidance: Scalars['Boolean']['output'];
  carbonplan_assessment?: Maybe<CarbonplanAssessment>;
  certifications?: Maybe<Array<ProjectCertification>>;
  coordinates?: Maybe<Geography>;
  country: Country;
  created_date: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  developer?: Maybe<ProjectDeveloper>;
  location?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  project_type: ProjectType;
  public_id: Scalars['String']['output'];
  registry?: Maybe<Registry>;
  removal: Scalars['Boolean']['output'];
  sdgs?: Maybe<Array<SustainableDevelopmentGoalsEnum>>;
  updated_date: Scalars['DateTimeISO']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type ProjectCertification = {
  __typename?: 'ProjectCertification';
  id: Scalars['ID']['output'];
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
};

export type ProjectDeveloper = {
  __typename?: 'ProjectDeveloper';
  created_date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type ProjectType = {
  __typename?: 'ProjectType';
  category: Scalars['String']['output'];
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type Query = {
  __typename?: 'Query';
  api_information: ApiInformation;
  api_keys: Array<ApiKey>;
  api_log: ApiLog;
  api_logs: GetApiLogs;
  buy_again_portfolios: GetPortfolios;
  calculator_input: CalculatorInput;
  client: Client;
  client_billing_details: GetClientBillingDetails;
  client_contact: DirectoryContact;
  client_directories: Array<DirectoryEntry>;
  client_directory: DirectoryEntry;
  client_integration: ClientIntegration;
  client_integration_logs: ClientIntegrationLogs;
  client_integrations: Array<ClientIntegration>;
  client_manage_payment_sources_hosted_page: HostedPage;
  client_payment_sources: GetClientPaymentSourcesOutput;
  client_subscription: ClientSubscription;
  client_user_tip: ClientUserTip;
  countries: Array<Country>;
  dashboard: Dashboards;
  data_collection: DataCollection;
  data_collection_request_inbound: DataCollectionRequest;
  data_collection_request_outbound: DataCollectionRequest;
  data_collection_request_total_and_indexed_emissions_copied_count?: Maybe<GetDataCollectionRequestTotalAndIndexedEmissionsCount>;
  data_collection_requests: GetDataCollectionRequests;
  data_collections: GetDataCollections;
  default_offsetting_link?: Maybe<OffsettingLink>;
  emission: Emission;
  emission_co2e_range: GetEmissionsCo2ERange;
  emission_distance_range: GetEmissionsDistanceRange;
  emission_metadata_keys: Array<Scalars['String']['output']>;
  emission_metadata_keys_with_count: Array<EmissionMetadataKeysAndCountWithUniqueValues>;
  emission_metadata_values: Array<FilterValues>;
  emission_property_values: Array<FilterValues>;
  emission_weight_range: GetEmissionsWeightRange;
  emissions: GetEmissions;
  emissions_count: GetEmissionsCount;
  generate_ghg_report: GhgReportResults;
  generate_glec_report: GlecReportResults;
  generate_itemised_emissions_report: ItemisedEmissionsReportResults;
  get_data_collection_request_review_data: DataCollectionRequestReviewData;
  get_data_collection_request_review_inventory: DataCollectionRequestReviewInventory;
  get_email_preferences: Array<ClientUserEmailPreference>;
  get_mfa: ClientUserMfa;
  ghg_report: GhgReport;
  glec_report: GlecReport;
  home_emissions_per_date: HomeEmissionsPerDate;
  import: Import;
  imports: GetImports;
  invoice_download_url: GetInvoiceDownloadUrl;
  invoices: GetInvoices;
  itemised_emissions_report: ItemisedEmissionsReport;
  metadata_display_names: Array<ClientMetadataDisplayName>;
  network_members_matching_domains: Array<Client>;
  offsetting_link: OffsettingLink;
  offsetting_links: GetOffsettingLinks;
  order: Order;
  order_co2e_range: GetOrdersCo2ERange;
  order_metadata_keys: Array<Scalars['String']['output']>;
  order_metadata_values: Array<FilterValues>;
  order_property_values: Array<FilterValues>;
  orders: GetOrders;
  plans: Array<Plan>;
  portfolio: GetPortfolio;
  portfolios: GetPortfolios;
  project: Project;
  projects: GetProjects;
  report: Report;
  reports: Reports;
  select_plan_item_hosted_page: HostedPage;
  select_plan_item_hosted_page_acknowledge: Scalars['Boolean']['output'];
  supplier_contact: DirectoryContact;
  supplier_directories: Array<DirectoryEntry>;
  supplier_directory: DirectoryEntry;
  untracked_emission: Emission;
};

export type QueryApi_KeysArgs = {
  test: Scalars['Boolean']['input'];
};

export type QueryApi_LogArgs = {
  id: Scalars['String']['input'];
};

export type QueryApi_LogsArgs = {
  descend: Scalars['Boolean']['input'];
  exclusive_start_key?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCalculator_InputArgs = {
  untracked_emission_public_id: Scalars['String']['input'];
};

export type QueryClient_ContactArgs = {
  contact_public_id: Scalars['String']['input'];
};

export type QueryClient_DirectoryArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryClient_IntegrationArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryClient_Integration_LogsArgs = {
  next_token?: InputMaybe<Scalars['String']['input']>;
  public_id: Scalars['String']['input'];
};

export type QueryClient_Payment_SourcesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  sort_by?: InputMaybe<SortConfig>;
  status_filter?: InputMaybe<ClientPaymentSourceStatusFilter>;
};

export type QueryClient_User_TipArgs = {
  type: ClientUserTipTypeEnum;
};

export type QueryDashboardArgs = {
  type: Scalars['String']['input'];
};

export type QueryData_CollectionArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryData_Collection_Request_InboundArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryData_Collection_Request_OutboundArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryData_Collection_Request_Total_And_Indexed_Emissions_Copied_CountArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryData_Collection_RequestsArgs = {
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  sorted_column: DataCollectionRequestsSortedColumnEnum;
  sorted_order: SortedOrderEnum;
};

export type QueryData_CollectionsArgs = {
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  sorted_column: DataCollectionSortedColumnEnum;
  sorted_order: SortedOrderEnum;
};

export type QueryEmissionArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryEmission_Metadata_ValuesArgs = {
  importId?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type QueryEmission_Property_ValuesArgs = {
  group_key?: InputMaybe<EmissionFilterPropertiesEnum>;
  key: EmissionFilterPropertiesEnum;
  value: Scalars['String']['input'];
};

export type QueryEmissionsArgs = {
  activity_type_mode_pairs?: InputMaybe<Array<Scalars['String']['input']>>;
  activity_type_vehicle_code_pairs?: InputMaybe<Array<Scalars['String']['input']>>;
  co2e?: InputMaybe<RangeInput>;
  date_range?: InputMaybe<DateRangeInput>;
  distance_km?: InputMaybe<RangeInput>;
  import_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<MetadataInput>>;
  modes?: InputMaybe<Array<EmissionModeEnum>>;
  page: Scalars['Int']['input'];
  public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  size: Scalars['Int']['input'];
  sorted_column: EmissionSortedColumnEnum;
  sorted_order: SortedOrderEnum;
  types?: InputMaybe<Array<EmissionTypeEnum>>;
  weight_kg?: InputMaybe<RangeInput>;
};

export type QueryGenerate_Ghg_ReportArgs = {
  baseFilters: GhgReportFiltersInput;
};

export type QueryGenerate_Glec_ReportArgs = {
  baseFilters: GlecReportFiltersInput;
};

export type QueryGenerate_Itemised_Emissions_ReportArgs = {
  baseFilters: ItemisedEmissionsReportFiltersInput;
};

export type QueryGet_Data_Collection_Request_Review_DataArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryGet_Data_Collection_Request_Review_InventoryArgs = {
  page: Scalars['Int']['input'];
  public_id: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  sorted_column: EmissionSortedColumnEnum;
  sorted_order: SortedOrderEnum;
};

export type QueryGhg_ReportArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryGlec_ReportArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryHome_Emissions_Per_DateArgs = {
  groupByEmissionMode: Scalars['Boolean']['input'];
  view: HomeEmissionPerDateViewEnum;
};

export type QueryImportArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryImportsArgs = {
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  sorted_column: ImportSortedColumnEnum;
  sorted_order: SortedOrderEnum;
};

export type QueryInvoice_Download_UrlArgs = {
  id: Scalars['String']['input'];
};

export type QueryInvoicesArgs = {
  offset?: InputMaybe<Scalars['String']['input']>;
};

export type QueryItemised_Emissions_ReportArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryMetadata_Display_NamesArgs = {
  metadata_key: Scalars['String']['input'];
};

export type QueryNetwork_Members_Matching_DomainsArgs = {
  directory_entry_public_id: Scalars['String']['input'];
};

export type QueryOffsetting_LinkArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryOffsetting_LinksArgs = {
  page?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};

export type QueryOrderArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOrder_Metadata_ValuesArgs = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type QueryOrder_Property_ValuesArgs = {
  key: OrderFilterPropertiesEnum;
  value: Scalars['String']['input'];
};

export type QueryOrdersArgs = {
  co2e?: InputMaybe<RangeInput>;
  date_range?: InputMaybe<DateRangeInput>;
  invoice_numbers?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<MetadataInput>>;
  page: Scalars['Int']['input'];
  public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  size: Scalars['Int']['input'];
  sorted_column: OrderSortedColumnEnum;
  sorted_order: SortedOrderEnum;
  status?: InputMaybe<Array<OrderStatusEnum>>;
};

export type QueryPortfolioArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPortfoliosArgs = {
  has_offsets_available?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProjectArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProjectsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  project_type?: InputMaybe<Array<Scalars['ID']['input']>>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryReportArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryReportsArgs = {
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  sorted_order: SortedOrderEnum;
};

export type QuerySelect_Plan_Item_Hosted_PageArgs = {
  plan_item_price_id: Scalars['String']['input'];
};

export type QuerySelect_Plan_Item_Hosted_Page_AcknowledgeArgs = {
  hosted_page_id: Scalars['String']['input'];
};

export type QuerySupplier_ContactArgs = {
  contact_public_id: Scalars['String']['input'];
};

export type QuerySupplier_DirectoryArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryUntracked_EmissionArgs = {
  public_id: Scalars['String']['input'];
};

export type RangeInput = {
  max: Scalars['Float']['input'];
  min: Scalars['Float']['input'];
};

export enum RecommendationAction {
  OverrideDefaultValue = 'OVERRIDE_DEFAULT_VALUE',
  ProvideMissingValue = 'PROVIDE_MISSING_VALUE',
  ProvideMoreSpecificValue = 'PROVIDE_MORE_SPECIFIC_VALUE',
}

export type Registry = {
  __typename?: 'Registry';
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  name_short?: Maybe<Scalars['String']['output']>;
  updated_date: Scalars['DateTimeISO']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type Report = {
  __typename?: 'Report';
  client: Client;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  prepared_for?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  share_token: Scalars['ID']['output'];
  type: ReportTypeEnum;
  version_number: Scalars['Int']['output'];
  visibility: ReportVisibilityEnum;
};

export enum ReportTypeEnum {
  Ghg = 'GHG',
  Glec = 'GLEC',
  ItemisedEmissions = 'ITEMISED_EMISSIONS',
}

export enum ReportVisibilityEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type Reports = {
  __typename?: 'Reports';
  items: Array<Report>;
  total_count: Scalars['Int']['output'];
};

export type ReviewDataCollectionRecipientsInput = {
  recipients: Array<Scalars['String']['input']>;
};

export type RoadRoutingConfiguration = {
  __typename?: 'RoadRoutingConfiguration';
  ferry_routing_preference?: Maybe<RoadRoutingFerryPreferenceEnum>;
};

export enum RoadRoutingFerryPreferenceEnum {
  AvoidSoft = 'AVOID_SOFT',
  AvoidStrict = 'AVOID_STRICT',
  Preferred = 'PREFERRED',
}

export type RouteDetails = {
  __typename?: 'RouteDetails';
  country_code_alpha_2?: Maybe<Scalars['String']['output']>;
  is_ferry?: Maybe<Scalars['Boolean']['output']>;
  is_motorway?: Maybe<Scalars['Boolean']['output']>;
  is_train?: Maybe<Scalars['Boolean']['output']>;
  is_urban?: Maybe<Scalars['Boolean']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  stops?: Maybe<Array<RouteStop>>;
};

export type RouteStop = {
  __typename?: 'RouteStop';
  code?: Maybe<Scalars['String']['output']>;
  stop_info?: Maybe<RouteStopInfo>;
};

export type RouteStopInfo = {
  __typename?: 'RouteStopInfo';
  code_alpha_2?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RoutingConfiguration = {
  __typename?: 'RoutingConfiguration';
  road?: Maybe<RoadRoutingConfiguration>;
  sea?: Maybe<SeaRoutingConfiguration>;
};

export enum S3FolderEnum {
  Client = 'CLIENT',
  ClientUser = 'CLIENT_USER',
}

export type S3Object = {
  __typename?: 'S3Object';
  bucket: Scalars['String']['output'];
  eTag: Scalars['String']['output'];
  key: Scalars['String']['output'];
  location: Scalars['String']['output'];
};

export type S3ObjectInput = {
  bucket: Scalars['String']['input'];
  eTag: Scalars['String']['input'];
  key: Scalars['String']['input'];
  location: Scalars['String']['input'];
};

export type SaveReportInput = {
  name: Scalars['String']['input'];
  prepared_for: Scalars['String']['input'];
};

export type ScopeBreakdown = {
  __typename?: 'ScopeBreakdown';
  co2e: EmissionNumber;
  label: Scalars['String']['output'];
};

export enum SeaRoutingAreaEnum {
  Babelmandeb = 'BABELMANDEB',
  Bering = 'BERING',
  Corinth = 'CORINTH',
  Dover = 'DOVER',
  Gibraltar = 'GIBRALTAR',
  Hra = 'HRA',
  Ice = 'ICE',
  Kiel = 'KIEL',
  Magellan = 'MAGELLAN',
  Malacca = 'MALACCA',
  Northeast = 'NORTHEAST',
  Northwest = 'NORTHWEST',
  Panama = 'PANAMA',
  Suez = 'SUEZ',
}

export type SeaRoutingConfiguration = {
  __typename?: 'SeaRoutingConfiguration';
  restricted_areas?: Maybe<Array<SeaRoutingAreaEnum>>;
};

export enum SortByAttributeEnum {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
}

export enum SortByOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortConfig = {
  attribute: SortByAttributeEnum;
  order: SortByOrderEnum;
};

export enum SortedOrderEnum {
  Ascend = 'ascend',
  Descend = 'descend',
}

export type StatusTimeline = {
  __typename?: 'StatusTimeline';
  client_user_avatar?: Maybe<S3Object>;
  client_user_first_name?: Maybe<Scalars['String']['output']>;
  client_user_id?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  impact_link_id?: Maybe<Scalars['String']['output']>;
  source_type_id?: Maybe<Scalars['String']['output']>;
  status: OrderStatusEnum;
};

export enum SustainableDevelopmentGoalsEnum {
  Ace = 'ACE',
  Ca = 'CA',
  Cws = 'CWS',
  Dweg = 'DWEG',
  Ge = 'GE',
  Ghw = 'GHW',
  Iii = 'III',
  Lbw = 'LBW',
  Ll = 'LL',
  Np = 'NP',
  Pg = 'PG',
  Pjsi = 'PJSI',
  Qe = 'QE',
  Rcp = 'RCP',
  Ri = 'RI',
  Scc = 'SCC',
  Zh = 'ZH',
}

export enum TaxIdTypeEnum {
  AeTrn = 'AE_TRN',
  AuAbn = 'AU_ABN',
  BrCnpj = 'BR_CNPJ',
  BrCpf = 'BR_CPF',
  CaBn = 'CA_BN',
  CaGstHst = 'CA_GST_HST',
  CaPstBc = 'CA_PST_BC',
  CaPstMb = 'CA_PST_MB',
  CaPstSk = 'CA_PST_SK',
  CaQst = 'CA_QST',
  ChVat = 'CH_VAT',
  ClTin = 'CL_TIN',
  EsCif = 'ES_CIF',
  EuVat = 'EU_VAT',
  GbVat = 'GB_VAT',
  HkBr = 'HK_BR',
  IdNpwp = 'ID_NPWP',
  IlVat = 'IL_VAT',
  InGst = 'IN_GST',
  JpCn = 'JP_CN',
  JpRn = 'JP_RN',
  KrBrn = 'KR_BRN',
  LiUid = 'LI_UID',
  MxRfc = 'MX_RFC',
  MyFrp = 'MY_FRP',
  MyItn = 'MY_ITN',
  MySst = 'MY_SST',
  NoVat = 'NO_VAT',
  NzGst = 'NZ_GST',
  RuInn = 'RU_INN',
  RuKpp = 'RU_KPP',
  SaVat = 'SA_VAT',
  SgGst = 'SG_GST',
  SgUen = 'SG_UEN',
  ThVat = 'TH_VAT',
  TwVat = 'TW_VAT',
  UsEin = 'US_EIN',
  ZaVat = 'ZA_VAT',
}

export enum TeamAccessEnum {
  InviteOnly = 'INVITE_ONLY',
  OpenAccess = 'OPEN_ACCESS',
}

export type TypeBreakdown = {
  __typename?: 'TypeBreakdown';
  scope_1?: Maybe<Array<TypeBreakdownItem>>;
  scope_2?: Maybe<Array<TypeBreakdownItem>>;
  scope_3?: Maybe<Array<TypeBreakdownItem>>;
};

export type TypeBreakdownItem = {
  __typename?: 'TypeBreakdownItem';
  biogenic: BiogenicBreakdown;
  co2e: EmissionNumber;
  label: Scalars['String']['output'];
  methodology: GhgMethodology;
};

export type UpdateClientBillingDetailsInput = {
  address?: InputMaybe<BillingAddressInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  vat?: InputMaybe<VatIdentificationNumberInput>;
};

export type UpdateClientBrandingSettingsInput = {
  avatar?: InputMaybe<S3ObjectInput>;
  branding?: InputMaybe<ClientBrandingInput>;
  logo?: InputMaybe<S3ObjectInput>;
};

export type UpdateClientInput = {
  address_1?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<S3ObjectInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  client_function?: InputMaybe<ClientFunctionEnum>;
  country?: InputMaybe<CountryInput>;
  legal_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip_code?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClientOrganizationPreferencesInput = {
  auto_transshipment?: InputMaybe<Scalars['Boolean']['input']>;
  emission_sharing_visibility?: InputMaybe<EmissionVisibilityEnum>;
  ferry_load_characteristics?: InputMaybe<FerryLoadCharacteristicsEnum>;
  road_routing_ferry_preference?: InputMaybe<RoadRoutingFerryPreferenceEnum>;
  sea_routing_restricted_areas?: InputMaybe<Array<SeaRoutingAreaEnum>>;
  shipment_reference_number_metadata_key?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClientTeamAccessInput = {
  id: Scalars['ID']['input'];
  team_access: TeamAccessEnum;
};

export type UpdateClientUserRoleInput = {
  id: Scalars['ID']['input'];
  role: ClientRoleEnum;
};

export type UpdateMfaInput = {
  mfa_enabled: Scalars['Boolean']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  avatar?: InputMaybe<S3ObjectInput>;
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
};

export type UploadEmissionsFile = {
  __typename?: 'UploadEmissionsFile';
  errors: Array<EmissionsFileError>;
  file_key?: Maybe<Scalars['String']['output']>;
  row_count: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

export type User = {
  __typename?: 'User';
  account_state: ClientUserAccountStateEnum;
  avatar?: Maybe<S3Object>;
  email: Scalars['String']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  job_function?: Maybe<ClientUserJobFunctionEnum>;
  last_name?: Maybe<Scalars['String']['output']>;
};

export type VatIdentificationNumber = {
  __typename?: 'VATIdentificationNumber';
  identifier: Scalars['String']['output'];
  prefix: Scalars['String']['output'];
};

export type VatIdentificationNumberInput = {
  identifier: Scalars['String']['input'];
  prefix: Scalars['String']['input'];
};

export type ViaLocation = LocationCode | LocationCoordinates;

export type Weight = {
  __typename?: 'Weight';
  unit?: Maybe<EmissionFreightWeightUnitEnum>;
  value: Scalars['Float']['output'];
};

export type WellKnownMetadata = {
  __typename?: 'WellKnownMetadata';
  client_key?: Maybe<Scalars['String']['output']>;
  lsp_key?: Maybe<Scalars['String']['output']>;
  shipment_id?: Maybe<Scalars['String']['output']>;
  supplier_key?: Maybe<Scalars['String']['output']>;
};

export type BuyOffsetting_FromBuyOffsettingFragment = { __typename?: 'BuyOffsetting'; paymentPageLink: string };

export type BuyOffsettingMutationVariables = Exact<{
  data: BuyOffsettingInput;
}>;

export type BuyOffsettingMutation = {
  __typename?: 'Mutation';
  buy_offsetting: { __typename?: 'BuyOffsetting'; paymentPageLink: string };
};

export type CacheTransactionIdMutationVariables = Exact<{
  hosted_page_id?: InputMaybe<Scalars['String']['input']>;
}>;

export type CacheTransactionIdMutation = { __typename?: 'Mutation'; cache_transaction_id: string };

export type CancelOffsettingMutationVariables = Exact<{
  data: CancelOffsettingInput;
}>;

export type CancelOffsettingMutation = { __typename?: 'Mutation'; cancel_offsetting: string };

export type ConfirmOffsetting_FromBuyOffsettingFragment = { __typename?: 'BuyOffsetting'; paymentPageLink: string };

export type ConfirmOffsettingMutationVariables = Exact<{
  data: ConfirmOffsettingInput;
}>;

export type ConfirmOffsettingMutation = {
  __typename?: 'Mutation';
  confirm_offsetting: { __typename?: 'BuyOffsetting'; paymentPageLink: string };
};

export type ComputeFreightEmissionMutationVariables = Exact<{
  input: Scalars['JSON']['input'];
}>;

export type ComputeFreightEmissionMutation = {
  __typename?: 'Mutation';
  compute_freight_emission: {
    __typename?: 'Emission';
    id: string;
    public_id: string;
    import_id?: string | null;
    type: EmissionTypeEnum;
    co2e: number;
    intensity_normalized?: number | null;
    activity_normalized?: number | null;
    activity_normalized_unit?: EmissionFactorUnitEnum | null;
    date: string;
    created_date: string;
    distance_km?: number | null;
    sequence_no?: number | null;
    level?: number | null;
    mode: EmissionModeEnum;
    weight_kg?: number | null;
    teu?: number | null;
    no_of_pax?: number | null;
    context_stringify?: string | null;
    source_type_name: EmissionSourceTypeEnum;
    has_parent: boolean;
    parent_id?: string | null;
    parent_public_id?: string | null;
    parent_child_count?: number | null;
    share_token: string;
    visibility?: EmissionVisibilityEnum | null;
    shipment_reference_number_metadata_key: string;
    shipment_reference_number_metadata_value?: string | null;
    client: {
      __typename?: 'Client';
      legal_name?: string | null;
      id: string;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    };
    meta_data?: Array<{ __typename?: 'Metadata'; key: string; display: string; value: string }> | null;
    quality?: {
      __typename?: 'EmissionQuality';
      overall?: EmissionQualityScoreEnum | null;
      origin_destination?: EmissionQualityScoreEnum | null;
      weight?: EmissionQualityScoreEnum | null;
      emission_intensity_factor?: EmissionQualityScoreEnum | null;
    } | null;
    parent_quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
    emission_children: Array<{
      __typename?: 'Emission';
      id: string;
      public_id: string;
      type: EmissionTypeEnum;
      mode: EmissionModeEnum;
      co2e: number;
      date: string;
      distance_km?: number | null;
      weight_kg?: number | null;
      teu?: number | null;
      context_stringify?: string | null;
      sequence_no?: number | null;
      level?: number | null;
      quality?: {
        __typename?: 'EmissionQuality';
        overall?: EmissionQualityScoreEnum | null;
        origin_destination?: EmissionQualityScoreEnum | null;
        weight?: EmissionQualityScoreEnum | null;
        emission_intensity_factor?: EmissionQualityScoreEnum | null;
      } | null;
      issues?: Array<{
        __typename?: 'EmissionIssue';
        param: string;
        input_value?: string | null;
        assumed_value?: string | null;
        recommendations: Array<{
          __typename?: 'EmissionIssueRecommendation';
          action: RecommendationAction;
          params: Array<string>;
        }>;
      }> | null;
      freight?: {
        __typename?: 'EmissionFreight';
        carriage: EmissionFreightCarriageEnum;
        vehicle: EmissionFreightVehicleEnum;
        vehicle_code?: string | null;
        vehicle_category: EmissionFreightVehicleCategoryEnum;
        fuel_type?: EmissionFreightFuelTypeEnum | null;
        fuel_consumption?: number | null;
        fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
        vessel_id?: string | null;
        vessel_name?: string | null;
        carrier_code?: string | null;
        carrier_name?: string | null;
        aircraft_code?: string | null;
        flight_no?: string | null;
        emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
        load_factor?: number | null;
        load_type?: EmissionFreightLoadTypeEnum | null;
        empty_running?: number | null;
        cargo_type: EmissionFreightCargoTypeEnum;
        is_refrigerated: boolean;
        from_address?: string | null;
        from_code?: string | null;
        from_country_code?: string | null;
        from_country_name?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_code?: string | null;
        to_country_code?: string | null;
        to_display?: string | null;
        route_details?: {
          __typename?: 'RouteDetails';
          is_urban?: boolean | null;
          is_motorway?: boolean | null;
          is_ferry?: boolean | null;
          is_train?: boolean | null;
          country_code_alpha_2?: string | null;
          path?: string | null;
          stops?: Array<{
            __typename?: 'RouteStop';
            code?: string | null;
            stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
          }> | null;
        } | null;
        fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      freight_aggregated?: {
        __typename?: 'EmissionFreightAggregated';
        start_date: string;
        end_date: string;
        vehicle: EmissionFreightAggregatedVehicleEnum;
        vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
        vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
        fuel_type: EmissionFreightAggregatedFuelTypeEnum;
        fuel_consumption: number;
        fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
        fuel_used: number;
        fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
        load_factor: number;
        distance_km: number;
        weight_kg: number;
        vehicle_id?: string | null;
        fleet_id?: string | null;
      } | null;
      flights?: {
        __typename?: 'EmissionFlights';
        no_of_pax: number;
        cabin_class?: EmissionFlightsClassEnum | null;
        airline?: string | null;
        aircraft?: string | null;
        flight_no?: string | null;
        from_code: string;
        from_display?: string | null;
        to_code: string;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
      } | null;
      last_mile?: {
        __typename?: 'EmissionLastMile';
        vehicle: EmissionLastMileVehicleEnum;
        vehicle_category: EmissionLastMileVehicleCategoryEnum;
        fuel_type?: EmissionLastMileFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
      } | null;
      ride_hailing?: {
        __typename?: 'EmissionRideHailing';
        vehicle: EmissionRideHailingVehicleEnum;
        vehicle_category: EmissionRideHailingVehicleCategoryEnum;
        fuel_type?: EmissionRideHailingFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        no_of_pax?: number | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
      } | null;
      details: Array<{
        __typename?: 'EmissionDetail';
        co2e: number;
        co2?: number | null;
        ch4?: number | null;
        n2o?: number | null;
        nox?: number | null;
        so2?: number | null;
        pm?: number | null;
        nmhc?: number | null;
        type?: EmissionDetailTypeEnum | null;
        subtype?: EmissionDetailSubtypeEnum | null;
        scope?: EmissionDetailScopeEnum | null;
        category?: EmissionDetailCategoryEnum | null;
      }>;
      emission_children: Array<{
        __typename?: 'Emission';
        id: string;
        public_id: string;
        type: EmissionTypeEnum;
        mode: EmissionModeEnum;
        co2e: number;
        date: string;
        distance_km?: number | null;
        weight_kg?: number | null;
        context_stringify?: string | null;
        sequence_no?: number | null;
        level?: number | null;
        issues?: Array<{
          __typename?: 'EmissionIssue';
          param: string;
          input_value?: string | null;
          assumed_value?: string | null;
          recommendations: Array<{
            __typename?: 'EmissionIssueRecommendation';
            action: RecommendationAction;
            params: Array<string>;
          }>;
        }> | null;
        freight?: {
          __typename?: 'EmissionFreight';
          carriage: EmissionFreightCarriageEnum;
          vehicle: EmissionFreightVehicleEnum;
          vehicle_code?: string | null;
          vehicle_category: EmissionFreightVehicleCategoryEnum;
          fuel_type?: EmissionFreightFuelTypeEnum | null;
          fuel_consumption?: number | null;
          fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
          vessel_id?: string | null;
          vessel_name?: string | null;
          carrier_code?: string | null;
          carrier_name?: string | null;
          aircraft_code?: string | null;
          flight_no?: string | null;
          emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
          load_factor?: number | null;
          load_type?: EmissionFreightLoadTypeEnum | null;
          empty_running?: number | null;
          cargo_type: EmissionFreightCargoTypeEnum;
          is_refrigerated: boolean;
          from_address?: string | null;
          from_code?: string | null;
          from_country_code?: string | null;
          from_country_name?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_code?: string | null;
          to_country_code?: string | null;
          to_display?: string | null;
          route_details?: {
            __typename?: 'RouteDetails';
            is_urban?: boolean | null;
            is_motorway?: boolean | null;
            is_ferry?: boolean | null;
            is_train?: boolean | null;
            country_code_alpha_2?: string | null;
            path?: string | null;
          } | null;
          fuel_identifier?: {
            __typename?: 'EmissionFreightFuelIdentifier';
            code: string;
            source?: string | null;
          } | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        freight_aggregated?: {
          __typename?: 'EmissionFreightAggregated';
          start_date: string;
          end_date: string;
          vehicle: EmissionFreightAggregatedVehicleEnum;
          vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
          vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
          fuel_type: EmissionFreightAggregatedFuelTypeEnum;
          fuel_consumption: number;
          fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
          fuel_used: number;
          fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
          load_factor: number;
          distance_km: number;
          weight_kg: number;
          vehicle_id?: string | null;
          fleet_id?: string | null;
        } | null;
        flights?: {
          __typename?: 'EmissionFlights';
          no_of_pax: number;
          cabin_class?: EmissionFlightsClassEnum | null;
          airline?: string | null;
          aircraft?: string | null;
          flight_no?: string | null;
          from_code: string;
          from_display?: string | null;
          to_code: string;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
        } | null;
        last_mile?: {
          __typename?: 'EmissionLastMile';
          vehicle: EmissionLastMileVehicleEnum;
          vehicle_category: EmissionLastMileVehicleCategoryEnum;
          fuel_type?: EmissionLastMileFuelTypeEnum | null;
          make?: string | null;
          model?: string | null;
          from_address?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
        } | null;
        ride_hailing?: {
          __typename?: 'EmissionRideHailing';
          vehicle: EmissionRideHailingVehicleEnum;
          vehicle_category: EmissionRideHailingVehicleCategoryEnum;
          fuel_type?: EmissionRideHailingFuelTypeEnum | null;
          make?: string | null;
          model?: string | null;
          no_of_pax?: number | null;
          from_address?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
        } | null;
        details: Array<{
          __typename?: 'EmissionDetail';
          co2e: number;
          co2?: number | null;
          ch4?: number | null;
          n2o?: number | null;
          nox?: number | null;
          so2?: number | null;
          pm?: number | null;
          nmhc?: number | null;
          type?: EmissionDetailTypeEnum | null;
          subtype?: EmissionDetailSubtypeEnum | null;
          scope?: EmissionDetailScopeEnum | null;
          category?: EmissionDetailCategoryEnum | null;
        }>;
      }>;
    }>;
    last_mile?: {
      __typename?: 'EmissionLastMile';
      vehicle: EmissionLastMileVehicleEnum;
      vehicle_category: EmissionLastMileVehicleCategoryEnum;
      fuel_type?: EmissionLastMileFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    ride_hailing?: {
      __typename?: 'EmissionRideHailing';
      vehicle: EmissionRideHailingVehicleEnum;
      vehicle_category: EmissionRideHailingVehicleCategoryEnum;
      fuel_type?: EmissionRideHailingFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      no_of_pax?: number | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    flights?: {
      __typename?: 'EmissionFlights';
      no_of_pax: number;
      cabin_class?: EmissionFlightsClassEnum | null;
      airline?: string | null;
      aircraft?: string | null;
      flight_no?: string | null;
      from_code: string;
      from_display?: string | null;
      to_code: string;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    freight?: {
      __typename?: 'EmissionFreight';
      carriage: EmissionFreightCarriageEnum;
      vehicle: EmissionFreightVehicleEnum;
      vehicle_code?: string | null;
      vehicle_category: EmissionFreightVehicleCategoryEnum;
      fuel_type?: EmissionFreightFuelTypeEnum | null;
      fuel_consumption?: number | null;
      fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
      vessel_id?: string | null;
      vessel_name?: string | null;
      carrier_code?: string | null;
      carrier_name?: string | null;
      aircraft_code?: string | null;
      flight_no?: string | null;
      emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
      load_factor?: number | null;
      load_type?: EmissionFreightLoadTypeEnum | null;
      empty_running?: number | null;
      cargo_type: EmissionFreightCargoTypeEnum;
      is_refrigerated: boolean;
      from_address?: string | null;
      from_code?: string | null;
      from_country_code?: string | null;
      from_country_name?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_code?: string | null;
      to_country_code?: string | null;
      to_display?: string | null;
      route_details?: {
        __typename?: 'RouteDetails';
        is_urban?: boolean | null;
        is_motorway?: boolean | null;
        is_ferry?: boolean | null;
        is_train?: boolean | null;
        country_code_alpha_2?: string | null;
        path?: string | null;
        stops?: Array<{
          __typename?: 'RouteStop';
          code?: string | null;
          stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
        }> | null;
      } | null;
      fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    client_user?: {
      __typename?: 'ClientUser';
      first_name?: string | null;
      last_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    } | null;
    details: Array<{
      __typename?: 'EmissionDetail';
      co2e: number;
      co2?: number | null;
      ch4?: number | null;
      n2o?: number | null;
      nox?: number | null;
      so2?: number | null;
      pm?: number | null;
      nmhc?: number | null;
      type?: EmissionDetailTypeEnum | null;
      subtype?: EmissionDetailSubtypeEnum | null;
      scope?: EmissionDetailScopeEnum | null;
      category?: EmissionDetailCategoryEnum | null;
    }>;
  };
};

export type CreateClientMutationVariables = Exact<{
  data: CreateClientInput;
}>;

export type CreateClientMutation = {
  __typename?: 'Mutation';
  create_client: {
    __typename?: 'CreateClient';
    redirect_to?: string | null;
    client: { __typename?: 'Client'; legal_name?: string | null; client_function?: ClientFunctionEnum | null };
  };
};

export type DeleteClientMetadataDisplayNameMutationVariables = Exact<{
  metadata_key: Scalars['String']['input'];
  metadata_value: Scalars['String']['input'];
}>;

export type DeleteClientMetadataDisplayNameMutation = {
  __typename?: 'Mutation';
  delete_metadata_display_name: {
    __typename?: 'ClientMetadataDisplayName';
    id: string;
    metadata_key: string;
    metadata_value: string;
    value_display_name: string;
  };
};

export type GetClientBillingDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientBillingDetailsQuery = {
  __typename?: 'Query';
  client_billing_details: {
    __typename?: 'GetClientBillingDetails';
    email?: string | null;
    address?: {
      __typename?: 'BillingAddress';
      country_code?: string | null;
      line1?: string | null;
      line2?: string | null;
      municipality?: string | null;
      zip?: string | null;
    } | null;
    vat?: { __typename?: 'VATIdentificationNumber'; prefix: string; identifier: string } | null;
  };
};

export type GetClientOffsettingCommissionRateQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientOffsettingCommissionRateQuery = {
  __typename?: 'Query';
  client: { __typename?: 'Client'; offsetting_commission_percentage: number };
};

export type GetClientManagePaymentSourcesHostedPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientManagePaymentSourcesHostedPageQuery = {
  __typename?: 'Query';
  client_manage_payment_sources_hosted_page: {
    __typename?: 'HostedPage';
    id?: string | null;
    type?: string | null;
    url?: string | null;
    state?: string | null;
    pass_thru_content?: string | null;
    created_at?: number | null;
    expires_at?: number | null;
    updated_at?: number | null;
    resource_version?: number | null;
    business_entity_id?: string | null;
  };
};

export type ClientMetadataDisplayNames_FromGetClientMetadataDisplayNamesFragment = {
  __typename?: 'ClientMetadataDisplayName';
  id: string;
  metadata_key: string;
  metadata_value: string;
  value_display_name: string;
};

export type GetClientMetadataDisplayNamesQueryVariables = Exact<{
  metadata_key: Scalars['String']['input'];
}>;

export type GetClientMetadataDisplayNamesQuery = {
  __typename?: 'Query';
  metadata_display_names: Array<{
    __typename?: 'ClientMetadataDisplayName';
    id: string;
    metadata_key: string;
    metadata_value: string;
    value_display_name: string;
  }>;
};

export type GetClientOrganizationDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientOrganizationDetailsQuery = {
  __typename?: 'Query';
  emission_metadata_keys_with_count: Array<{
    __typename?: 'EmissionMetadataKeysAndCountWithUniqueValues';
    key: string;
    count: number;
  }>;
  client: {
    __typename?: 'Client';
    id: string;
    public_id: string;
    legal_name?: string | null;
    client_function?: ClientFunctionEnum | null;
    shipment_reference_number_metadata_key: string;
    branding?: { __typename?: 'ClientBranding'; brandColour: string; accentColour: string } | null;
    config: {
      __typename?: 'ClientConfig';
      id: string;
      auto_transshipment: boolean;
      emission_sharing_visibility: EmissionVisibilityEnum;
      ferry_load_characteristics: FerryLoadCharacteristicsEnum;
      road_routing_ferry_preference: RoadRoutingFerryPreferenceEnum;
      sea_routing_restricted_areas: Array<SeaRoutingAreaEnum>;
    };
    avatar?: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string } | null;
    logo?: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string } | null;
  };
};

export type GetClientPaymentSourcesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['String']['input']>;
  sort_by?: InputMaybe<SortConfig>;
  status_filter?: InputMaybe<ClientPaymentSourceStatusFilter>;
}>;

export type GetClientPaymentSourcesQuery = {
  __typename?: 'Query';
  client_payment_sources: {
    __typename?: 'GetClientPaymentSourcesOutput';
    next_offset?: string | null;
    payment_sources: Array<{
      __typename?: 'PaymentSource';
      id: string;
      type: PaymentSourceTypeEnum;
      status: PaymentSourceStatusEnum;
      deleted: boolean;
      card?: {
        __typename?: 'Card';
        brand: CardBrandEnum;
        expiry_month: number;
        expiry_year: number;
        last_four_digits: string;
      } | null;
    }>;
  };
};

export type GetClientShipmentReferenceNumberMetadataKeyQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientShipmentReferenceNumberMetadataKeyQuery = {
  __typename?: 'Query';
  client: { __typename?: 'Client'; id: string; public_id: string; shipment_reference_number_metadata_key: string };
};

export type GetClientSubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientSubscriptionQuery = {
  __typename?: 'Query';
  client_subscription: {
    __typename?: 'ClientSubscription';
    next_billing_at?: string | null;
    plan_item_price?: {
      __typename?: 'PlanItemPrice';
      amount?: number | null;
      currency: CurrencyEnum;
      period: PlanItemPricePeriodEnum;
      is_discontinued: boolean;
    } | null;
    addons?: Array<{
      __typename?: 'Addon';
      id: string;
      amount: number;
      currency: CurrencyEnum;
      metered_quantity?: number | null;
    }> | null;
  };
};

export type GetClientTeamSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientTeamSettingsQuery = {
  __typename?: 'Query';
  client: {
    __typename?: 'Client';
    id: string;
    domains: Array<{ __typename?: 'ClientDomain'; id: string; domain: string; team_access: TeamAccessEnum }>;
  };
};

export type ClientUserProfile_FromGetClientUsersFragment = {
  __typename?: 'ClientUser';
  first_name?: string | null;
  last_name?: string | null;
  email: string;
  avatar?: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string } | null;
};

export type ClientUser_FromGetClientUsersFragment = {
  __typename?: 'ClientUser';
  id: string;
  account_state: ClientUserAccountStateEnum;
  first_name?: string | null;
  last_name?: string | null;
  email: string;
  role: { __typename?: 'ClientRole'; id: string; name: ClientRoleEnum };
  avatar?: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string } | null;
};

export type GetClientUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientUsersQuery = {
  __typename?: 'Query';
  client: {
    __typename?: 'Client';
    id: string;
    users: Array<{
      __typename?: 'ClientUser';
      id: string;
      account_state: ClientUserAccountStateEnum;
      first_name?: string | null;
      last_name?: string | null;
      email: string;
      role: { __typename?: 'ClientRole'; id: string; name: ClientRoleEnum };
      avatar?: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string } | null;
    }>;
  };
};

export type Client_FromUpdateClientFragment = {
  __typename?: 'Client';
  public_id: string;
  legal_name?: string | null;
  client_function?: ClientFunctionEnum | null;
  avatar?: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string } | null;
  logo?: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string } | null;
};

export type UpdateClientMutationVariables = Exact<{
  data: UpdateClientInput;
}>;

export type UpdateClientMutation = {
  __typename?: 'Mutation';
  update_client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    client_function?: ClientFunctionEnum | null;
    avatar?: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string } | null;
    logo?: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string } | null;
  };
};

export type UpdateClientAfterPaymentMethodChangeMutationVariables = Exact<{ [key: string]: never }>;

export type UpdateClientAfterPaymentMethodChangeMutation = {
  __typename?: 'Mutation';
  update_client_after_payment_method_update: boolean;
};

export type UpdateClientBillingDetailsMutationVariables = Exact<{
  data: UpdateClientBillingDetailsInput;
}>;

export type UpdateClientBillingDetailsMutation = { __typename?: 'Mutation'; update_client_billing_details: boolean };

export type UpdateClientBrandingSettingsMutationVariables = Exact<{
  data: UpdateClientBrandingSettingsInput;
}>;

export type UpdateClientBrandingSettingsMutation = {
  __typename?: 'Mutation';
  update_client_branding_settings: {
    __typename?: 'Client';
    id: string;
    public_id: string;
    avatar?: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string } | null;
    logo?: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string } | null;
    branding?: { __typename?: 'ClientBranding'; brandColour: string; accentColour: string } | null;
  };
};

export type UpdateClientMetadataValueDisplayNameMutationVariables = Exact<{
  metadata_key: Scalars['String']['input'];
  metadata_value: Scalars['String']['input'];
  value_display_name: Scalars['String']['input'];
}>;

export type UpdateClientMetadataValueDisplayNameMutation = {
  __typename?: 'Mutation';
  update_metadata_value_display_name: {
    __typename?: 'ClientMetadataDisplayName';
    id: string;
    metadata_key: string;
    metadata_value: string;
    value_display_name: string;
  };
};

export type UpdateClientOrganizationPreferencesMutationVariables = Exact<{
  data: UpdateClientOrganizationPreferencesInput;
}>;

export type UpdateClientOrganizationPreferencesMutation = {
  __typename?: 'Mutation';
  update_client_organization_preferences: {
    __typename?: 'Client';
    id: string;
    shipment_reference_number_metadata_key: string;
    config: {
      __typename?: 'ClientConfig';
      id: string;
      emission_sharing_visibility: EmissionVisibilityEnum;
      auto_transshipment: boolean;
      ferry_load_characteristics: FerryLoadCharacteristicsEnum;
      road_routing_ferry_preference: RoadRoutingFerryPreferenceEnum;
      sea_routing_restricted_areas: Array<SeaRoutingAreaEnum>;
    };
  };
};

export type UpdateClientTeamAccessMutationVariables = Exact<{
  data: Array<UpdateClientTeamAccessInput> | UpdateClientTeamAccessInput;
}>;

export type UpdateClientTeamAccessMutation = {
  __typename?: 'Mutation';
  update_client_team_access: {
    __typename?: 'Client';
    domains: Array<{ __typename?: 'ClientDomain'; id: string; domain: string; team_access: TeamAccessEnum }>;
  };
};

export type ApproveClientUserAccessMutationVariables = Exact<{
  id: Scalars['String']['input'];
  role: ClientRoleEnum;
}>;

export type ApproveClientUserAccessMutation = { __typename?: 'Mutation'; approve_client_user_access: string };

export type CheckVerificationTokenMfaMutationVariables = Exact<{
  data: MfaCheckVerification;
}>;

export type CheckVerificationTokenMfaMutation = {
  __typename?: 'Mutation';
  check_verification_token_mfa: { __typename?: 'MFA'; status: string };
};

export type DeleteClientUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteClientUserMutation = { __typename?: 'Mutation'; delete_client_user: string };

export type DenyClientUserAccessMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DenyClientUserAccessMutation = { __typename?: 'Mutation'; deny_client_user_access: string };

export type DismissClientUserTipMutationVariables = Exact<{
  type: ClientUserTipTypeEnum;
}>;

export type DismissClientUserTipMutation = {
  __typename?: 'Mutation';
  dismiss_client_user_tip: {
    __typename?: 'ClientUserTip';
    type: ClientUserTipTypeEnum;
    status: ClientUserTipStatusEnum;
  };
};

export type ClientUserEmailPreference_FromGetClientUserEmailPreferenceFragment = {
  __typename?: 'ClientUserEmailPreference';
  type: ClientUserEmailPreferenceTypeEnum;
  enabled: boolean;
};

export type GetClientUserEmailPreferenceQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientUserEmailPreferenceQuery = {
  __typename?: 'Query';
  get_email_preferences: Array<{
    __typename?: 'ClientUserEmailPreference';
    type: ClientUserEmailPreferenceTypeEnum;
    enabled: boolean;
  }>;
};

export type ClientUserMfa_FromGetClientUserMfaFragment = {
  __typename?: 'ClientUserMFA';
  phone?: string | null;
  mfa_enabled?: boolean | null;
};

export type GetClientUserMfaQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientUserMfaQuery = {
  __typename?: 'Query';
  get_mfa: { __typename?: 'ClientUserMFA'; phone?: string | null; mfa_enabled?: boolean | null };
};

export type GetClientUserTipQueryVariables = Exact<{
  type: ClientUserTipTypeEnum;
}>;

export type GetClientUserTipQuery = {
  __typename?: 'Query';
  client_user_tip: { __typename?: 'ClientUserTip'; type: ClientUserTipTypeEnum; status: ClientUserTipStatusEnum };
};

export type InviteClientUsersMutationVariables = Exact<{
  data: InviteClientUsersInput;
}>;

export type InviteClientUsersMutation = {
  __typename?: 'Mutation';
  invite_client_users: Array<{
    __typename?: 'ClientUser';
    id: string;
    account_state: ClientUserAccountStateEnum;
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    role: { __typename?: 'ClientRole'; id: string; name: ClientRoleEnum };
    avatar?: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string } | null;
  }>;
};

export type SendVerificationTokenMfaMutationVariables = Exact<{
  data: MfaSendVerification;
}>;

export type SendVerificationTokenMfaMutation = {
  __typename?: 'Mutation';
  send_verification_token_mfa: { __typename?: 'MFA'; status: string };
};

export type SetClientUserEmailPreferenceMutationVariables = Exact<{
  data: Array<ClientUserEmailPreferenceInput> | ClientUserEmailPreferenceInput;
}>;

export type SetClientUserEmailPreferenceMutation = {
  __typename?: 'Mutation';
  update_email_preferences: Array<{
    __typename?: 'ClientUserEmailPreference';
    type: ClientUserEmailPreferenceTypeEnum;
    enabled: boolean;
  }>;
};

export type UpdateMfaMutationVariables = Exact<{
  data: UpdateMfaInput;
}>;

export type UpdateMfaMutation = {
  __typename?: 'Mutation';
  update_mfa: { __typename?: 'ClientUserMFA'; phone?: string | null; mfa_enabled?: boolean | null };
};

export type UpdateClientUserRoleMutationVariables = Exact<{
  data: UpdateClientUserRoleInput;
}>;

export type UpdateClientUserRoleMutation = {
  __typename?: 'Mutation';
  update_client_user_role: {
    __typename?: 'ClientUser';
    id: string;
    role: { __typename?: 'ClientRole'; name: ClientRoleEnum };
  };
};

export type ConfirmCsvFileForCalculationMutationVariables = Exact<{
  fileKey: Scalars['String']['input'];
}>;

export type ConfirmCsvFileForCalculationMutation = {
  __typename?: 'Mutation';
  confirm_csv_file_for_calculation: { __typename?: 'ConfirmCSVFileForCalculation'; import_id: string };
};

export type UploadEmissionsFileMutationVariables = Exact<{
  vertical: ImportVerticalEnum;
  file: Scalars['Upload']['input'];
  dateFormat: Scalars['String']['input'];
  numberFormat: Scalars['String']['input'];
}>;

export type UploadEmissionsFileMutation = {
  __typename?: 'Mutation';
  upload_emissions_file: {
    __typename?: 'UploadEmissionsFile';
    success: boolean;
    file_key?: string | null;
    row_count: number;
    errors: Array<{
      __typename?: 'EmissionsFileError';
      row?: number | null;
      column?: string | null;
      error: string;
      type: EmissionsFileErrorTypeEnum;
    }>;
  };
};

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
  folder: S3FolderEnum;
}>;

export type UploadFileMutation = {
  __typename?: 'Mutation';
  upload_file: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string };
};

export type Country_FromGetCountriesFragment = { __typename?: 'Country'; name: string; code: string };

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  __typename?: 'Query';
  countries: Array<{ __typename?: 'Country'; name: string; code: string }>;
};

export type GetDashboardQueryVariables = Exact<{
  type: Scalars['String']['input'];
}>;

export type GetDashboardQuery = { __typename?: 'Query'; dashboard: { __typename?: 'Dashboards'; token: string } };

export type DataCollection_FromCreateDataCollectionFragment = {
  __typename?: 'DataCollection';
  id: string;
  public_id: string;
};

export type CreateDataCollectionMutationVariables = Exact<{
  input: CreateDataCollectionInput;
}>;

export type CreateDataCollectionMutation = {
  __typename?: 'Mutation';
  create_data_collection: { __typename?: 'DataCollection'; id: string; public_id: string };
};

export type DataCollection_FromGetDataCollectionFragment = {
  __typename?: 'DataCollection';
  id: string;
  public_id: string;
  period_start_date: string;
  period_end_date: string;
  updated_date: string;
  latest_request_updated_date: string;
  requests: Array<{
    __typename?: 'DataCollectionRequest';
    id: string;
    public_id: string;
    state: DataCollectionRequestStateEnum;
    domain: string;
    recipients: Array<{ __typename?: 'ClientUser'; email: string }>;
  }>;
};

export type GetDataCollectionQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetDataCollectionQuery = {
  __typename?: 'Query';
  data_collection: {
    __typename?: 'DataCollection';
    id: string;
    public_id: string;
    period_start_date: string;
    period_end_date: string;
    updated_date: string;
    latest_request_updated_date: string;
    requests: Array<{
      __typename?: 'DataCollectionRequest';
      id: string;
      public_id: string;
      state: DataCollectionRequestStateEnum;
      domain: string;
      recipients: Array<{ __typename?: 'ClientUser'; email: string }>;
    }>;
  };
};

export type GetDataCollectionRequestInboundQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetDataCollectionRequestInboundQuery = {
  __typename?: 'Query';
  data_collection_request_inbound: {
    __typename?: 'DataCollectionRequest';
    id: string;
    public_id: string;
    domain: string;
    state: DataCollectionRequestStateEnum;
    created_date: string;
    updated_date: string;
    recipients: Array<{ __typename?: 'ClientUser'; email: string }>;
    activities: Array<
      | {
          __typename?: 'DataCollectionRequestAcceptedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestApprovedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestCancelledActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestDataStagedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
          emissions_aggregations?: {
            __typename?: 'EmissionsAggregations';
            total_co2e: number;
            total_activity: number;
            total_activity_unit: string;
            total_intensity: number;
            total_intensity_unit: string;
            overall_quality: number;
            total_shipments: number;
            activity_start_date: string;
            activity_end_date: string;
            emissions_by_mode: Array<{
              __typename?: 'EmissionsModeBreakdown';
              label: EmissionModeEnum;
              percentage?: number | null;
              co2e: number;
              count?: number | null;
              activity?: number | null;
              activity_unit?: string | null;
              intensity?: number | null;
              intensity_unit?: string | null;
            }>;
            emissions_by_lifecycle: Array<{
              __typename?: 'EmissionsLifecycleBreakdown';
              label: EmissionDetailTypeEnum;
              percentage?: number | null;
              co2e: number;
              activity?: number | null;
              activity_unit?: string | null;
              intensity?: number | null;
              intensity_unit?: string | null;
            }>;
          } | null;
        }
      | {
          __typename?: 'DataCollectionRequestDeclinedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestDeliveryFailedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
        }
      | {
          __typename?: 'DataCollectionRequestRejectedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestRemindedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestRequestFailedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
        }
      | {
          __typename?: 'DataCollectionRequestRequestedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestSubmittedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
          emissions_aggregations?: {
            __typename?: 'EmissionsAggregations';
            total_co2e: number;
            total_activity: number;
            total_activity_unit: string;
            total_intensity: number;
            total_intensity_unit: string;
            overall_quality: number;
            total_shipments: number;
            activity_start_date: string;
            activity_end_date: string;
            emissions_by_mode: Array<{
              __typename?: 'EmissionsModeBreakdown';
              label: EmissionModeEnum;
              percentage?: number | null;
              co2e: number;
              count?: number | null;
              activity?: number | null;
              activity_unit?: string | null;
              intensity?: number | null;
              intensity_unit?: string | null;
            }>;
            emissions_by_lifecycle: Array<{
              __typename?: 'EmissionsLifecycleBreakdown';
              label: EmissionDetailTypeEnum;
              percentage?: number | null;
              co2e: number;
              activity?: number | null;
              activity_unit?: string | null;
              intensity?: number | null;
              intensity_unit?: string | null;
            }>;
          } | null;
        }
      | {
          __typename?: 'DataCollectionRequestViewedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
    >;
    data_collection: {
      __typename?: 'DataCollection';
      id: string;
      public_id: string;
      created_date: string;
      period_start_date: string;
      period_end_date: string;
      client: {
        __typename?: 'Client';
        public_id: string;
        legal_name?: string | null;
        avatar?: { __typename?: 'S3Object'; key: string } | null;
        logo?: { __typename?: 'S3Object'; key: string } | null;
      };
      client_user: {
        __typename?: 'ClientUser';
        first_name?: string | null;
        last_name?: string | null;
        email: string;
        avatar?: { __typename?: 'S3Object'; key: string } | null;
      };
    };
  };
};

export type ClientProfile_FromGetDataCollectionRequestFragment = {
  __typename?: 'Client';
  public_id: string;
  legal_name?: string | null;
  avatar?: { __typename?: 'S3Object'; key: string } | null;
  logo?: { __typename?: 'S3Object'; key: string } | null;
};

export type ClientUserProfile_FromGetDataCollectionRequestFragment = {
  __typename?: 'ClientUser';
  first_name?: string | null;
  last_name?: string | null;
  email: string;
  avatar?: { __typename?: 'S3Object'; key: string } | null;
};

export type DataCollection_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollection';
  id: string;
  public_id: string;
  created_date: string;
  period_start_date: string;
  period_end_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type EmissionsModeBreakdown_FromGetDataCollectionRequestFragment = {
  __typename?: 'EmissionsModeBreakdown';
  label: EmissionModeEnum;
  percentage?: number | null;
  co2e: number;
  count?: number | null;
  activity?: number | null;
  activity_unit?: string | null;
  intensity?: number | null;
  intensity_unit?: string | null;
};

export type EmissionsLifecycleBreakdown_FromGetDataCollectionRequestFragment = {
  __typename?: 'EmissionsLifecycleBreakdown';
  label: EmissionDetailTypeEnum;
  percentage?: number | null;
  co2e: number;
  activity?: number | null;
  activity_unit?: string | null;
  intensity?: number | null;
  intensity_unit?: string | null;
};

export type EmissionsAggregations_FromGetDataCollectionRequestFragment = {
  __typename?: 'EmissionsAggregations';
  total_co2e: number;
  total_activity: number;
  total_activity_unit: string;
  total_intensity: number;
  total_intensity_unit: string;
  overall_quality: number;
  total_shipments: number;
  activity_start_date: string;
  activity_end_date: string;
  emissions_by_mode: Array<{
    __typename?: 'EmissionsModeBreakdown';
    label: EmissionModeEnum;
    percentage?: number | null;
    co2e: number;
    count?: number | null;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
  emissions_by_lifecycle: Array<{
    __typename?: 'EmissionsLifecycleBreakdown';
    label: EmissionDetailTypeEnum;
    percentage?: number | null;
    co2e: number;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
};

export type DataCollectionRequestRequestedActivity_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollectionRequestRequestedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestAcceptedActivity_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollectionRequestAcceptedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestDeclinedActivity_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollectionRequestDeclinedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestCancelledActivity_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollectionRequestCancelledActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestDataStagedActivity_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollectionRequestDataStagedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
  emissions_aggregations?: {
    __typename?: 'EmissionsAggregations';
    total_co2e: number;
    total_activity: number;
    total_activity_unit: string;
    total_intensity: number;
    total_intensity_unit: string;
    overall_quality: number;
    total_shipments: number;
    activity_start_date: string;
    activity_end_date: string;
    emissions_by_mode: Array<{
      __typename?: 'EmissionsModeBreakdown';
      label: EmissionModeEnum;
      percentage?: number | null;
      co2e: number;
      count?: number | null;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
    emissions_by_lifecycle: Array<{
      __typename?: 'EmissionsLifecycleBreakdown';
      label: EmissionDetailTypeEnum;
      percentage?: number | null;
      co2e: number;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
  } | null;
};

export type DataCollectionRequestSubmittedActivity_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollectionRequestSubmittedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
  emissions_aggregations?: {
    __typename?: 'EmissionsAggregations';
    total_co2e: number;
    total_activity: number;
    total_activity_unit: string;
    total_intensity: number;
    total_intensity_unit: string;
    overall_quality: number;
    total_shipments: number;
    activity_start_date: string;
    activity_end_date: string;
    emissions_by_mode: Array<{
      __typename?: 'EmissionsModeBreakdown';
      label: EmissionModeEnum;
      percentage?: number | null;
      co2e: number;
      count?: number | null;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
    emissions_by_lifecycle: Array<{
      __typename?: 'EmissionsLifecycleBreakdown';
      label: EmissionDetailTypeEnum;
      percentage?: number | null;
      co2e: number;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
  } | null;
};

export type DataCollectionRequestRejectedActivity_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollectionRequestRejectedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestApprovedActivity_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollectionRequestApprovedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestRemindedActivity_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollectionRequestRemindedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequestDeliveryFailedActivity_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollectionRequestDeliveryFailedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
};

export type DataCollectionRequestRequestFailedActivity_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollectionRequestRequestFailedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
};

export type DataCollectionRequestViewedActivity_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollectionRequestViewedActivity';
  id: string;
  event: DataCollectionRequestEventEnum;
  note?: string | null;
  created_date: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
    logo?: { __typename?: 'S3Object'; key: string } | null;
  };
  client_user: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type DataCollectionRequest_FromGetDataCollectionRequestFragment = {
  __typename?: 'DataCollectionRequest';
  id: string;
  public_id: string;
  domain: string;
  state: DataCollectionRequestStateEnum;
  created_date: string;
  updated_date: string;
  recipients: Array<{ __typename?: 'ClientUser'; email: string }>;
  activities: Array<
    | {
        __typename?: 'DataCollectionRequestAcceptedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestApprovedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestCancelledActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestDataStagedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
        emissions_aggregations?: {
          __typename?: 'EmissionsAggregations';
          total_co2e: number;
          total_activity: number;
          total_activity_unit: string;
          total_intensity: number;
          total_intensity_unit: string;
          overall_quality: number;
          total_shipments: number;
          activity_start_date: string;
          activity_end_date: string;
          emissions_by_mode: Array<{
            __typename?: 'EmissionsModeBreakdown';
            label: EmissionModeEnum;
            percentage?: number | null;
            co2e: number;
            count?: number | null;
            activity?: number | null;
            activity_unit?: string | null;
            intensity?: number | null;
            intensity_unit?: string | null;
          }>;
          emissions_by_lifecycle: Array<{
            __typename?: 'EmissionsLifecycleBreakdown';
            label: EmissionDetailTypeEnum;
            percentage?: number | null;
            co2e: number;
            activity?: number | null;
            activity_unit?: string | null;
            intensity?: number | null;
            intensity_unit?: string | null;
          }>;
        } | null;
      }
    | {
        __typename?: 'DataCollectionRequestDeclinedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestDeliveryFailedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
      }
    | {
        __typename?: 'DataCollectionRequestRejectedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestRemindedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestRequestFailedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
      }
    | {
        __typename?: 'DataCollectionRequestRequestedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
    | {
        __typename?: 'DataCollectionRequestSubmittedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
        emissions_aggregations?: {
          __typename?: 'EmissionsAggregations';
          total_co2e: number;
          total_activity: number;
          total_activity_unit: string;
          total_intensity: number;
          total_intensity_unit: string;
          overall_quality: number;
          total_shipments: number;
          activity_start_date: string;
          activity_end_date: string;
          emissions_by_mode: Array<{
            __typename?: 'EmissionsModeBreakdown';
            label: EmissionModeEnum;
            percentage?: number | null;
            co2e: number;
            count?: number | null;
            activity?: number | null;
            activity_unit?: string | null;
            intensity?: number | null;
            intensity_unit?: string | null;
          }>;
          emissions_by_lifecycle: Array<{
            __typename?: 'EmissionsLifecycleBreakdown';
            label: EmissionDetailTypeEnum;
            percentage?: number | null;
            co2e: number;
            activity?: number | null;
            activity_unit?: string | null;
            intensity?: number | null;
            intensity_unit?: string | null;
          }>;
        } | null;
      }
    | {
        __typename?: 'DataCollectionRequestViewedActivity';
        id: string;
        event: DataCollectionRequestEventEnum;
        note?: string | null;
        created_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      }
  >;
  data_collection: {
    __typename?: 'DataCollection';
    id: string;
    public_id: string;
    created_date: string;
    period_start_date: string;
    period_end_date: string;
    client: {
      __typename?: 'Client';
      public_id: string;
      legal_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
      logo?: { __typename?: 'S3Object'; key: string } | null;
    };
    client_user: {
      __typename?: 'ClientUser';
      first_name?: string | null;
      last_name?: string | null;
      email: string;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    };
  };
};

export type GetDataCollectionRequestOutboundQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetDataCollectionRequestOutboundQuery = {
  __typename?: 'Query';
  data_collection_request_outbound: {
    __typename?: 'DataCollectionRequest';
    id: string;
    public_id: string;
    domain: string;
    state: DataCollectionRequestStateEnum;
    created_date: string;
    updated_date: string;
    recipients: Array<{ __typename?: 'ClientUser'; email: string }>;
    activities: Array<
      | {
          __typename?: 'DataCollectionRequestAcceptedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestApprovedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestCancelledActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestDataStagedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
          emissions_aggregations?: {
            __typename?: 'EmissionsAggregations';
            total_co2e: number;
            total_activity: number;
            total_activity_unit: string;
            total_intensity: number;
            total_intensity_unit: string;
            overall_quality: number;
            total_shipments: number;
            activity_start_date: string;
            activity_end_date: string;
            emissions_by_mode: Array<{
              __typename?: 'EmissionsModeBreakdown';
              label: EmissionModeEnum;
              percentage?: number | null;
              co2e: number;
              count?: number | null;
              activity?: number | null;
              activity_unit?: string | null;
              intensity?: number | null;
              intensity_unit?: string | null;
            }>;
            emissions_by_lifecycle: Array<{
              __typename?: 'EmissionsLifecycleBreakdown';
              label: EmissionDetailTypeEnum;
              percentage?: number | null;
              co2e: number;
              activity?: number | null;
              activity_unit?: string | null;
              intensity?: number | null;
              intensity_unit?: string | null;
            }>;
          } | null;
        }
      | {
          __typename?: 'DataCollectionRequestDeclinedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestDeliveryFailedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
        }
      | {
          __typename?: 'DataCollectionRequestRejectedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestRemindedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestRequestFailedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
        }
      | {
          __typename?: 'DataCollectionRequestRequestedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestSubmittedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
          emissions_aggregations?: {
            __typename?: 'EmissionsAggregations';
            total_co2e: number;
            total_activity: number;
            total_activity_unit: string;
            total_intensity: number;
            total_intensity_unit: string;
            overall_quality: number;
            total_shipments: number;
            activity_start_date: string;
            activity_end_date: string;
            emissions_by_mode: Array<{
              __typename?: 'EmissionsModeBreakdown';
              label: EmissionModeEnum;
              percentage?: number | null;
              co2e: number;
              count?: number | null;
              activity?: number | null;
              activity_unit?: string | null;
              intensity?: number | null;
              intensity_unit?: string | null;
            }>;
            emissions_by_lifecycle: Array<{
              __typename?: 'EmissionsLifecycleBreakdown';
              label: EmissionDetailTypeEnum;
              percentage?: number | null;
              co2e: number;
              activity?: number | null;
              activity_unit?: string | null;
              intensity?: number | null;
              intensity_unit?: string | null;
            }>;
          } | null;
        }
      | {
          __typename?: 'DataCollectionRequestViewedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
    >;
    data_collection: {
      __typename?: 'DataCollection';
      id: string;
      public_id: string;
      created_date: string;
      period_start_date: string;
      period_end_date: string;
      client: {
        __typename?: 'Client';
        public_id: string;
        legal_name?: string | null;
        avatar?: { __typename?: 'S3Object'; key: string } | null;
        logo?: { __typename?: 'S3Object'; key: string } | null;
      };
      client_user: {
        __typename?: 'ClientUser';
        first_name?: string | null;
        last_name?: string | null;
        email: string;
        avatar?: { __typename?: 'S3Object'; key: string } | null;
      };
    };
  };
};

export type GetDataCollectionRequestReviewDataQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetDataCollectionRequestReviewDataQuery = {
  __typename?: 'Query';
  get_data_collection_request_review_data: {
    __typename?: 'DataCollectionRequestReviewData';
    total_co2e: number;
    total_activity: number;
    total_activity_unit: string;
    total_intensity: number;
    total_intensity_unit: string;
    overall_quality: number;
    total_shipments: number;
    activity_start_date: string;
    activity_end_date: string;
    emissions_by_mode: Array<{
      __typename?: 'EmissionsModeBreakdown';
      label: EmissionModeEnum;
      percentage?: number | null;
      co2e: number;
    }>;
    emissions_by_lifecycle: Array<{
      __typename?: 'EmissionsLifecycleBreakdown';
      label: EmissionDetailTypeEnum;
      percentage?: number | null;
      co2e: number;
    }>;
    data_collection_request: {
      __typename?: 'DataCollectionRequest';
      id: string;
      public_id: string;
      domain: string;
      state: DataCollectionRequestStateEnum;
      created_date: string;
      updated_date: string;
      recipients: Array<{ __typename?: 'ClientUser'; email: string }>;
      activities: Array<
        | {
            __typename?: 'DataCollectionRequestAcceptedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestApprovedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestCancelledActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestDataStagedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
            emissions_aggregations?: {
              __typename?: 'EmissionsAggregations';
              total_co2e: number;
              total_activity: number;
              total_activity_unit: string;
              total_intensity: number;
              total_intensity_unit: string;
              overall_quality: number;
              total_shipments: number;
              activity_start_date: string;
              activity_end_date: string;
              emissions_by_mode: Array<{
                __typename?: 'EmissionsModeBreakdown';
                label: EmissionModeEnum;
                percentage?: number | null;
                co2e: number;
                count?: number | null;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
              emissions_by_lifecycle: Array<{
                __typename?: 'EmissionsLifecycleBreakdown';
                label: EmissionDetailTypeEnum;
                percentage?: number | null;
                co2e: number;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
            } | null;
          }
        | {
            __typename?: 'DataCollectionRequestDeclinedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestDeliveryFailedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
          }
        | {
            __typename?: 'DataCollectionRequestRejectedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestRemindedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestRequestFailedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
          }
        | {
            __typename?: 'DataCollectionRequestRequestedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestSubmittedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
            emissions_aggregations?: {
              __typename?: 'EmissionsAggregations';
              total_co2e: number;
              total_activity: number;
              total_activity_unit: string;
              total_intensity: number;
              total_intensity_unit: string;
              overall_quality: number;
              total_shipments: number;
              activity_start_date: string;
              activity_end_date: string;
              emissions_by_mode: Array<{
                __typename?: 'EmissionsModeBreakdown';
                label: EmissionModeEnum;
                percentage?: number | null;
                co2e: number;
                count?: number | null;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
              emissions_by_lifecycle: Array<{
                __typename?: 'EmissionsLifecycleBreakdown';
                label: EmissionDetailTypeEnum;
                percentage?: number | null;
                co2e: number;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
            } | null;
          }
        | {
            __typename?: 'DataCollectionRequestViewedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
      >;
      data_collection: {
        __typename?: 'DataCollection';
        id: string;
        public_id: string;
        created_date: string;
        period_start_date: string;
        period_end_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      };
    };
  };
};

export type GetDataCollectionRequestReviewInventoryQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  sortedColumn: EmissionSortedColumnEnum;
  sortedOrder: SortedOrderEnum;
}>;

export type GetDataCollectionRequestReviewInventoryQuery = {
  __typename?: 'Query';
  get_data_collection_request_review_inventory: {
    __typename?: 'DataCollectionRequestReviewInventory';
    total_count: number;
    total_co2e: number;
    pagination_limit: number;
    items: Array<{
      __typename?: 'EmissionDocument';
      id: string;
      public_id: string;
      co2e: number;
      date: string;
      distance_km?: number | null;
      mode: EmissionModeEnum;
      weight_kg?: number | null;
      teu?: number | null;
      activity_normalized?: number | null;
      activity_unit_normalized?: EmissionFactorUnitEnum | null;
      intensity_normalized?: number | null;
      visibility?: EmissionVisibilityEnum | null;
      shipment_reference_number_metadata_key: string;
      shipment_reference_number_metadata_value?: string | null;
      well_known_metadata: {
        __typename?: 'WellKnownMetadata';
        client_key?: string | null;
        supplier_key?: string | null;
        lsp_key?: string | null;
        shipment_id?: string | null;
      };
      parent_quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
      children: Array<{
        __typename?: 'EmissionChildDocument';
        id: string;
        freight?: {
          __typename?: 'EmissionFreightDocument';
          id: string;
          from_address?: string | null;
          from_display?: string | null;
          from_code?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          to_code?: string | null;
          from_coordinates?: { __typename?: 'LatLong'; lat?: number | null; lon?: number | null } | null;
          to_coordinates?: { __typename?: 'LatLong'; lat?: number | null; lon?: number | null } | null;
        } | null;
      }>;
    }>;
  };
};

export type GetDataCollectionRequestTotalAndIndexedEmissionsCountQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetDataCollectionRequestTotalAndIndexedEmissionsCountQuery = {
  __typename?: 'Query';
  data_collection_request_total_and_indexed_emissions_copied_count?: {
    __typename?: 'GetDataCollectionRequestTotalAndIndexedEmissionsCount';
    total_submitted_emissions_count: number;
    total_indexed_copied_emissions_count: number;
  } | null;
};

export type GetDataCollectionRequestsQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  sortedColumn: DataCollectionRequestsSortedColumnEnum;
  sortedOrder: SortedOrderEnum;
}>;

export type GetDataCollectionRequestsQuery = {
  __typename?: 'Query';
  data_collection_requests: {
    __typename?: 'GetDataCollectionRequests';
    total_count: number;
    items: Array<{
      __typename?: 'DataCollectionRequest';
      id: string;
      public_id: string;
      domain: string;
      state: DataCollectionRequestStateEnum;
      created_date: string;
      updated_date: string;
      recipients: Array<{ __typename?: 'ClientUser'; email: string }>;
      activities: Array<
        | {
            __typename?: 'DataCollectionRequestAcceptedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestApprovedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestCancelledActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestDataStagedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
            emissions_aggregations?: {
              __typename?: 'EmissionsAggregations';
              total_co2e: number;
              total_activity: number;
              total_activity_unit: string;
              total_intensity: number;
              total_intensity_unit: string;
              overall_quality: number;
              total_shipments: number;
              activity_start_date: string;
              activity_end_date: string;
              emissions_by_mode: Array<{
                __typename?: 'EmissionsModeBreakdown';
                label: EmissionModeEnum;
                percentage?: number | null;
                co2e: number;
                count?: number | null;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
              emissions_by_lifecycle: Array<{
                __typename?: 'EmissionsLifecycleBreakdown';
                label: EmissionDetailTypeEnum;
                percentage?: number | null;
                co2e: number;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
            } | null;
          }
        | {
            __typename?: 'DataCollectionRequestDeclinedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestDeliveryFailedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
          }
        | {
            __typename?: 'DataCollectionRequestRejectedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestRemindedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestRequestFailedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
          }
        | {
            __typename?: 'DataCollectionRequestRequestedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
        | {
            __typename?: 'DataCollectionRequestSubmittedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
            emissions_aggregations?: {
              __typename?: 'EmissionsAggregations';
              total_co2e: number;
              total_activity: number;
              total_activity_unit: string;
              total_intensity: number;
              total_intensity_unit: string;
              overall_quality: number;
              total_shipments: number;
              activity_start_date: string;
              activity_end_date: string;
              emissions_by_mode: Array<{
                __typename?: 'EmissionsModeBreakdown';
                label: EmissionModeEnum;
                percentage?: number | null;
                co2e: number;
                count?: number | null;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
              emissions_by_lifecycle: Array<{
                __typename?: 'EmissionsLifecycleBreakdown';
                label: EmissionDetailTypeEnum;
                percentage?: number | null;
                co2e: number;
                activity?: number | null;
                activity_unit?: string | null;
                intensity?: number | null;
                intensity_unit?: string | null;
              }>;
            } | null;
          }
        | {
            __typename?: 'DataCollectionRequestViewedActivity';
            id: string;
            event: DataCollectionRequestEventEnum;
            note?: string | null;
            created_date: string;
            client: {
              __typename?: 'Client';
              public_id: string;
              legal_name?: string | null;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
              logo?: { __typename?: 'S3Object'; key: string } | null;
            };
            client_user: {
              __typename?: 'ClientUser';
              first_name?: string | null;
              last_name?: string | null;
              email: string;
              avatar?: { __typename?: 'S3Object'; key: string } | null;
            };
          }
      >;
      data_collection: {
        __typename?: 'DataCollection';
        id: string;
        public_id: string;
        created_date: string;
        period_start_date: string;
        period_end_date: string;
        client: {
          __typename?: 'Client';
          public_id: string;
          legal_name?: string | null;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
          logo?: { __typename?: 'S3Object'; key: string } | null;
        };
        client_user: {
          __typename?: 'ClientUser';
          first_name?: string | null;
          last_name?: string | null;
          email: string;
          avatar?: { __typename?: 'S3Object'; key: string } | null;
        };
      };
    }>;
  };
};

export type DataCollection_FromGetDataCollectionsFragment = {
  __typename?: 'DataCollection';
  id: string;
  public_id: string;
  period_start_date: string;
  period_end_date: string;
  updated_date: string;
  latest_request_updated_date: string;
  requests: Array<{ __typename?: 'DataCollectionRequest'; id: string; state: DataCollectionRequestStateEnum }>;
};

export type GetDataCollectionsQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  sortedColumn: DataCollectionSortedColumnEnum;
  sortedOrder: SortedOrderEnum;
}>;

export type GetDataCollectionsQuery = {
  __typename?: 'Query';
  data_collections: {
    __typename?: 'GetDataCollections';
    total_count: number;
    items: Array<{
      __typename?: 'DataCollection';
      id: string;
      public_id: string;
      period_start_date: string;
      period_end_date: string;
      updated_date: string;
      latest_request_updated_date: string;
      requests: Array<{ __typename?: 'DataCollectionRequest'; id: string; state: DataCollectionRequestStateEnum }>;
    }>;
  };
};

export type ReviewDataCollectionRecipientsMutationVariables = Exact<{
  input: ReviewDataCollectionRecipientsInput;
}>;

export type ReviewDataCollectionRecipientsMutation = {
  __typename?: 'Mutation';
  review_data_collection_recipients: boolean;
};

export type TransitionDataCollectionRequestMutationVariables = Exact<{
  public_id: Scalars['String']['input'];
  event: DataCollectionRequestEventEnum;
  note?: InputMaybe<Scalars['String']['input']>;
  supplier_key?: InputMaybe<Scalars['String']['input']>;
}>;

export type TransitionDataCollectionRequestMutation = {
  __typename?: 'Mutation';
  transition_data_collection_request: {
    __typename?: 'DataCollectionRequest';
    id: string;
    public_id: string;
    domain: string;
    state: DataCollectionRequestStateEnum;
    created_date: string;
    updated_date: string;
    recipients: Array<{ __typename?: 'ClientUser'; email: string }>;
    activities: Array<
      | {
          __typename?: 'DataCollectionRequestAcceptedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestApprovedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestCancelledActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestDataStagedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
          emissions_aggregations?: {
            __typename?: 'EmissionsAggregations';
            total_co2e: number;
            total_activity: number;
            total_activity_unit: string;
            total_intensity: number;
            total_intensity_unit: string;
            overall_quality: number;
            total_shipments: number;
            activity_start_date: string;
            activity_end_date: string;
            emissions_by_mode: Array<{
              __typename?: 'EmissionsModeBreakdown';
              label: EmissionModeEnum;
              percentage?: number | null;
              co2e: number;
              count?: number | null;
              activity?: number | null;
              activity_unit?: string | null;
              intensity?: number | null;
              intensity_unit?: string | null;
            }>;
            emissions_by_lifecycle: Array<{
              __typename?: 'EmissionsLifecycleBreakdown';
              label: EmissionDetailTypeEnum;
              percentage?: number | null;
              co2e: number;
              activity?: number | null;
              activity_unit?: string | null;
              intensity?: number | null;
              intensity_unit?: string | null;
            }>;
          } | null;
        }
      | {
          __typename?: 'DataCollectionRequestDeclinedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestDeliveryFailedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
        }
      | {
          __typename?: 'DataCollectionRequestRejectedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestRemindedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestRequestFailedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
        }
      | {
          __typename?: 'DataCollectionRequestRequestedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
      | {
          __typename?: 'DataCollectionRequestSubmittedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
          emissions_aggregations?: {
            __typename?: 'EmissionsAggregations';
            total_co2e: number;
            total_activity: number;
            total_activity_unit: string;
            total_intensity: number;
            total_intensity_unit: string;
            overall_quality: number;
            total_shipments: number;
            activity_start_date: string;
            activity_end_date: string;
            emissions_by_mode: Array<{
              __typename?: 'EmissionsModeBreakdown';
              label: EmissionModeEnum;
              percentage?: number | null;
              co2e: number;
              count?: number | null;
              activity?: number | null;
              activity_unit?: string | null;
              intensity?: number | null;
              intensity_unit?: string | null;
            }>;
            emissions_by_lifecycle: Array<{
              __typename?: 'EmissionsLifecycleBreakdown';
              label: EmissionDetailTypeEnum;
              percentage?: number | null;
              co2e: number;
              activity?: number | null;
              activity_unit?: string | null;
              intensity?: number | null;
              intensity_unit?: string | null;
            }>;
          } | null;
        }
      | {
          __typename?: 'DataCollectionRequestViewedActivity';
          id: string;
          event: DataCollectionRequestEventEnum;
          note?: string | null;
          created_date: string;
          client: {
            __typename?: 'Client';
            public_id: string;
            legal_name?: string | null;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
            logo?: { __typename?: 'S3Object'; key: string } | null;
          };
          client_user: {
            __typename?: 'ClientUser';
            first_name?: string | null;
            last_name?: string | null;
            email: string;
            avatar?: { __typename?: 'S3Object'; key: string } | null;
          };
        }
    >;
    data_collection: {
      __typename?: 'DataCollection';
      id: string;
      public_id: string;
      created_date: string;
      period_start_date: string;
      period_end_date: string;
      client: {
        __typename?: 'Client';
        public_id: string;
        legal_name?: string | null;
        avatar?: { __typename?: 'S3Object'; key: string } | null;
        logo?: { __typename?: 'S3Object'; key: string } | null;
      };
      client_user: {
        __typename?: 'ClientUser';
        first_name?: string | null;
        last_name?: string | null;
        email: string;
        avatar?: { __typename?: 'S3Object'; key: string } | null;
      };
    };
  };
};

export type DeleteTestModeDataMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteTestModeDataMutation = { __typename?: 'Mutation'; delete_test_mode_data: boolean };

export type GetApiInformationQueryVariables = Exact<{ [key: string]: never }>;

export type GetApiInformationQuery = {
  __typename?: 'Query';
  api_information: { __typename?: 'ApiInformation'; key: string; uri: string };
};

export type ApiKey_FromGetApiKeysFragment = {
  __typename?: 'ApiKey';
  id: string;
  name: string;
  api_key: string;
  created_date: string;
};

export type GetApiKeysQueryVariables = Exact<{
  test: Scalars['Boolean']['input'];
}>;

export type GetApiKeysQuery = {
  __typename?: 'Query';
  api_keys: Array<{ __typename?: 'ApiKey'; id: string; name: string; api_key: string; created_date: string }>;
};

export type GetApiLogQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetApiLogQuery = {
  __typename?: 'Query';
  api_log: {
    __typename?: 'ApiLog';
    id: string;
    date: string;
    api_key: string;
    endpoint: string;
    response_code: number;
    request: string;
    response: string;
  };
};

export type ApiLog_FromGetApiLogsFragment = {
  __typename?: 'ApiLog';
  id: string;
  date: string;
  api_key: string;
  endpoint: string;
  response_code: number;
  request: string;
  response: string;
};

export type GetApiLogsQueryVariables = Exact<{
  descend: Scalars['Boolean']['input'];
  exclusive_start_key?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetApiLogsQuery = {
  __typename?: 'Query';
  api_logs: {
    __typename?: 'GetApiLogs';
    last_evaluated_key?: string | null;
    items: Array<{
      __typename?: 'ApiLog';
      id: string;
      date: string;
      api_key: string;
      endpoint: string;
      response_code: number;
      request: string;
      response: string;
    }>;
  };
};

export type PopulateTestModeDataMutationVariables = Exact<{ [key: string]: never }>;

export type PopulateTestModeDataMutation = { __typename?: 'Mutation'; populate_test_mode_data: boolean };

export type RollApiKeyMutationVariables = Exact<{
  id: Scalars['String']['input'];
  test: Scalars['Boolean']['input'];
}>;

export type RollApiKeyMutation = { __typename?: 'Mutation'; roll_api_key: { __typename?: 'ApiKey'; id: string } };

export type AssociateSupplierWithNetworkMemberMutationVariables = Exact<{
  directory_entry_public_id: Scalars['String']['input'];
  network_member_public_id: Scalars['String']['input'];
}>;

export type AssociateSupplierWithNetworkMemberMutation = {
  __typename?: 'Mutation';
  associate_with_network_member: {
    __typename?: 'DirectoryEntry';
    name: string;
    public_id: string;
    references: Array<{ __typename?: 'DirectoryReference'; id: string; reference: string }>;
    domains: Array<{ __typename?: 'DirectoryDomain'; id: string; domain: string }>;
    contacts: Array<{
      __typename?: 'DirectoryContact';
      id: string;
      public_id: string;
      email: string;
      first_name?: string | null;
      last_name?: string | null;
    }>;
    network_member?: { __typename?: 'Client'; id: string; public_id: string } | null;
    meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
  };
};

export type NetworkMemberFragment = {
  __typename?: 'Client';
  id: string;
  public_id: string;
  legal_name?: string | null;
};

export type NetworkMembersMatchingDomainsQueryVariables = Exact<{
  directory_entry_public_id: Scalars['String']['input'];
}>;

export type NetworkMembersMatchingDomainsQuery = {
  __typename?: 'Query';
  network_members_matching_domains: Array<{
    __typename?: 'Client';
    id: string;
    public_id: string;
    legal_name?: string | null;
  }>;
};

export type ClientDirectoryEntriesQueryVariables = Exact<{ [key: string]: never }>;

export type ClientDirectoryEntriesQuery = {
  __typename?: 'Query';
  client_directories: Array<{
    __typename?: 'DirectoryEntry';
    name: string;
    public_id: string;
    references: Array<{ __typename?: 'DirectoryReference'; id: string; reference: string }>;
    domains: Array<{ __typename?: 'DirectoryDomain'; id: string; domain: string }>;
    contacts: Array<{
      __typename?: 'DirectoryContact';
      id: string;
      public_id: string;
      email: string;
      first_name?: string | null;
      last_name?: string | null;
    }>;
    network_member?: { __typename?: 'Client'; id: string; public_id: string } | null;
    meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
  }>;
};

export type ClientDirectoryEntryQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type ClientDirectoryEntryQuery = {
  __typename?: 'Query';
  client_directory: {
    __typename?: 'DirectoryEntry';
    name: string;
    public_id: string;
    references: Array<{ __typename?: 'DirectoryReference'; id: string; reference: string }>;
    domains: Array<{ __typename?: 'DirectoryDomain'; id: string; domain: string }>;
    contacts: Array<{
      __typename?: 'DirectoryContact';
      id: string;
      public_id: string;
      email: string;
      first_name?: string | null;
      last_name?: string | null;
    }>;
    network_member?: { __typename?: 'Client'; id: string; public_id: string } | null;
    meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
  };
};

export type CreateClientContactMutationVariables = Exact<{
  directory_entry_public_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateClientContactMutation = {
  __typename?: 'Mutation';
  create_client_contact: {
    __typename?: 'DirectoryContact';
    id: string;
    public_id: string;
    email: string;
    first_name?: string | null;
    last_name?: string | null;
  };
};

export type CreateClientDirectoryMutationVariables = Exact<{
  name: Scalars['String']['input'];
  clientKey: Scalars['String']['input'];
  domains?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type CreateClientDirectoryMutation = {
  __typename?: 'Mutation';
  create_client_directory: {
    __typename?: 'DirectoryEntry';
    name: string;
    public_id: string;
    references: Array<{ __typename?: 'DirectoryReference'; id: string; reference: string }>;
    domains: Array<{ __typename?: 'DirectoryDomain'; id: string; domain: string }>;
    contacts: Array<{
      __typename?: 'DirectoryContact';
      id: string;
      public_id: string;
      email: string;
      first_name?: string | null;
      last_name?: string | null;
    }>;
    network_member?: { __typename?: 'Client'; id: string; public_id: string } | null;
    meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
  };
};

export type CreateSupplierContactMutationVariables = Exact<{
  directory_entry_public_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateSupplierContactMutation = {
  __typename?: 'Mutation';
  create_supplier_contact: {
    __typename?: 'DirectoryContact';
    id: string;
    public_id: string;
    email: string;
    first_name?: string | null;
    last_name?: string | null;
  };
};

export type CreateSupplierDirectoryMutationVariables = Exact<{
  name: Scalars['String']['input'];
  supplierKey: Scalars['String']['input'];
  domains: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type CreateSupplierDirectoryMutation = {
  __typename?: 'Mutation';
  create_supplier_directory: {
    __typename?: 'DirectoryEntry';
    name: string;
    public_id: string;
    references: Array<{ __typename?: 'DirectoryReference'; id: string; reference: string }>;
    domains: Array<{ __typename?: 'DirectoryDomain'; id: string; domain: string }>;
    contacts: Array<{
      __typename?: 'DirectoryContact';
      id: string;
      public_id: string;
      email: string;
      first_name?: string | null;
      last_name?: string | null;
    }>;
    network_member?: { __typename?: 'Client'; id: string; public_id: string } | null;
    meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
  };
};

export type DeleteClientContactMutationVariables = Exact<{
  contact_public_id: Scalars['String']['input'];
}>;

export type DeleteClientContactMutation = { __typename?: 'Mutation'; delete_client_contact?: string | null };

export type DeleteSupplierContactMutationVariables = Exact<{
  contact_public_id: Scalars['String']['input'];
}>;

export type DeleteSupplierContactMutation = { __typename?: 'Mutation'; delete_supplier_contact?: string | null };

export type DirectoryEntryFragment = {
  __typename?: 'DirectoryEntry';
  name: string;
  public_id: string;
  references: Array<{ __typename?: 'DirectoryReference'; id: string; reference: string }>;
  domains: Array<{ __typename?: 'DirectoryDomain'; id: string; domain: string }>;
  contacts: Array<{
    __typename?: 'DirectoryContact';
    id: string;
    public_id: string;
    email: string;
    first_name?: string | null;
    last_name?: string | null;
  }>;
  network_member?: { __typename?: 'Client'; id: string; public_id: string } | null;
  meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
};

export type DirectoryContactFragment = {
  __typename?: 'DirectoryContact';
  id: string;
  public_id: string;
  email: string;
  first_name?: string | null;
  last_name?: string | null;
};

export type DirectoryReferenceFragment = { __typename?: 'DirectoryReference'; id: string; reference: string };

export type DirectoryDomainFragment = { __typename?: 'DirectoryDomain'; id: string; domain: string };

export type DirectoryEntryMetaDataFragment = {
  __typename?: 'DirectoryEntry';
  meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
};

export type ClientContactQueryVariables = Exact<{
  contact_public_id: Scalars['String']['input'];
}>;

export type ClientContactQuery = {
  __typename?: 'Query';
  client_contact: {
    __typename?: 'DirectoryContact';
    id: string;
    public_id: string;
    email: string;
    first_name?: string | null;
    last_name?: string | null;
  };
};

export type SupplierContactQueryVariables = Exact<{
  contact_public_id: Scalars['String']['input'];
}>;

export type SupplierContactQuery = {
  __typename?: 'Query';
  supplier_contact: {
    __typename?: 'DirectoryContact';
    id: string;
    public_id: string;
    email: string;
    first_name?: string | null;
    last_name?: string | null;
  };
};

export type SupplierDirectoryFragment = {
  __typename?: 'DirectoryEntry';
  name: string;
  public_id: string;
  references: Array<{ __typename?: 'DirectoryReference'; id: string; reference: string }>;
  domains: Array<{ __typename?: 'DirectoryDomain'; id: string; domain: string }>;
  contacts: Array<{
    __typename?: 'DirectoryContact';
    id: string;
    public_id: string;
    email: string;
    first_name?: string | null;
    last_name?: string | null;
  }>;
  network_member?: { __typename?: 'Client'; id: string; public_id: string } | null;
  meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
};

export type SupplierDirectoryEntriesQueryVariables = Exact<{ [key: string]: never }>;

export type SupplierDirectoryEntriesQuery = {
  __typename?: 'Query';
  supplier_directories: Array<{
    __typename?: 'DirectoryEntry';
    name: string;
    public_id: string;
    references: Array<{ __typename?: 'DirectoryReference'; id: string; reference: string }>;
    domains: Array<{ __typename?: 'DirectoryDomain'; id: string; domain: string }>;
    contacts: Array<{
      __typename?: 'DirectoryContact';
      id: string;
      public_id: string;
      email: string;
      first_name?: string | null;
      last_name?: string | null;
    }>;
    network_member?: { __typename?: 'Client'; id: string; public_id: string } | null;
    meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
  }>;
};

export type SupplierDirectoryEntryQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type SupplierDirectoryEntryQuery = {
  __typename?: 'Query';
  supplier_directory: {
    __typename?: 'DirectoryEntry';
    name: string;
    public_id: string;
    references: Array<{ __typename?: 'DirectoryReference'; id: string; reference: string }>;
    domains: Array<{ __typename?: 'DirectoryDomain'; id: string; domain: string }>;
    contacts: Array<{
      __typename?: 'DirectoryContact';
      id: string;
      public_id: string;
      email: string;
      first_name?: string | null;
      last_name?: string | null;
    }>;
    network_member?: { __typename?: 'Client'; id: string; public_id: string } | null;
    meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
  };
};

export type UpdateClientContactMutationVariables = Exact<{
  contact_public_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateClientContactMutation = {
  __typename?: 'Mutation';
  update_client_contact: {
    __typename?: 'DirectoryContact';
    id: string;
    public_id: string;
    email: string;
    first_name?: string | null;
    last_name?: string | null;
  };
};

export type UpdateClientDirectoryEntryMutationVariables = Exact<{
  public_id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;

export type UpdateClientDirectoryEntryMutation = {
  __typename?: 'Mutation';
  update_client_directory: {
    __typename?: 'DirectoryEntry';
    name: string;
    public_id: string;
    references: Array<{ __typename?: 'DirectoryReference'; id: string; reference: string }>;
    domains: Array<{ __typename?: 'DirectoryDomain'; id: string; domain: string }>;
    contacts: Array<{
      __typename?: 'DirectoryContact';
      id: string;
      public_id: string;
      email: string;
      first_name?: string | null;
      last_name?: string | null;
    }>;
    network_member?: { __typename?: 'Client'; id: string; public_id: string } | null;
    meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
  };
};

export type UpdateClientDirectoryEntryMetadataMutationVariables = Exact<{
  public_id: Scalars['String']['input'];
  meta_data: Array<DirectoryEntryMetadataInput> | DirectoryEntryMetadataInput;
}>;

export type UpdateClientDirectoryEntryMetadataMutation = {
  __typename?: 'Mutation';
  update_client_directory_metadata: {
    __typename?: 'DirectoryEntry';
    name: string;
    public_id: string;
    references: Array<{ __typename?: 'DirectoryReference'; id: string; reference: string }>;
    domains: Array<{ __typename?: 'DirectoryDomain'; id: string; domain: string }>;
    contacts: Array<{
      __typename?: 'DirectoryContact';
      id: string;
      public_id: string;
      email: string;
      first_name?: string | null;
      last_name?: string | null;
    }>;
    network_member?: { __typename?: 'Client'; id: string; public_id: string } | null;
    meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
  };
};

export type UpdateSupplierContactMutationVariables = Exact<{
  contact_public_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateSupplierContactMutation = {
  __typename?: 'Mutation';
  update_supplier_contact: {
    __typename?: 'DirectoryContact';
    id: string;
    public_id: string;
    email: string;
    first_name?: string | null;
    last_name?: string | null;
  };
};

export type UpdateSupplierDirectoryEntryMutationVariables = Exact<{
  public_id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;

export type UpdateSupplierDirectoryEntryMutation = {
  __typename?: 'Mutation';
  update_supplier_directory: {
    __typename?: 'DirectoryEntry';
    name: string;
    public_id: string;
    references: Array<{ __typename?: 'DirectoryReference'; id: string; reference: string }>;
    domains: Array<{ __typename?: 'DirectoryDomain'; id: string; domain: string }>;
    contacts: Array<{
      __typename?: 'DirectoryContact';
      id: string;
      public_id: string;
      email: string;
      first_name?: string | null;
      last_name?: string | null;
    }>;
    network_member?: { __typename?: 'Client'; id: string; public_id: string } | null;
    meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
  };
};

export type UpdateSupplierDirectoryEntryMetadataMutationVariables = Exact<{
  public_id: Scalars['String']['input'];
  meta_data: Array<DirectoryEntryMetadataInput> | DirectoryEntryMetadataInput;
}>;

export type UpdateSupplierDirectoryEntryMetadataMutation = {
  __typename?: 'Mutation';
  update_supplier_directory_metadata: {
    __typename?: 'DirectoryEntry';
    name: string;
    public_id: string;
    references: Array<{ __typename?: 'DirectoryReference'; id: string; reference: string }>;
    domains: Array<{ __typename?: 'DirectoryDomain'; id: string; domain: string }>;
    contacts: Array<{
      __typename?: 'DirectoryContact';
      id: string;
      public_id: string;
      email: string;
      first_name?: string | null;
      last_name?: string | null;
    }>;
    network_member?: { __typename?: 'Client'; id: string; public_id: string } | null;
    meta_data: Array<{ __typename?: 'Metadata'; key: string; value: string; display: string }>;
  };
};

export type Base_FreightEmission_FromGetEmissionFragment = {
  __typename?: 'EmissionFreight';
  carriage: EmissionFreightCarriageEnum;
  vehicle: EmissionFreightVehicleEnum;
  vehicle_code?: string | null;
  vehicle_category: EmissionFreightVehicleCategoryEnum;
  fuel_type?: EmissionFreightFuelTypeEnum | null;
  fuel_consumption?: number | null;
  fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
  vessel_id?: string | null;
  vessel_name?: string | null;
  carrier_code?: string | null;
  carrier_name?: string | null;
  aircraft_code?: string | null;
  flight_no?: string | null;
  emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
  load_factor?: number | null;
  load_type?: EmissionFreightLoadTypeEnum | null;
  empty_running?: number | null;
  cargo_type: EmissionFreightCargoTypeEnum;
  is_refrigerated: boolean;
  from_address?: string | null;
  from_code?: string | null;
  from_country_code?: string | null;
  from_country_name?: string | null;
  from_display?: string | null;
  to_address?: string | null;
  to_code?: string | null;
  to_country_code?: string | null;
  to_display?: string | null;
  fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
  from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
};

export type FreightEmission_FromGetEmissionFragment = {
  __typename?: 'EmissionFreight';
  carriage: EmissionFreightCarriageEnum;
  vehicle: EmissionFreightVehicleEnum;
  vehicle_code?: string | null;
  vehicle_category: EmissionFreightVehicleCategoryEnum;
  fuel_type?: EmissionFreightFuelTypeEnum | null;
  fuel_consumption?: number | null;
  fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
  vessel_id?: string | null;
  vessel_name?: string | null;
  carrier_code?: string | null;
  carrier_name?: string | null;
  aircraft_code?: string | null;
  flight_no?: string | null;
  emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
  load_factor?: number | null;
  load_type?: EmissionFreightLoadTypeEnum | null;
  empty_running?: number | null;
  cargo_type: EmissionFreightCargoTypeEnum;
  is_refrigerated: boolean;
  from_address?: string | null;
  from_code?: string | null;
  from_country_code?: string | null;
  from_country_name?: string | null;
  from_display?: string | null;
  to_address?: string | null;
  to_code?: string | null;
  to_country_code?: string | null;
  to_display?: string | null;
  route_details?: {
    __typename?: 'RouteDetails';
    is_urban?: boolean | null;
    is_motorway?: boolean | null;
    is_ferry?: boolean | null;
    is_train?: boolean | null;
    country_code_alpha_2?: string | null;
    path?: string | null;
  } | null;
  fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
  from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
};

export type FreightEmissionWithStops_FromGetEmissionFragment = {
  __typename?: 'EmissionFreight';
  carriage: EmissionFreightCarriageEnum;
  vehicle: EmissionFreightVehicleEnum;
  vehicle_code?: string | null;
  vehicle_category: EmissionFreightVehicleCategoryEnum;
  fuel_type?: EmissionFreightFuelTypeEnum | null;
  fuel_consumption?: number | null;
  fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
  vessel_id?: string | null;
  vessel_name?: string | null;
  carrier_code?: string | null;
  carrier_name?: string | null;
  aircraft_code?: string | null;
  flight_no?: string | null;
  emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
  load_factor?: number | null;
  load_type?: EmissionFreightLoadTypeEnum | null;
  empty_running?: number | null;
  cargo_type: EmissionFreightCargoTypeEnum;
  is_refrigerated: boolean;
  from_address?: string | null;
  from_code?: string | null;
  from_country_code?: string | null;
  from_country_name?: string | null;
  from_display?: string | null;
  to_address?: string | null;
  to_code?: string | null;
  to_country_code?: string | null;
  to_display?: string | null;
  route_details?: {
    __typename?: 'RouteDetails';
    is_urban?: boolean | null;
    is_motorway?: boolean | null;
    is_ferry?: boolean | null;
    is_train?: boolean | null;
    country_code_alpha_2?: string | null;
    path?: string | null;
    stops?: Array<{
      __typename?: 'RouteStop';
      code?: string | null;
      stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
    }> | null;
  } | null;
  fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
  from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
};

export type FreightAggregatedEmission_FromGetEmissionFragment = {
  __typename?: 'EmissionFreightAggregated';
  start_date: string;
  end_date: string;
  vehicle: EmissionFreightAggregatedVehicleEnum;
  vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
  vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
  fuel_type: EmissionFreightAggregatedFuelTypeEnum;
  fuel_consumption: number;
  fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
  fuel_used: number;
  fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
  load_factor: number;
  distance_km: number;
  weight_kg: number;
  vehicle_id?: string | null;
  fleet_id?: string | null;
};

export type LastMileEmission_FromGetEmissionFragment = {
  __typename?: 'EmissionLastMile';
  vehicle: EmissionLastMileVehicleEnum;
  vehicle_category: EmissionLastMileVehicleCategoryEnum;
  fuel_type?: EmissionLastMileFuelTypeEnum | null;
  make?: string | null;
  model?: string | null;
  from_address?: string | null;
  from_display?: string | null;
  to_address?: string | null;
  to_display?: string | null;
  from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
};

export type RideHailingEmission_FromGetEmissionFragment = {
  __typename?: 'EmissionRideHailing';
  vehicle: EmissionRideHailingVehicleEnum;
  vehicle_category: EmissionRideHailingVehicleCategoryEnum;
  fuel_type?: EmissionRideHailingFuelTypeEnum | null;
  make?: string | null;
  model?: string | null;
  no_of_pax?: number | null;
  from_address?: string | null;
  from_display?: string | null;
  to_address?: string | null;
  to_display?: string | null;
  from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
};

export type FlightsEmission_FromGetEmissionFragment = {
  __typename?: 'EmissionFlights';
  no_of_pax: number;
  cabin_class?: EmissionFlightsClassEnum | null;
  airline?: string | null;
  aircraft?: string | null;
  flight_no?: string | null;
  from_code: string;
  from_display?: string | null;
  to_code: string;
  to_display?: string | null;
  from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
};

export type SegmentEmissions_FromGetEmissionFragment = {
  __typename?: 'Emission';
  id: string;
  public_id: string;
  type: EmissionTypeEnum;
  mode: EmissionModeEnum;
  co2e: number;
  date: string;
  distance_km?: number | null;
  weight_kg?: number | null;
  context_stringify?: string | null;
  sequence_no?: number | null;
  level?: number | null;
  issues?: Array<{
    __typename?: 'EmissionIssue';
    param: string;
    input_value?: string | null;
    assumed_value?: string | null;
    recommendations: Array<{
      __typename?: 'EmissionIssueRecommendation';
      action: RecommendationAction;
      params: Array<string>;
    }>;
  }> | null;
  freight?: {
    __typename?: 'EmissionFreight';
    carriage: EmissionFreightCarriageEnum;
    vehicle: EmissionFreightVehicleEnum;
    vehicle_code?: string | null;
    vehicle_category: EmissionFreightVehicleCategoryEnum;
    fuel_type?: EmissionFreightFuelTypeEnum | null;
    fuel_consumption?: number | null;
    fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
    vessel_id?: string | null;
    vessel_name?: string | null;
    carrier_code?: string | null;
    carrier_name?: string | null;
    aircraft_code?: string | null;
    flight_no?: string | null;
    emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
    load_factor?: number | null;
    load_type?: EmissionFreightLoadTypeEnum | null;
    empty_running?: number | null;
    cargo_type: EmissionFreightCargoTypeEnum;
    is_refrigerated: boolean;
    from_address?: string | null;
    from_code?: string | null;
    from_country_code?: string | null;
    from_country_name?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_code?: string | null;
    to_country_code?: string | null;
    to_display?: string | null;
    route_details?: {
      __typename?: 'RouteDetails';
      is_urban?: boolean | null;
      is_motorway?: boolean | null;
      is_ferry?: boolean | null;
      is_train?: boolean | null;
      country_code_alpha_2?: string | null;
      path?: string | null;
    } | null;
    fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  freight_aggregated?: {
    __typename?: 'EmissionFreightAggregated';
    start_date: string;
    end_date: string;
    vehicle: EmissionFreightAggregatedVehicleEnum;
    vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
    vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
    fuel_type: EmissionFreightAggregatedFuelTypeEnum;
    fuel_consumption: number;
    fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
    fuel_used: number;
    fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
    load_factor: number;
    distance_km: number;
    weight_kg: number;
    vehicle_id?: string | null;
    fleet_id?: string | null;
  } | null;
  flights?: {
    __typename?: 'EmissionFlights';
    no_of_pax: number;
    cabin_class?: EmissionFlightsClassEnum | null;
    airline?: string | null;
    aircraft?: string | null;
    flight_no?: string | null;
    from_code: string;
    from_display?: string | null;
    to_code: string;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
  } | null;
  last_mile?: {
    __typename?: 'EmissionLastMile';
    vehicle: EmissionLastMileVehicleEnum;
    vehicle_category: EmissionLastMileVehicleCategoryEnum;
    fuel_type?: EmissionLastMileFuelTypeEnum | null;
    make?: string | null;
    model?: string | null;
    from_address?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
  } | null;
  ride_hailing?: {
    __typename?: 'EmissionRideHailing';
    vehicle: EmissionRideHailingVehicleEnum;
    vehicle_category: EmissionRideHailingVehicleCategoryEnum;
    fuel_type?: EmissionRideHailingFuelTypeEnum | null;
    make?: string | null;
    model?: string | null;
    no_of_pax?: number | null;
    from_address?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
  } | null;
  details: Array<{
    __typename?: 'EmissionDetail';
    co2e: number;
    co2?: number | null;
    ch4?: number | null;
    n2o?: number | null;
    nox?: number | null;
    so2?: number | null;
    pm?: number | null;
    nmhc?: number | null;
    type?: EmissionDetailTypeEnum | null;
    subtype?: EmissionDetailSubtypeEnum | null;
    scope?: EmissionDetailScopeEnum | null;
    category?: EmissionDetailCategoryEnum | null;
  }>;
};

export type ChildEmissions_FromGetEmissionFragment = {
  __typename?: 'Emission';
  id: string;
  public_id: string;
  type: EmissionTypeEnum;
  mode: EmissionModeEnum;
  co2e: number;
  date: string;
  distance_km?: number | null;
  weight_kg?: number | null;
  teu?: number | null;
  context_stringify?: string | null;
  sequence_no?: number | null;
  level?: number | null;
  quality?: {
    __typename?: 'EmissionQuality';
    overall?: EmissionQualityScoreEnum | null;
    origin_destination?: EmissionQualityScoreEnum | null;
    weight?: EmissionQualityScoreEnum | null;
    emission_intensity_factor?: EmissionQualityScoreEnum | null;
  } | null;
  issues?: Array<{
    __typename?: 'EmissionIssue';
    param: string;
    input_value?: string | null;
    assumed_value?: string | null;
    recommendations: Array<{
      __typename?: 'EmissionIssueRecommendation';
      action: RecommendationAction;
      params: Array<string>;
    }>;
  }> | null;
  freight?: {
    __typename?: 'EmissionFreight';
    carriage: EmissionFreightCarriageEnum;
    vehicle: EmissionFreightVehicleEnum;
    vehicle_code?: string | null;
    vehicle_category: EmissionFreightVehicleCategoryEnum;
    fuel_type?: EmissionFreightFuelTypeEnum | null;
    fuel_consumption?: number | null;
    fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
    vessel_id?: string | null;
    vessel_name?: string | null;
    carrier_code?: string | null;
    carrier_name?: string | null;
    aircraft_code?: string | null;
    flight_no?: string | null;
    emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
    load_factor?: number | null;
    load_type?: EmissionFreightLoadTypeEnum | null;
    empty_running?: number | null;
    cargo_type: EmissionFreightCargoTypeEnum;
    is_refrigerated: boolean;
    from_address?: string | null;
    from_code?: string | null;
    from_country_code?: string | null;
    from_country_name?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_code?: string | null;
    to_country_code?: string | null;
    to_display?: string | null;
    route_details?: {
      __typename?: 'RouteDetails';
      is_urban?: boolean | null;
      is_motorway?: boolean | null;
      is_ferry?: boolean | null;
      is_train?: boolean | null;
      country_code_alpha_2?: string | null;
      path?: string | null;
      stops?: Array<{
        __typename?: 'RouteStop';
        code?: string | null;
        stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
      }> | null;
    } | null;
    fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  freight_aggregated?: {
    __typename?: 'EmissionFreightAggregated';
    start_date: string;
    end_date: string;
    vehicle: EmissionFreightAggregatedVehicleEnum;
    vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
    vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
    fuel_type: EmissionFreightAggregatedFuelTypeEnum;
    fuel_consumption: number;
    fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
    fuel_used: number;
    fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
    load_factor: number;
    distance_km: number;
    weight_kg: number;
    vehicle_id?: string | null;
    fleet_id?: string | null;
  } | null;
  flights?: {
    __typename?: 'EmissionFlights';
    no_of_pax: number;
    cabin_class?: EmissionFlightsClassEnum | null;
    airline?: string | null;
    aircraft?: string | null;
    flight_no?: string | null;
    from_code: string;
    from_display?: string | null;
    to_code: string;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
  } | null;
  last_mile?: {
    __typename?: 'EmissionLastMile';
    vehicle: EmissionLastMileVehicleEnum;
    vehicle_category: EmissionLastMileVehicleCategoryEnum;
    fuel_type?: EmissionLastMileFuelTypeEnum | null;
    make?: string | null;
    model?: string | null;
    from_address?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
  } | null;
  ride_hailing?: {
    __typename?: 'EmissionRideHailing';
    vehicle: EmissionRideHailingVehicleEnum;
    vehicle_category: EmissionRideHailingVehicleCategoryEnum;
    fuel_type?: EmissionRideHailingFuelTypeEnum | null;
    make?: string | null;
    model?: string | null;
    no_of_pax?: number | null;
    from_address?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
  } | null;
  details: Array<{
    __typename?: 'EmissionDetail';
    co2e: number;
    co2?: number | null;
    ch4?: number | null;
    n2o?: number | null;
    nox?: number | null;
    so2?: number | null;
    pm?: number | null;
    nmhc?: number | null;
    type?: EmissionDetailTypeEnum | null;
    subtype?: EmissionDetailSubtypeEnum | null;
    scope?: EmissionDetailScopeEnum | null;
    category?: EmissionDetailCategoryEnum | null;
  }>;
  emission_children: Array<{
    __typename?: 'Emission';
    id: string;
    public_id: string;
    type: EmissionTypeEnum;
    mode: EmissionModeEnum;
    co2e: number;
    date: string;
    distance_km?: number | null;
    weight_kg?: number | null;
    context_stringify?: string | null;
    sequence_no?: number | null;
    level?: number | null;
    issues?: Array<{
      __typename?: 'EmissionIssue';
      param: string;
      input_value?: string | null;
      assumed_value?: string | null;
      recommendations: Array<{
        __typename?: 'EmissionIssueRecommendation';
        action: RecommendationAction;
        params: Array<string>;
      }>;
    }> | null;
    freight?: {
      __typename?: 'EmissionFreight';
      carriage: EmissionFreightCarriageEnum;
      vehicle: EmissionFreightVehicleEnum;
      vehicle_code?: string | null;
      vehicle_category: EmissionFreightVehicleCategoryEnum;
      fuel_type?: EmissionFreightFuelTypeEnum | null;
      fuel_consumption?: number | null;
      fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
      vessel_id?: string | null;
      vessel_name?: string | null;
      carrier_code?: string | null;
      carrier_name?: string | null;
      aircraft_code?: string | null;
      flight_no?: string | null;
      emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
      load_factor?: number | null;
      load_type?: EmissionFreightLoadTypeEnum | null;
      empty_running?: number | null;
      cargo_type: EmissionFreightCargoTypeEnum;
      is_refrigerated: boolean;
      from_address?: string | null;
      from_code?: string | null;
      from_country_code?: string | null;
      from_country_name?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_code?: string | null;
      to_country_code?: string | null;
      to_display?: string | null;
      route_details?: {
        __typename?: 'RouteDetails';
        is_urban?: boolean | null;
        is_motorway?: boolean | null;
        is_ferry?: boolean | null;
        is_train?: boolean | null;
        country_code_alpha_2?: string | null;
        path?: string | null;
      } | null;
      fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    freight_aggregated?: {
      __typename?: 'EmissionFreightAggregated';
      start_date: string;
      end_date: string;
      vehicle: EmissionFreightAggregatedVehicleEnum;
      vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
      vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
      fuel_type: EmissionFreightAggregatedFuelTypeEnum;
      fuel_consumption: number;
      fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
      fuel_used: number;
      fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
      load_factor: number;
      distance_km: number;
      weight_kg: number;
      vehicle_id?: string | null;
      fleet_id?: string | null;
    } | null;
    flights?: {
      __typename?: 'EmissionFlights';
      no_of_pax: number;
      cabin_class?: EmissionFlightsClassEnum | null;
      airline?: string | null;
      aircraft?: string | null;
      flight_no?: string | null;
      from_code: string;
      from_display?: string | null;
      to_code: string;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    last_mile?: {
      __typename?: 'EmissionLastMile';
      vehicle: EmissionLastMileVehicleEnum;
      vehicle_category: EmissionLastMileVehicleCategoryEnum;
      fuel_type?: EmissionLastMileFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    ride_hailing?: {
      __typename?: 'EmissionRideHailing';
      vehicle: EmissionRideHailingVehicleEnum;
      vehicle_category: EmissionRideHailingVehicleCategoryEnum;
      fuel_type?: EmissionRideHailingFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      no_of_pax?: number | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    details: Array<{
      __typename?: 'EmissionDetail';
      co2e: number;
      co2?: number | null;
      ch4?: number | null;
      n2o?: number | null;
      nox?: number | null;
      so2?: number | null;
      pm?: number | null;
      nmhc?: number | null;
      type?: EmissionDetailTypeEnum | null;
      subtype?: EmissionDetailSubtypeEnum | null;
      scope?: EmissionDetailScopeEnum | null;
      category?: EmissionDetailCategoryEnum | null;
    }>;
  }>;
};

export type Emission_FromGetEmissionFragment = {
  __typename?: 'Emission';
  id: string;
  public_id: string;
  import_id?: string | null;
  type: EmissionTypeEnum;
  co2e: number;
  intensity_normalized?: number | null;
  activity_normalized?: number | null;
  activity_normalized_unit?: EmissionFactorUnitEnum | null;
  date: string;
  created_date: string;
  distance_km?: number | null;
  sequence_no?: number | null;
  level?: number | null;
  mode: EmissionModeEnum;
  weight_kg?: number | null;
  teu?: number | null;
  no_of_pax?: number | null;
  context_stringify?: string | null;
  source_type_name: EmissionSourceTypeEnum;
  has_parent: boolean;
  parent_id?: string | null;
  parent_public_id?: string | null;
  parent_child_count?: number | null;
  share_token: string;
  visibility?: EmissionVisibilityEnum | null;
  shipment_reference_number_metadata_key: string;
  shipment_reference_number_metadata_value?: string | null;
  client: {
    __typename?: 'Client';
    legal_name?: string | null;
    id: string;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
  meta_data?: Array<{ __typename?: 'Metadata'; key: string; display: string; value: string }> | null;
  quality?: {
    __typename?: 'EmissionQuality';
    overall?: EmissionQualityScoreEnum | null;
    origin_destination?: EmissionQualityScoreEnum | null;
    weight?: EmissionQualityScoreEnum | null;
    emission_intensity_factor?: EmissionQualityScoreEnum | null;
  } | null;
  parent_quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
  emission_children: Array<{
    __typename?: 'Emission';
    id: string;
    public_id: string;
    type: EmissionTypeEnum;
    mode: EmissionModeEnum;
    co2e: number;
    date: string;
    distance_km?: number | null;
    weight_kg?: number | null;
    teu?: number | null;
    context_stringify?: string | null;
    sequence_no?: number | null;
    level?: number | null;
    quality?: {
      __typename?: 'EmissionQuality';
      overall?: EmissionQualityScoreEnum | null;
      origin_destination?: EmissionQualityScoreEnum | null;
      weight?: EmissionQualityScoreEnum | null;
      emission_intensity_factor?: EmissionQualityScoreEnum | null;
    } | null;
    issues?: Array<{
      __typename?: 'EmissionIssue';
      param: string;
      input_value?: string | null;
      assumed_value?: string | null;
      recommendations: Array<{
        __typename?: 'EmissionIssueRecommendation';
        action: RecommendationAction;
        params: Array<string>;
      }>;
    }> | null;
    freight?: {
      __typename?: 'EmissionFreight';
      carriage: EmissionFreightCarriageEnum;
      vehicle: EmissionFreightVehicleEnum;
      vehicle_code?: string | null;
      vehicle_category: EmissionFreightVehicleCategoryEnum;
      fuel_type?: EmissionFreightFuelTypeEnum | null;
      fuel_consumption?: number | null;
      fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
      vessel_id?: string | null;
      vessel_name?: string | null;
      carrier_code?: string | null;
      carrier_name?: string | null;
      aircraft_code?: string | null;
      flight_no?: string | null;
      emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
      load_factor?: number | null;
      load_type?: EmissionFreightLoadTypeEnum | null;
      empty_running?: number | null;
      cargo_type: EmissionFreightCargoTypeEnum;
      is_refrigerated: boolean;
      from_address?: string | null;
      from_code?: string | null;
      from_country_code?: string | null;
      from_country_name?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_code?: string | null;
      to_country_code?: string | null;
      to_display?: string | null;
      route_details?: {
        __typename?: 'RouteDetails';
        is_urban?: boolean | null;
        is_motorway?: boolean | null;
        is_ferry?: boolean | null;
        is_train?: boolean | null;
        country_code_alpha_2?: string | null;
        path?: string | null;
        stops?: Array<{
          __typename?: 'RouteStop';
          code?: string | null;
          stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
        }> | null;
      } | null;
      fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    freight_aggregated?: {
      __typename?: 'EmissionFreightAggregated';
      start_date: string;
      end_date: string;
      vehicle: EmissionFreightAggregatedVehicleEnum;
      vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
      vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
      fuel_type: EmissionFreightAggregatedFuelTypeEnum;
      fuel_consumption: number;
      fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
      fuel_used: number;
      fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
      load_factor: number;
      distance_km: number;
      weight_kg: number;
      vehicle_id?: string | null;
      fleet_id?: string | null;
    } | null;
    flights?: {
      __typename?: 'EmissionFlights';
      no_of_pax: number;
      cabin_class?: EmissionFlightsClassEnum | null;
      airline?: string | null;
      aircraft?: string | null;
      flight_no?: string | null;
      from_code: string;
      from_display?: string | null;
      to_code: string;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    last_mile?: {
      __typename?: 'EmissionLastMile';
      vehicle: EmissionLastMileVehicleEnum;
      vehicle_category: EmissionLastMileVehicleCategoryEnum;
      fuel_type?: EmissionLastMileFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    ride_hailing?: {
      __typename?: 'EmissionRideHailing';
      vehicle: EmissionRideHailingVehicleEnum;
      vehicle_category: EmissionRideHailingVehicleCategoryEnum;
      fuel_type?: EmissionRideHailingFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      no_of_pax?: number | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    details: Array<{
      __typename?: 'EmissionDetail';
      co2e: number;
      co2?: number | null;
      ch4?: number | null;
      n2o?: number | null;
      nox?: number | null;
      so2?: number | null;
      pm?: number | null;
      nmhc?: number | null;
      type?: EmissionDetailTypeEnum | null;
      subtype?: EmissionDetailSubtypeEnum | null;
      scope?: EmissionDetailScopeEnum | null;
      category?: EmissionDetailCategoryEnum | null;
    }>;
    emission_children: Array<{
      __typename?: 'Emission';
      id: string;
      public_id: string;
      type: EmissionTypeEnum;
      mode: EmissionModeEnum;
      co2e: number;
      date: string;
      distance_km?: number | null;
      weight_kg?: number | null;
      context_stringify?: string | null;
      sequence_no?: number | null;
      level?: number | null;
      issues?: Array<{
        __typename?: 'EmissionIssue';
        param: string;
        input_value?: string | null;
        assumed_value?: string | null;
        recommendations: Array<{
          __typename?: 'EmissionIssueRecommendation';
          action: RecommendationAction;
          params: Array<string>;
        }>;
      }> | null;
      freight?: {
        __typename?: 'EmissionFreight';
        carriage: EmissionFreightCarriageEnum;
        vehicle: EmissionFreightVehicleEnum;
        vehicle_code?: string | null;
        vehicle_category: EmissionFreightVehicleCategoryEnum;
        fuel_type?: EmissionFreightFuelTypeEnum | null;
        fuel_consumption?: number | null;
        fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
        vessel_id?: string | null;
        vessel_name?: string | null;
        carrier_code?: string | null;
        carrier_name?: string | null;
        aircraft_code?: string | null;
        flight_no?: string | null;
        emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
        load_factor?: number | null;
        load_type?: EmissionFreightLoadTypeEnum | null;
        empty_running?: number | null;
        cargo_type: EmissionFreightCargoTypeEnum;
        is_refrigerated: boolean;
        from_address?: string | null;
        from_code?: string | null;
        from_country_code?: string | null;
        from_country_name?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_code?: string | null;
        to_country_code?: string | null;
        to_display?: string | null;
        route_details?: {
          __typename?: 'RouteDetails';
          is_urban?: boolean | null;
          is_motorway?: boolean | null;
          is_ferry?: boolean | null;
          is_train?: boolean | null;
          country_code_alpha_2?: string | null;
          path?: string | null;
        } | null;
        fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      freight_aggregated?: {
        __typename?: 'EmissionFreightAggregated';
        start_date: string;
        end_date: string;
        vehicle: EmissionFreightAggregatedVehicleEnum;
        vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
        vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
        fuel_type: EmissionFreightAggregatedFuelTypeEnum;
        fuel_consumption: number;
        fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
        fuel_used: number;
        fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
        load_factor: number;
        distance_km: number;
        weight_kg: number;
        vehicle_id?: string | null;
        fleet_id?: string | null;
      } | null;
      flights?: {
        __typename?: 'EmissionFlights';
        no_of_pax: number;
        cabin_class?: EmissionFlightsClassEnum | null;
        airline?: string | null;
        aircraft?: string | null;
        flight_no?: string | null;
        from_code: string;
        from_display?: string | null;
        to_code: string;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
      } | null;
      last_mile?: {
        __typename?: 'EmissionLastMile';
        vehicle: EmissionLastMileVehicleEnum;
        vehicle_category: EmissionLastMileVehicleCategoryEnum;
        fuel_type?: EmissionLastMileFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
      } | null;
      ride_hailing?: {
        __typename?: 'EmissionRideHailing';
        vehicle: EmissionRideHailingVehicleEnum;
        vehicle_category: EmissionRideHailingVehicleCategoryEnum;
        fuel_type?: EmissionRideHailingFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        no_of_pax?: number | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
      } | null;
      details: Array<{
        __typename?: 'EmissionDetail';
        co2e: number;
        co2?: number | null;
        ch4?: number | null;
        n2o?: number | null;
        nox?: number | null;
        so2?: number | null;
        pm?: number | null;
        nmhc?: number | null;
        type?: EmissionDetailTypeEnum | null;
        subtype?: EmissionDetailSubtypeEnum | null;
        scope?: EmissionDetailScopeEnum | null;
        category?: EmissionDetailCategoryEnum | null;
      }>;
    }>;
  }>;
  last_mile?: {
    __typename?: 'EmissionLastMile';
    vehicle: EmissionLastMileVehicleEnum;
    vehicle_category: EmissionLastMileVehicleCategoryEnum;
    fuel_type?: EmissionLastMileFuelTypeEnum | null;
    make?: string | null;
    model?: string | null;
    from_address?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
  } | null;
  ride_hailing?: {
    __typename?: 'EmissionRideHailing';
    vehicle: EmissionRideHailingVehicleEnum;
    vehicle_category: EmissionRideHailingVehicleCategoryEnum;
    fuel_type?: EmissionRideHailingFuelTypeEnum | null;
    make?: string | null;
    model?: string | null;
    no_of_pax?: number | null;
    from_address?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
  } | null;
  flights?: {
    __typename?: 'EmissionFlights';
    no_of_pax: number;
    cabin_class?: EmissionFlightsClassEnum | null;
    airline?: string | null;
    aircraft?: string | null;
    flight_no?: string | null;
    from_code: string;
    from_display?: string | null;
    to_code: string;
    to_display?: string | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
  } | null;
  freight?: {
    __typename?: 'EmissionFreight';
    carriage: EmissionFreightCarriageEnum;
    vehicle: EmissionFreightVehicleEnum;
    vehicle_code?: string | null;
    vehicle_category: EmissionFreightVehicleCategoryEnum;
    fuel_type?: EmissionFreightFuelTypeEnum | null;
    fuel_consumption?: number | null;
    fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
    vessel_id?: string | null;
    vessel_name?: string | null;
    carrier_code?: string | null;
    carrier_name?: string | null;
    aircraft_code?: string | null;
    flight_no?: string | null;
    emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
    load_factor?: number | null;
    load_type?: EmissionFreightLoadTypeEnum | null;
    empty_running?: number | null;
    cargo_type: EmissionFreightCargoTypeEnum;
    is_refrigerated: boolean;
    from_address?: string | null;
    from_code?: string | null;
    from_country_code?: string | null;
    from_country_name?: string | null;
    from_display?: string | null;
    to_address?: string | null;
    to_code?: string | null;
    to_country_code?: string | null;
    to_display?: string | null;
    route_details?: {
      __typename?: 'RouteDetails';
      is_urban?: boolean | null;
      is_motorway?: boolean | null;
      is_ferry?: boolean | null;
      is_train?: boolean | null;
      country_code_alpha_2?: string | null;
      path?: string | null;
      stops?: Array<{
        __typename?: 'RouteStop';
        code?: string | null;
        stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
      }> | null;
    } | null;
    fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  } | null;
  client_user?: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  } | null;
  details: Array<{
    __typename?: 'EmissionDetail';
    co2e: number;
    co2?: number | null;
    ch4?: number | null;
    n2o?: number | null;
    nox?: number | null;
    so2?: number | null;
    pm?: number | null;
    nmhc?: number | null;
    type?: EmissionDetailTypeEnum | null;
    subtype?: EmissionDetailSubtypeEnum | null;
    scope?: EmissionDetailScopeEnum | null;
    category?: EmissionDetailCategoryEnum | null;
  }>;
};

export type EmissionDetail_FromGetEmissionFragment = {
  __typename?: 'EmissionDetail';
  co2e: number;
  co2?: number | null;
  ch4?: number | null;
  n2o?: number | null;
  nox?: number | null;
  so2?: number | null;
  pm?: number | null;
  nmhc?: number | null;
  type?: EmissionDetailTypeEnum | null;
  subtype?: EmissionDetailSubtypeEnum | null;
  scope?: EmissionDetailScopeEnum | null;
  category?: EmissionDetailCategoryEnum | null;
};

export type EmissionIssue_FromGetEmissionFragment = {
  __typename?: 'EmissionIssue';
  param: string;
  input_value?: string | null;
  assumed_value?: string | null;
  recommendations: Array<{
    __typename?: 'EmissionIssueRecommendation';
    action: RecommendationAction;
    params: Array<string>;
  }>;
};

export type EmissionParentQuality_FromGetEmissionFragment = {
  __typename?: 'EmissionParentQuality';
  overall?: number | null;
};

export type EmissionQuality_FromGetEmissionFragment = {
  __typename?: 'EmissionQuality';
  overall?: EmissionQualityScoreEnum | null;
  origin_destination?: EmissionQualityScoreEnum | null;
  weight?: EmissionQualityScoreEnum | null;
  emission_intensity_factor?: EmissionQualityScoreEnum | null;
};

export type GetEmissionQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEmissionQuery = {
  __typename?: 'Query';
  emission: {
    __typename?: 'Emission';
    id: string;
    public_id: string;
    import_id?: string | null;
    type: EmissionTypeEnum;
    co2e: number;
    intensity_normalized?: number | null;
    activity_normalized?: number | null;
    activity_normalized_unit?: EmissionFactorUnitEnum | null;
    date: string;
    created_date: string;
    distance_km?: number | null;
    sequence_no?: number | null;
    level?: number | null;
    mode: EmissionModeEnum;
    weight_kg?: number | null;
    teu?: number | null;
    no_of_pax?: number | null;
    context_stringify?: string | null;
    source_type_name: EmissionSourceTypeEnum;
    has_parent: boolean;
    parent_id?: string | null;
    parent_public_id?: string | null;
    parent_child_count?: number | null;
    share_token: string;
    visibility?: EmissionVisibilityEnum | null;
    shipment_reference_number_metadata_key: string;
    shipment_reference_number_metadata_value?: string | null;
    client: {
      __typename?: 'Client';
      legal_name?: string | null;
      id: string;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    };
    meta_data?: Array<{ __typename?: 'Metadata'; key: string; display: string; value: string }> | null;
    quality?: {
      __typename?: 'EmissionQuality';
      overall?: EmissionQualityScoreEnum | null;
      origin_destination?: EmissionQualityScoreEnum | null;
      weight?: EmissionQualityScoreEnum | null;
      emission_intensity_factor?: EmissionQualityScoreEnum | null;
    } | null;
    parent_quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
    emission_children: Array<{
      __typename?: 'Emission';
      id: string;
      public_id: string;
      type: EmissionTypeEnum;
      mode: EmissionModeEnum;
      co2e: number;
      date: string;
      distance_km?: number | null;
      weight_kg?: number | null;
      teu?: number | null;
      context_stringify?: string | null;
      sequence_no?: number | null;
      level?: number | null;
      quality?: {
        __typename?: 'EmissionQuality';
        overall?: EmissionQualityScoreEnum | null;
        origin_destination?: EmissionQualityScoreEnum | null;
        weight?: EmissionQualityScoreEnum | null;
        emission_intensity_factor?: EmissionQualityScoreEnum | null;
      } | null;
      issues?: Array<{
        __typename?: 'EmissionIssue';
        param: string;
        input_value?: string | null;
        assumed_value?: string | null;
        recommendations: Array<{
          __typename?: 'EmissionIssueRecommendation';
          action: RecommendationAction;
          params: Array<string>;
        }>;
      }> | null;
      freight?: {
        __typename?: 'EmissionFreight';
        carriage: EmissionFreightCarriageEnum;
        vehicle: EmissionFreightVehicleEnum;
        vehicle_code?: string | null;
        vehicle_category: EmissionFreightVehicleCategoryEnum;
        fuel_type?: EmissionFreightFuelTypeEnum | null;
        fuel_consumption?: number | null;
        fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
        vessel_id?: string | null;
        vessel_name?: string | null;
        carrier_code?: string | null;
        carrier_name?: string | null;
        aircraft_code?: string | null;
        flight_no?: string | null;
        emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
        load_factor?: number | null;
        load_type?: EmissionFreightLoadTypeEnum | null;
        empty_running?: number | null;
        cargo_type: EmissionFreightCargoTypeEnum;
        is_refrigerated: boolean;
        from_address?: string | null;
        from_code?: string | null;
        from_country_code?: string | null;
        from_country_name?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_code?: string | null;
        to_country_code?: string | null;
        to_display?: string | null;
        route_details?: {
          __typename?: 'RouteDetails';
          is_urban?: boolean | null;
          is_motorway?: boolean | null;
          is_ferry?: boolean | null;
          is_train?: boolean | null;
          country_code_alpha_2?: string | null;
          path?: string | null;
          stops?: Array<{
            __typename?: 'RouteStop';
            code?: string | null;
            stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
          }> | null;
        } | null;
        fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      freight_aggregated?: {
        __typename?: 'EmissionFreightAggregated';
        start_date: string;
        end_date: string;
        vehicle: EmissionFreightAggregatedVehicleEnum;
        vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
        vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
        fuel_type: EmissionFreightAggregatedFuelTypeEnum;
        fuel_consumption: number;
        fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
        fuel_used: number;
        fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
        load_factor: number;
        distance_km: number;
        weight_kg: number;
        vehicle_id?: string | null;
        fleet_id?: string | null;
      } | null;
      flights?: {
        __typename?: 'EmissionFlights';
        no_of_pax: number;
        cabin_class?: EmissionFlightsClassEnum | null;
        airline?: string | null;
        aircraft?: string | null;
        flight_no?: string | null;
        from_code: string;
        from_display?: string | null;
        to_code: string;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
      } | null;
      last_mile?: {
        __typename?: 'EmissionLastMile';
        vehicle: EmissionLastMileVehicleEnum;
        vehicle_category: EmissionLastMileVehicleCategoryEnum;
        fuel_type?: EmissionLastMileFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
      } | null;
      ride_hailing?: {
        __typename?: 'EmissionRideHailing';
        vehicle: EmissionRideHailingVehicleEnum;
        vehicle_category: EmissionRideHailingVehicleCategoryEnum;
        fuel_type?: EmissionRideHailingFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        no_of_pax?: number | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
      } | null;
      details: Array<{
        __typename?: 'EmissionDetail';
        co2e: number;
        co2?: number | null;
        ch4?: number | null;
        n2o?: number | null;
        nox?: number | null;
        so2?: number | null;
        pm?: number | null;
        nmhc?: number | null;
        type?: EmissionDetailTypeEnum | null;
        subtype?: EmissionDetailSubtypeEnum | null;
        scope?: EmissionDetailScopeEnum | null;
        category?: EmissionDetailCategoryEnum | null;
      }>;
      emission_children: Array<{
        __typename?: 'Emission';
        id: string;
        public_id: string;
        type: EmissionTypeEnum;
        mode: EmissionModeEnum;
        co2e: number;
        date: string;
        distance_km?: number | null;
        weight_kg?: number | null;
        context_stringify?: string | null;
        sequence_no?: number | null;
        level?: number | null;
        issues?: Array<{
          __typename?: 'EmissionIssue';
          param: string;
          input_value?: string | null;
          assumed_value?: string | null;
          recommendations: Array<{
            __typename?: 'EmissionIssueRecommendation';
            action: RecommendationAction;
            params: Array<string>;
          }>;
        }> | null;
        freight?: {
          __typename?: 'EmissionFreight';
          carriage: EmissionFreightCarriageEnum;
          vehicle: EmissionFreightVehicleEnum;
          vehicle_code?: string | null;
          vehicle_category: EmissionFreightVehicleCategoryEnum;
          fuel_type?: EmissionFreightFuelTypeEnum | null;
          fuel_consumption?: number | null;
          fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
          vessel_id?: string | null;
          vessel_name?: string | null;
          carrier_code?: string | null;
          carrier_name?: string | null;
          aircraft_code?: string | null;
          flight_no?: string | null;
          emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
          load_factor?: number | null;
          load_type?: EmissionFreightLoadTypeEnum | null;
          empty_running?: number | null;
          cargo_type: EmissionFreightCargoTypeEnum;
          is_refrigerated: boolean;
          from_address?: string | null;
          from_code?: string | null;
          from_country_code?: string | null;
          from_country_name?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_code?: string | null;
          to_country_code?: string | null;
          to_display?: string | null;
          route_details?: {
            __typename?: 'RouteDetails';
            is_urban?: boolean | null;
            is_motorway?: boolean | null;
            is_ferry?: boolean | null;
            is_train?: boolean | null;
            country_code_alpha_2?: string | null;
            path?: string | null;
          } | null;
          fuel_identifier?: {
            __typename?: 'EmissionFreightFuelIdentifier';
            code: string;
            source?: string | null;
          } | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        freight_aggregated?: {
          __typename?: 'EmissionFreightAggregated';
          start_date: string;
          end_date: string;
          vehicle: EmissionFreightAggregatedVehicleEnum;
          vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
          vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
          fuel_type: EmissionFreightAggregatedFuelTypeEnum;
          fuel_consumption: number;
          fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
          fuel_used: number;
          fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
          load_factor: number;
          distance_km: number;
          weight_kg: number;
          vehicle_id?: string | null;
          fleet_id?: string | null;
        } | null;
        flights?: {
          __typename?: 'EmissionFlights';
          no_of_pax: number;
          cabin_class?: EmissionFlightsClassEnum | null;
          airline?: string | null;
          aircraft?: string | null;
          flight_no?: string | null;
          from_code: string;
          from_display?: string | null;
          to_code: string;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
        } | null;
        last_mile?: {
          __typename?: 'EmissionLastMile';
          vehicle: EmissionLastMileVehicleEnum;
          vehicle_category: EmissionLastMileVehicleCategoryEnum;
          fuel_type?: EmissionLastMileFuelTypeEnum | null;
          make?: string | null;
          model?: string | null;
          from_address?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
        } | null;
        ride_hailing?: {
          __typename?: 'EmissionRideHailing';
          vehicle: EmissionRideHailingVehicleEnum;
          vehicle_category: EmissionRideHailingVehicleCategoryEnum;
          fuel_type?: EmissionRideHailingFuelTypeEnum | null;
          make?: string | null;
          model?: string | null;
          no_of_pax?: number | null;
          from_address?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
        } | null;
        details: Array<{
          __typename?: 'EmissionDetail';
          co2e: number;
          co2?: number | null;
          ch4?: number | null;
          n2o?: number | null;
          nox?: number | null;
          so2?: number | null;
          pm?: number | null;
          nmhc?: number | null;
          type?: EmissionDetailTypeEnum | null;
          subtype?: EmissionDetailSubtypeEnum | null;
          scope?: EmissionDetailScopeEnum | null;
          category?: EmissionDetailCategoryEnum | null;
        }>;
      }>;
    }>;
    last_mile?: {
      __typename?: 'EmissionLastMile';
      vehicle: EmissionLastMileVehicleEnum;
      vehicle_category: EmissionLastMileVehicleCategoryEnum;
      fuel_type?: EmissionLastMileFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    ride_hailing?: {
      __typename?: 'EmissionRideHailing';
      vehicle: EmissionRideHailingVehicleEnum;
      vehicle_category: EmissionRideHailingVehicleCategoryEnum;
      fuel_type?: EmissionRideHailingFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      no_of_pax?: number | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    flights?: {
      __typename?: 'EmissionFlights';
      no_of_pax: number;
      cabin_class?: EmissionFlightsClassEnum | null;
      airline?: string | null;
      aircraft?: string | null;
      flight_no?: string | null;
      from_code: string;
      from_display?: string | null;
      to_code: string;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    freight?: {
      __typename?: 'EmissionFreight';
      carriage: EmissionFreightCarriageEnum;
      vehicle: EmissionFreightVehicleEnum;
      vehicle_code?: string | null;
      vehicle_category: EmissionFreightVehicleCategoryEnum;
      fuel_type?: EmissionFreightFuelTypeEnum | null;
      fuel_consumption?: number | null;
      fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
      vessel_id?: string | null;
      vessel_name?: string | null;
      carrier_code?: string | null;
      carrier_name?: string | null;
      aircraft_code?: string | null;
      flight_no?: string | null;
      emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
      load_factor?: number | null;
      load_type?: EmissionFreightLoadTypeEnum | null;
      empty_running?: number | null;
      cargo_type: EmissionFreightCargoTypeEnum;
      is_refrigerated: boolean;
      from_address?: string | null;
      from_code?: string | null;
      from_country_code?: string | null;
      from_country_name?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_code?: string | null;
      to_country_code?: string | null;
      to_display?: string | null;
      route_details?: {
        __typename?: 'RouteDetails';
        is_urban?: boolean | null;
        is_motorway?: boolean | null;
        is_ferry?: boolean | null;
        is_train?: boolean | null;
        country_code_alpha_2?: string | null;
        path?: string | null;
        stops?: Array<{
          __typename?: 'RouteStop';
          code?: string | null;
          stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
        }> | null;
      } | null;
      fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    client_user?: {
      __typename?: 'ClientUser';
      first_name?: string | null;
      last_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    } | null;
    details: Array<{
      __typename?: 'EmissionDetail';
      co2e: number;
      co2?: number | null;
      ch4?: number | null;
      n2o?: number | null;
      nox?: number | null;
      so2?: number | null;
      pm?: number | null;
      nmhc?: number | null;
      type?: EmissionDetailTypeEnum | null;
      subtype?: EmissionDetailSubtypeEnum | null;
      scope?: EmissionDetailScopeEnum | null;
      category?: EmissionDetailCategoryEnum | null;
    }>;
  };
};

export type GetEmissionFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type GetEmissionFiltersQuery = {
  __typename?: 'Query';
  emission_metadata_keys: Array<string>;
  emission_co2e_range: {
    __typename?: 'GetEmissionsCo2ERange';
    min?: number | null;
    max?: number | null;
    histogram: Array<{ __typename?: 'HistogramBucket'; key: number; doc_count: number }>;
  };
  emission_distance_range: {
    __typename?: 'GetEmissionsDistanceRange';
    min?: number | null;
    max?: number | null;
    histogram: Array<{ __typename?: 'HistogramBucket'; key: number; doc_count: number }>;
  };
  emission_weight_range: {
    __typename?: 'GetEmissionsWeightRange';
    min?: number | null;
    max?: number | null;
    histogram: Array<{ __typename?: 'HistogramBucket'; key: number; doc_count: number }>;
  };
};

export type GetEmissionMetadataKeysQueryVariables = Exact<{ [key: string]: never }>;

export type GetEmissionMetadataKeysQuery = { __typename?: 'Query'; emission_metadata_keys: Array<string> };

export type GetEmissionMetadataValuesQueryVariables = Exact<{
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
  importId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEmissionMetadataValuesQuery = {
  __typename?: 'Query';
  emission_metadata_values: Array<{ __typename?: 'FilterValues'; value: string; display: string; count: number }>;
};

export type GetEmissionMetadataValuesWithoutCountQueryVariables = Exact<{
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
}>;

export type GetEmissionMetadataValuesWithoutCountQuery = {
  __typename?: 'Query';
  emission_metadata_values: Array<{ __typename?: 'FilterValues'; value: string; display: string }>;
};

export type GetEmissionPropertyValuesQueryVariables = Exact<{
  key: EmissionFilterPropertiesEnum;
  value: Scalars['String']['input'];
  groupKey?: InputMaybe<EmissionFilterPropertiesEnum>;
}>;

export type GetEmissionPropertyValuesQuery = {
  __typename?: 'Query';
  emission_property_values: Array<{
    __typename?: 'FilterValues';
    value: string;
    display: string;
    count: number;
    group_value?: string | null;
  }>;
};

export type Freight_FromGetEmissionsFragment = {
  __typename?: 'EmissionFreightDocument';
  id: string;
  from_address?: string | null;
  from_display?: string | null;
  from_code?: string | null;
  to_address?: string | null;
  to_display?: string | null;
  to_code?: string | null;
  from_coordinates?: { __typename?: 'LatLong'; lat?: number | null; lon?: number | null } | null;
  to_coordinates?: { __typename?: 'LatLong'; lat?: number | null; lon?: number | null } | null;
};

export type EmissionChild_FromGetEmissionsFragment = {
  __typename?: 'EmissionChildDocument';
  id: string;
  freight?: {
    __typename?: 'EmissionFreightDocument';
    id: string;
    from_address?: string | null;
    from_display?: string | null;
    from_code?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    to_code?: string | null;
    from_coordinates?: { __typename?: 'LatLong'; lat?: number | null; lon?: number | null } | null;
    to_coordinates?: { __typename?: 'LatLong'; lat?: number | null; lon?: number | null } | null;
  } | null;
};

export type Emission_FromGetEmissionsFragment = {
  __typename?: 'EmissionDocument';
  id: string;
  public_id: string;
  co2e: number;
  date: string;
  distance_km?: number | null;
  mode: EmissionModeEnum;
  weight_kg?: number | null;
  teu?: number | null;
  activity_normalized?: number | null;
  activity_unit_normalized?: EmissionFactorUnitEnum | null;
  intensity_normalized?: number | null;
  visibility?: EmissionVisibilityEnum | null;
  shipment_reference_number_metadata_key: string;
  shipment_reference_number_metadata_value?: string | null;
  well_known_metadata: {
    __typename?: 'WellKnownMetadata';
    client_key?: string | null;
    supplier_key?: string | null;
    lsp_key?: string | null;
    shipment_id?: string | null;
  };
  parent_quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
  children: Array<{
    __typename?: 'EmissionChildDocument';
    id: string;
    freight?: {
      __typename?: 'EmissionFreightDocument';
      id: string;
      from_address?: string | null;
      from_display?: string | null;
      from_code?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      to_code?: string | null;
      from_coordinates?: { __typename?: 'LatLong'; lat?: number | null; lon?: number | null } | null;
      to_coordinates?: { __typename?: 'LatLong'; lat?: number | null; lon?: number | null } | null;
    } | null;
  }>;
};

export type GetEmissionsQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  types?: InputMaybe<Array<EmissionTypeEnum> | EmissionTypeEnum>;
  modes?: InputMaybe<Array<EmissionModeEnum> | EmissionModeEnum>;
  activityTypeModePairs?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  activityTypeVehicleCodePairs?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  publicIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  sortedColumn: EmissionSortedColumnEnum;
  sortedOrder: SortedOrderEnum;
  search?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Array<MetadataInput> | MetadataInput>;
  co2e?: InputMaybe<RangeInput>;
  distanceKm?: InputMaybe<RangeInput>;
  weightKg?: InputMaybe<RangeInput>;
  dateRange?: InputMaybe<DateRangeInput>;
  importIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type GetEmissionsQuery = {
  __typename?: 'Query';
  emissions: {
    __typename?: 'GetEmissions';
    total_count: number;
    total_co2e: number;
    pagination_limit: number;
    total_count_unfiltered: number;
    items: Array<{
      __typename?: 'EmissionDocument';
      id: string;
      public_id: string;
      co2e: number;
      date: string;
      distance_km?: number | null;
      mode: EmissionModeEnum;
      weight_kg?: number | null;
      teu?: number | null;
      activity_normalized?: number | null;
      activity_unit_normalized?: EmissionFactorUnitEnum | null;
      intensity_normalized?: number | null;
      visibility?: EmissionVisibilityEnum | null;
      shipment_reference_number_metadata_key: string;
      shipment_reference_number_metadata_value?: string | null;
      well_known_metadata: {
        __typename?: 'WellKnownMetadata';
        client_key?: string | null;
        supplier_key?: string | null;
        lsp_key?: string | null;
        shipment_id?: string | null;
      };
      parent_quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
      children: Array<{
        __typename?: 'EmissionChildDocument';
        id: string;
        freight?: {
          __typename?: 'EmissionFreightDocument';
          id: string;
          from_address?: string | null;
          from_display?: string | null;
          from_code?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          to_code?: string | null;
          from_coordinates?: { __typename?: 'LatLong'; lat?: number | null; lon?: number | null } | null;
          to_coordinates?: { __typename?: 'LatLong'; lat?: number | null; lon?: number | null } | null;
        } | null;
      }>;
    }>;
  };
};

export type GetEmissionsCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetEmissionsCountQuery = {
  __typename?: 'Query';
  emissions_count: { __typename?: 'GetEmissionsCount'; total_count_unfiltered: number };
};

export type EmissionVisibility_FromUpdateEmissionVisibilityFragment = {
  __typename?: 'Emission';
  id: string;
  public_id: string;
  visibility?: EmissionVisibilityEnum | null;
};

export type UpdateEmissionVisibilityMutationVariables = Exact<{
  publicId: Scalars['String']['input'];
  visibility: EmissionVisibilityEnum;
}>;

export type UpdateEmissionVisibilityMutation = {
  __typename?: 'Mutation';
  update_emission_visibility: {
    __typename?: 'Emission';
    id: string;
    public_id: string;
    visibility?: EmissionVisibilityEnum | null;
  };
};

export type GetHomeEmissionsPerDateQueryVariables = Exact<{
  view: HomeEmissionPerDateViewEnum;
  groupByEmissionMode: Scalars['Boolean']['input'];
}>;

export type GetHomeEmissionsPerDateQuery = {
  __typename?: 'Query';
  home_emissions_per_date: {
    __typename?: 'HomeEmissionsPerDate';
    calendarInterval: CalendarInterval;
    items: Array<{
      __typename?: 'HomeEmissionPerDate';
      date: string;
      co2e?: number | null;
      breakdown?: Array<{
        __typename?: 'HomeEmissionPerDateBreakdown';
        co2e?: number | null;
        grouping?: Array<{ __typename?: 'HomeEmissionPerDateGrouping'; emissionMode?: EmissionModeEnum | null }> | null;
      }> | null;
    }>;
  };
};

export type Import_FromGetImportFragment = {
  __typename?: 'Import';
  id: string;
  public_id: string;
  processed_rows_success_count?: number | null;
  processed_rows_error_count?: number | null;
  emission_count?: number | null;
  has_success_file: boolean;
  has_error_file: boolean;
  has_success_file_xlsx: boolean;
  has_error_file_xlsx: boolean;
  co2e_total?: number | null;
  state: ImportStateEnum;
  emissions_by_client_key?: Array<{
    __typename?: 'ImportEmissionsByClientKey';
    client_key: string;
    count: number;
    co2e: number;
  }> | null;
  emissions_by_supplier_key?: Array<{
    __typename?: 'ImportEmissionsBySupplierKey';
    supplier_key: string;
    count: number;
    co2e: number;
  }> | null;
};

export type GetImportQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
  withEmissionsByClientKey: Scalars['Boolean']['input'];
  withEmissionsBySupplierKey: Scalars['Boolean']['input'];
}>;

export type GetImportQuery = {
  __typename?: 'Query';
  import: {
    __typename?: 'Import';
    id: string;
    public_id: string;
    processed_rows_success_count?: number | null;
    processed_rows_error_count?: number | null;
    emission_count?: number | null;
    has_success_file: boolean;
    has_error_file: boolean;
    has_success_file_xlsx: boolean;
    has_error_file_xlsx: boolean;
    co2e_total?: number | null;
    state: ImportStateEnum;
    emissions_by_client_key?: Array<{
      __typename?: 'ImportEmissionsByClientKey';
      client_key: string;
      count: number;
      co2e: number;
    }> | null;
    emissions_by_supplier_key?: Array<{
      __typename?: 'ImportEmissionsBySupplierKey';
      supplier_key: string;
      count: number;
      co2e: number;
    }> | null;
  };
};

export type Import_FromGetImportCsvErrorSignedUrlFragment = {
  __typename?: 'Import';
  processed_rows_error_file_url?: string | null;
};

export type GetImportCsvErrorSignedUrlQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetImportCsvErrorSignedUrlQuery = {
  __typename?: 'Query';
  import: { __typename?: 'Import'; processed_rows_error_file_url?: string | null };
};

export type GetImportCsvSuccessSignedUrlQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
  custom_schema_id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetImportCsvSuccessSignedUrlQuery = {
  __typename?: 'Query';
  import: { __typename?: 'Import'; processed_rows_success_file_url?: string | null };
};

export type Import_FromGetImportCsvXlsxErrorSignedUrlFragment = {
  __typename?: 'Import';
  processed_rows_error_file_url_xlsx?: string | null;
  processed_rows_error_file_url?: string | null;
};

export type GetImportCsvXlsxErrorSignedUrlQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetImportCsvXlsxErrorSignedUrlQuery = {
  __typename?: 'Query';
  import: {
    __typename?: 'Import';
    processed_rows_error_file_url_xlsx?: string | null;
    processed_rows_error_file_url?: string | null;
  };
};

export type GetImportCsvXlsxSuccessSignedUrlQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
  custom_schema_id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetImportCsvXlsxSuccessSignedUrlQuery = {
  __typename?: 'Query';
  import: {
    __typename?: 'Import';
    processed_rows_success_file_url_xlsx?: string | null;
    processed_rows_success_file_url?: string | null;
  };
};

export type Import_FromGetImportsFragment = {
  __typename?: 'Import';
  filename: string;
  filesize: number;
  public_id: string;
  vertical: ImportVerticalEnum;
  date?: string | null;
  state: ImportStateEnum;
  processed_rows_success_count?: number | null;
  processed_rows_error_count?: number | null;
  client_user?: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    deleted_date?: string | null;
    avatar?: { __typename?: 'S3Object'; location: string } | null;
  } | null;
};

export type GetImportsQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  sortedColumn: ImportSortedColumnEnum;
  sortedOrder: SortedOrderEnum;
}>;

export type GetImportsQuery = {
  __typename?: 'Query';
  imports: {
    __typename?: 'GetImports';
    total_count: number;
    items: Array<{
      __typename?: 'Import';
      filename: string;
      filesize: number;
      public_id: string;
      vertical: ImportVerticalEnum;
      date?: string | null;
      state: ImportStateEnum;
      processed_rows_success_count?: number | null;
      processed_rows_error_count?: number | null;
      client_user?: {
        __typename?: 'ClientUser';
        first_name?: string | null;
        last_name?: string | null;
        deleted_date?: string | null;
        avatar?: { __typename?: 'S3Object'; location: string } | null;
      } | null;
    }>;
  };
};

export type ClientIntegration_FromGetClientIntegrationFragment = {
  __typename?: 'ClientIntegration';
  id: string;
  public_id: string;
  system: ClientIntegrationSystemEnum;
  secondary_system?: ClientIntegrationSecondarySystemEnum | null;
  status: ClientIntegrationStatusEnum;
  status_update_date: string;
  status_update_requested_by: {
    __typename?: 'ClientUser';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  };
};

export type GetClientIntegrationQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetClientIntegrationQuery = {
  __typename?: 'Query';
  client_integration: {
    __typename?: 'ClientIntegration';
    id: string;
    public_id: string;
    system: ClientIntegrationSystemEnum;
    secondary_system?: ClientIntegrationSecondarySystemEnum | null;
    status: ClientIntegrationStatusEnum;
    status_update_date: string;
    status_update_requested_by: {
      __typename?: 'ClientUser';
      id: string;
      first_name?: string | null;
      last_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    };
  };
};

export type GetClientIntegrationLogsQueryVariables = Exact<{
  publicId: Scalars['String']['input'];
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetClientIntegrationLogsQuery = {
  __typename?: 'Query';
  client_integration_logs: {
    __typename?: 'ClientIntegrationLogs';
    nextToken?: string | null;
    streams: Array<{
      __typename?: 'LogStream';
      name: string;
      firstEventTimestamp: number;
      lastEventTimestamp: number;
      events: Array<{ __typename?: 'LogEvent'; timestamp: number; message: string; level: LogLevel; context: unknown }>;
    }>;
  };
};

export type GetClientIntegrationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientIntegrationsQuery = {
  __typename?: 'Query';
  client_integrations: Array<{
    __typename?: 'ClientIntegration';
    id: string;
    public_id: string;
    system: ClientIntegrationSystemEnum;
    secondary_system?: ClientIntegrationSecondarySystemEnum | null;
    status: ClientIntegrationStatusEnum;
    status_update_date: string;
    status_update_requested_by: {
      __typename?: 'ClientUser';
      id: string;
      first_name?: string | null;
      last_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    };
  }>;
};

export type GetInvoiceDownloadUrlQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetInvoiceDownloadUrlQuery = {
  __typename?: 'Query';
  invoice_download_url: { __typename?: 'GetInvoiceDownloadUrl'; id: string; url: string; valid_until: string };
};

export type GetInvoicesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetInvoicesQuery = {
  __typename?: 'Query';
  invoices: {
    __typename?: 'GetInvoices';
    next_offset?: string | null;
    items: Array<{
      __typename?: 'Invoice';
      id: string;
      issued_at: string;
      status: InvoiceStatus;
      total: { __typename?: 'MonetaryValue'; amount: number; currency: string };
    }>;
  };
};

export type OffsettingLink_FromGetDefaultOffsettingLinkFragment = {
  __typename?: 'OffsettingLink';
  public_id: string;
  is_active: boolean;
  is_default: boolean;
  url: string;
};

export type GetDefaultOffsettingLinkQueryVariables = Exact<{ [key: string]: never }>;

export type GetDefaultOffsettingLinkQuery = {
  __typename?: 'Query';
  default_offsetting_link?: {
    __typename?: 'OffsettingLink';
    public_id: string;
    is_active: boolean;
    is_default: boolean;
    url: string;
  } | null;
};

export type OffsettingLink_FromGetOffsettingLinkFragment = {
  __typename?: 'OffsettingLink';
  id: string;
  public_id: string;
  url: string;
  type: OffsettingLinkTypeEnum;
  is_active: boolean;
  currency: CurrencyEnum;
  is_default: boolean;
  show_confirmation_page: boolean;
  redirect_url?: string | null;
  created_date: string;
  updated_date: string;
  portfolios: Array<{
    __typename?: 'OffsettingLinkPortfolio';
    name: string;
    public_id: string;
    portfolio_version: string;
    allocated_weight_kg: number;
    reserved_weight_kg: number;
  }>;
  created_by: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string; location: string; bucket: string } | null;
  };
  updated_by: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string; location: string; bucket: string } | null;
  };
};

export type GetOffsettingLinkQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetOffsettingLinkQuery = {
  __typename?: 'Query';
  offsetting_link: {
    __typename?: 'OffsettingLink';
    id: string;
    public_id: string;
    url: string;
    type: OffsettingLinkTypeEnum;
    is_active: boolean;
    currency: CurrencyEnum;
    is_default: boolean;
    show_confirmation_page: boolean;
    redirect_url?: string | null;
    created_date: string;
    updated_date: string;
    portfolios: Array<{
      __typename?: 'OffsettingLinkPortfolio';
      name: string;
      public_id: string;
      portfolio_version: string;
      allocated_weight_kg: number;
      reserved_weight_kg: number;
    }>;
    created_by: {
      __typename?: 'ClientUser';
      first_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string; location: string; bucket: string } | null;
    };
    updated_by: {
      __typename?: 'ClientUser';
      first_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string; location: string; bucket: string } | null;
    };
  };
};

export type OffsettingLink_FromGetOffsettingLinksFragment = {
  __typename?: 'OffsettingLink';
  id: string;
  public_id: string;
  url: string;
  type: OffsettingLinkTypeEnum;
  is_active: boolean;
  is_default: boolean;
  created_date: string;
  updated_date: string;
  portfolios: Array<{
    __typename?: 'OffsettingLinkPortfolio';
    name: string;
    public_id: string;
    portfolio_version: string;
    allocated_weight_kg: number;
    reserved_weight_kg: number;
  }>;
};

export type GetOffsettingLinksQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
}>;

export type GetOffsettingLinksQuery = {
  __typename?: 'Query';
  offsetting_links: {
    __typename?: 'GetOffsettingLinks';
    items: Array<{
      __typename?: 'OffsettingLink';
      id: string;
      public_id: string;
      url: string;
      type: OffsettingLinkTypeEnum;
      is_active: boolean;
      is_default: boolean;
      created_date: string;
      updated_date: string;
      portfolios: Array<{
        __typename?: 'OffsettingLinkPortfolio';
        name: string;
        public_id: string;
        portfolio_version: string;
        allocated_weight_kg: number;
        reserved_weight_kg: number;
      }>;
    }>;
  };
};

export type SaveOffsettingLink_FromSaveOffsettingLinkFragment = {
  __typename?: 'OffsettingLink';
  public_id: string;
  url: string;
  type: OffsettingLinkTypeEnum;
  is_active: boolean;
  is_default: boolean;
  currency: CurrencyEnum;
  show_confirmation_page: boolean;
  redirect_url?: string | null;
  created_date: string;
  updated_date: string;
};

export type SaveOffsettingLinkMutationVariables = Exact<{
  settings: OffsettingLinkSettingsInput;
}>;

export type SaveOffsettingLinkMutation = {
  __typename?: 'Mutation';
  save_offsetting_link: {
    __typename?: 'OffsettingLink';
    public_id: string;
    url: string;
    type: OffsettingLinkTypeEnum;
    is_active: boolean;
    is_default: boolean;
    currency: CurrencyEnum;
    show_confirmation_page: boolean;
    redirect_url?: string | null;
    created_date: string;
    updated_date: string;
  };
};

export type UpdateOffsettingLinkMutationVariables = Exact<{
  settings: OffsettingLinkSettingsInput;
  public_id: Scalars['String']['input'];
}>;

export type UpdateOffsettingLinkMutation = {
  __typename?: 'Mutation';
  update_offsetting_link: {
    __typename?: 'OffsettingLink';
    public_id: string;
    url: string;
    type: OffsettingLinkTypeEnum;
    is_active: boolean;
    is_default: boolean;
    currency: CurrencyEnum;
    show_confirmation_page: boolean;
    redirect_url?: string | null;
    created_date: string;
    updated_date: string;
  };
};

export type OffsettingLink_FromUpdateDefaultOffsettingLinkFragment = {
  __typename?: 'OffsettingLink';
  id: string;
  public_id: string;
  is_active: boolean;
  is_default: boolean;
};

export type UpdateDefaultOffsettingLinkMutationVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type UpdateDefaultOffsettingLinkMutation = {
  __typename?: 'Mutation';
  update_default_offsetting_link: {
    __typename?: 'OffsettingLink';
    id: string;
    public_id: string;
    is_active: boolean;
    is_default: boolean;
  };
};

export type Order_FromGetOrderFragment = {
  __typename?: 'Order';
  public_id: string;
  weight_kg: number;
  price_per_kg: number;
  amount: number;
  currency: string;
  commission: number;
  commission_percentage: number;
  small_order_fee?: number | null;
  small_order_threshold?: number | null;
  status: OrderStatusEnum;
  issued_on_behalf_of?: string | null;
  certificate_url: string;
  source_type_name: OrderSourceTypeEnum;
  created_date: string;
  updated_date: string;
  status_timeline?: Array<{
    __typename?: 'StatusTimeline';
    status: OrderStatusEnum;
    date: string;
    source_type_id?: string | null;
    client_user_id?: string | null;
    client_user_first_name?: string | null;
    impact_link_id?: string | null;
    client_user_avatar?: { __typename?: 'S3Object'; key: string; bucket: string; location: string } | null;
  }> | null;
  portfolio: {
    __typename?: 'Portfolio';
    public_id: string;
    name: string;
    currency: string;
    price_per_kg: number;
    description: string;
    allocations: Array<{
      __typename?: 'PortfolioAllocation';
      percentage: number;
      project: {
        __typename?: 'Project';
        media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
      };
    }>;
  };
  client: {
    __typename?: 'Client';
    legal_name?: string | null;
    avatar?: { __typename?: 'S3Object'; location: string; bucket: string; key: string } | null;
  };
  client_user?: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    avatar?: { __typename?: 'S3Object'; location: string; bucket: string; key: string } | null;
  } | null;
  meta_data?: Array<{ __typename?: 'Metadata'; key: string; display: string; value: string }> | null;
};

export type GetOrderQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetOrderQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    public_id: string;
    weight_kg: number;
    price_per_kg: number;
    amount: number;
    currency: string;
    commission: number;
    commission_percentage: number;
    small_order_fee?: number | null;
    small_order_threshold?: number | null;
    status: OrderStatusEnum;
    issued_on_behalf_of?: string | null;
    certificate_url: string;
    source_type_name: OrderSourceTypeEnum;
    created_date: string;
    updated_date: string;
    status_timeline?: Array<{
      __typename?: 'StatusTimeline';
      status: OrderStatusEnum;
      date: string;
      source_type_id?: string | null;
      client_user_id?: string | null;
      client_user_first_name?: string | null;
      impact_link_id?: string | null;
      client_user_avatar?: { __typename?: 'S3Object'; key: string; bucket: string; location: string } | null;
    }> | null;
    portfolio: {
      __typename?: 'Portfolio';
      public_id: string;
      name: string;
      currency: string;
      price_per_kg: number;
      description: string;
      allocations: Array<{
        __typename?: 'PortfolioAllocation';
        percentage: number;
        project: {
          __typename?: 'Project';
          media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
        };
      }>;
    };
    client: {
      __typename?: 'Client';
      legal_name?: string | null;
      avatar?: { __typename?: 'S3Object'; location: string; bucket: string; key: string } | null;
    };
    client_user?: {
      __typename?: 'ClientUser';
      first_name?: string | null;
      avatar?: { __typename?: 'S3Object'; location: string; bucket: string; key: string } | null;
    } | null;
    meta_data?: Array<{ __typename?: 'Metadata'; key: string; display: string; value: string }> | null;
  };
};

export type GetOrderFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrderFiltersQuery = {
  __typename?: 'Query';
  order_metadata_keys: Array<string>;
  order_co2e_range: {
    __typename?: 'GetOrdersCo2ERange';
    min?: number | null;
    max?: number | null;
    histogram: Array<{ __typename?: 'HistogramBucket'; key: number; doc_count: number }>;
  };
};

export type GetOrderMetadataValuesQueryVariables = Exact<{
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
}>;

export type GetOrderMetadataValuesQuery = {
  __typename?: 'Query';
  order_metadata_values: Array<{ __typename?: 'FilterValues'; value: string; display: string; count: number }>;
};

export type GetOrderPropertyValuesQueryVariables = Exact<{
  key: OrderFilterPropertiesEnum;
  value: Scalars['String']['input'];
}>;

export type GetOrderPropertyValuesQuery = {
  __typename?: 'Query';
  order_property_values: Array<{ __typename?: 'FilterValues'; value: string; display: string; count: number }>;
};

export type Order_FromGetOrdersFragment = {
  __typename?: 'Order';
  public_id: string;
  weight_kg: number;
  amount: number;
  currency: string;
  status: OrderStatusEnum;
  portfolio_name: string;
  portfolio_id: string;
  created_date: string;
};

export type GetOrdersQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  co2e: RangeInput;
  dateRange?: InputMaybe<DateRangeInput>;
  status?: InputMaybe<Array<OrderStatusEnum> | OrderStatusEnum>;
  publicIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  invoiceNumbers?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  metadata?: InputMaybe<Array<MetadataInput> | MetadataInput>;
  sortedColumn: OrderSortedColumnEnum;
  sortedOrder: SortedOrderEnum;
}>;

export type GetOrdersQuery = {
  __typename?: 'Query';
  orders: {
    __typename?: 'GetOrders';
    total_count: number;
    total_co2e: number;
    pagination_limit: number;
    items: Array<{
      __typename?: 'Order';
      public_id: string;
      weight_kg: number;
      amount: number;
      currency: string;
      status: OrderStatusEnum;
      portfolio_name: string;
      portfolio_id: string;
      created_date: string;
    }>;
  };
};

export type PlanItemPrice_FromGetPlansFragment = {
  __typename?: 'PlanItemPrice';
  id: string;
  list_price?: string | null;
  sale_price?: string | null;
  currency: CurrencyEnum;
  period: PlanItemPricePeriodEnum;
  discount_percentage?: string | null;
};

export type Plan_FromGetPlansFragment = {
  __typename?: 'Plan';
  id: string;
  is_enhanced: boolean;
  item_prices: Array<{
    __typename?: 'PlanItemPrice';
    id: string;
    list_price?: string | null;
    sale_price?: string | null;
    currency: CurrencyEnum;
    period: PlanItemPricePeriodEnum;
    discount_percentage?: string | null;
  }>;
};

export type GetPlansQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlansQuery = {
  __typename?: 'Query';
  plans: Array<{
    __typename?: 'Plan';
    id: string;
    is_enhanced: boolean;
    item_prices: Array<{
      __typename?: 'PlanItemPrice';
      id: string;
      list_price?: string | null;
      sale_price?: string | null;
      currency: CurrencyEnum;
      period: PlanItemPricePeriodEnum;
      discount_percentage?: string | null;
    }>;
  }>;
};

export type SelectPlanItemHostedPageQueryVariables = Exact<{
  planItemPriceId: Scalars['String']['input'];
}>;

export type SelectPlanItemHostedPageQuery = {
  __typename?: 'Query';
  select_plan_item_hosted_page: {
    __typename?: 'HostedPage';
    id?: string | null;
    type?: string | null;
    url?: string | null;
    state?: string | null;
    pass_thru_content?: string | null;
    created_at?: number | null;
    expires_at?: number | null;
    updated_at?: number | null;
    resource_version?: number | null;
    business_entity_id?: string | null;
  };
};

export type SelectPlanItemHostedPageAcknowledgeQueryVariables = Exact<{
  hostedPageId: Scalars['String']['input'];
}>;

export type SelectPlanItemHostedPageAcknowledgeQuery = {
  __typename?: 'Query';
  select_plan_item_hosted_page_acknowledge: boolean;
};

export type Portfolio_FromBuyAgainPortfoliosFragment = { __typename?: 'Portfolio'; public_id: string };

export type GetBuyAgainPortfoliosQueryVariables = Exact<{ [key: string]: never }>;

export type GetBuyAgainPortfoliosQuery = {
  __typename?: 'Query';
  buy_again_portfolios: {
    __typename?: 'GetPortfolios';
    total_count: number;
    items: Array<{ __typename?: 'Portfolio'; public_id: string }>;
  };
};

export type Portfolio_FromGetPortfolioFragment = {
  __typename?: 'Portfolio';
  public_id: string;
  is_published: boolean;
  name: string;
  description: string;
  price_per_kg: number;
  currency: string;
  allocated_weight_kg: number;
  reserved_weight_kg: number;
  created_date: string;
  updated_date: string;
  allocations: Array<{
    __typename?: 'PortfolioAllocation';
    percentage: number;
    project: {
      __typename?: 'Project';
      public_id: string;
      name: string;
      description: string;
      avoidance: boolean;
      removal: boolean;
      coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      project_type: { __typename?: 'ProjectType'; name: string; category: string };
      country: { __typename?: 'Country'; name: string; code: string };
      media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
    };
  }>;
  distribution: { __typename?: 'PortfolioDistribution'; totalAllocated: number; avoidance: number; removal: number };
};

export type KeyFact_FromGetPortfolioFragment = {
  __typename?: 'PortfolioKeyFact';
  id: string;
  key_facts: Array<string>;
};

export type GetPortfolioQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPortfolioQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'GetPortfolio';
    portfolio: {
      __typename?: 'Portfolio';
      public_id: string;
      is_published: boolean;
      name: string;
      description: string;
      price_per_kg: number;
      currency: string;
      allocated_weight_kg: number;
      reserved_weight_kg: number;
      created_date: string;
      updated_date: string;
      allocations: Array<{
        __typename?: 'PortfolioAllocation';
        percentage: number;
        project: {
          __typename?: 'Project';
          public_id: string;
          name: string;
          description: string;
          avoidance: boolean;
          removal: boolean;
          coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          project_type: { __typename?: 'ProjectType'; name: string; category: string };
          country: { __typename?: 'Country'; name: string; code: string };
          media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
        };
      }>;
      distribution: {
        __typename?: 'PortfolioDistribution';
        totalAllocated: number;
        avoidance: number;
        removal: number;
      };
    };
    keyFacts?: { __typename?: 'PortfolioKeyFact'; id: string; key_facts: Array<string> } | null;
  };
};

export type Portfolio_FromGetPortfoliosFragment = {
  __typename?: 'Portfolio';
  public_id: string;
  is_published: boolean;
  name: string;
  description: string;
  price_per_kg: number;
  currency: string;
  allocated_weight_kg: number;
  reserved_weight_kg: number;
  created_date: string;
  updated_date: string;
  allocations: Array<{
    __typename?: 'PortfolioAllocation';
    percentage: number;
    project: {
      __typename?: 'Project';
      public_id: string;
      name: string;
      description: string;
      avoidance: boolean;
      removal: boolean;
      project_type: { __typename?: 'ProjectType'; name: string; category: string };
      country: { __typename?: 'Country'; name: string; code: string };
      media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
    };
  }>;
  distribution: { __typename?: 'PortfolioDistribution'; totalAllocated: number; avoidance: number; removal: number };
};

export type GetPortfoliosQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  has_offsets_available?: InputMaybe<Scalars['Boolean']['input']>;
  publicIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type GetPortfoliosQuery = {
  __typename?: 'Query';
  portfolios: {
    __typename?: 'GetPortfolios';
    total_count: number;
    items: Array<{
      __typename?: 'Portfolio';
      public_id: string;
      is_published: boolean;
      name: string;
      description: string;
      price_per_kg: number;
      currency: string;
      allocated_weight_kg: number;
      reserved_weight_kg: number;
      created_date: string;
      updated_date: string;
      allocations: Array<{
        __typename?: 'PortfolioAllocation';
        percentage: number;
        project: {
          __typename?: 'Project';
          public_id: string;
          name: string;
          description: string;
          avoidance: boolean;
          removal: boolean;
          project_type: { __typename?: 'ProjectType'; name: string; category: string };
          country: { __typename?: 'Country'; name: string; code: string };
          media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
        };
      }>;
      distribution: {
        __typename?: 'PortfolioDistribution';
        totalAllocated: number;
        avoidance: number;
        removal: number;
      };
    }>;
  };
};

export type Project_FromGetProjectFragment = {
  __typename?: 'Project';
  public_id: string;
  name: string;
  description: string;
  website?: string | null;
  location?: string | null;
  sdgs?: Array<SustainableDevelopmentGoalsEnum> | null;
  avoidance: boolean;
  removal: boolean;
  created_date: string;
  updated_date: string;
  country: { __typename?: 'Country'; name: string; code: string };
  coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  project_type: { __typename?: 'ProjectType'; name: string; category: string };
  developer?: {
    __typename?: 'ProjectDeveloper';
    name: string;
    description?: string | null;
    website?: string | null;
    media?: Array<{ __typename?: 'S3Object'; key: string; bucket: string }> | null;
  } | null;
  registry?: {
    __typename?: 'Registry';
    name: string;
    name_short?: string | null;
    website?: string | null;
    media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
  } | null;
  carbonplan_assessment?: { __typename?: 'CarbonplanAssessment'; data_stringify?: string | null } | null;
  certifications?: Array<{
    __typename?: 'ProjectCertification';
    name: string;
    media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
  }> | null;
  media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
};

export type GetProjectQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetProjectQuery = {
  __typename?: 'Query';
  project: {
    __typename?: 'Project';
    public_id: string;
    name: string;
    description: string;
    website?: string | null;
    location?: string | null;
    sdgs?: Array<SustainableDevelopmentGoalsEnum> | null;
    avoidance: boolean;
    removal: boolean;
    created_date: string;
    updated_date: string;
    country: { __typename?: 'Country'; name: string; code: string };
    coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    project_type: { __typename?: 'ProjectType'; name: string; category: string };
    developer?: {
      __typename?: 'ProjectDeveloper';
      name: string;
      description?: string | null;
      website?: string | null;
      media?: Array<{ __typename?: 'S3Object'; key: string; bucket: string }> | null;
    } | null;
    registry?: {
      __typename?: 'Registry';
      name: string;
      name_short?: string | null;
      website?: string | null;
      media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
    } | null;
    carbonplan_assessment?: { __typename?: 'CarbonplanAssessment'; data_stringify?: string | null } | null;
    certifications?: Array<{
      __typename?: 'ProjectCertification';
      name: string;
      media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
    }> | null;
    media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
  };
};

export type Project_FromGetProjectsFragment = {
  __typename?: 'Project';
  public_id: string;
  name: string;
  description: string;
  website?: string | null;
  location?: string | null;
  sdgs?: Array<SustainableDevelopmentGoalsEnum> | null;
  avoidance: boolean;
  removal: boolean;
  created_date: string;
  updated_date: string;
  country: { __typename?: 'Country'; name: string; code: string };
  coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  project_type: { __typename?: 'ProjectType'; name: string; category: string };
  developer?: {
    __typename?: 'ProjectDeveloper';
    name: string;
    description?: string | null;
    website?: string | null;
    media?: Array<{ __typename?: 'S3Object'; location: string; key: string; bucket: string }> | null;
  } | null;
  registry?: { __typename?: 'Registry'; name: string; name_short?: string | null; website?: string | null } | null;
  carbonplan_assessment?: { __typename?: 'CarbonplanAssessment'; data_stringify?: string | null } | null;
  media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
};

export type GetProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectsQuery = {
  __typename?: 'Query';
  projects: {
    __typename?: 'GetProjects';
    total_count: number;
    items: Array<{
      __typename?: 'Project';
      public_id: string;
      name: string;
      description: string;
      website?: string | null;
      location?: string | null;
      sdgs?: Array<SustainableDevelopmentGoalsEnum> | null;
      avoidance: boolean;
      removal: boolean;
      created_date: string;
      updated_date: string;
      country: { __typename?: 'Country'; name: string; code: string };
      coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      project_type: { __typename?: 'ProjectType'; name: string; category: string };
      developer?: {
        __typename?: 'ProjectDeveloper';
        name: string;
        description?: string | null;
        website?: string | null;
        media?: Array<{ __typename?: 'S3Object'; location: string; key: string; bucket: string }> | null;
      } | null;
      registry?: { __typename?: 'Registry'; name: string; name_short?: string | null; website?: string | null } | null;
      carbonplan_assessment?: { __typename?: 'CarbonplanAssessment'; data_stringify?: string | null } | null;
      media?: Array<{ __typename?: 'S3Object'; bucket: string; key: string }> | null;
    }>;
  };
};

export type DeleteReportMutationVariables = Exact<{
  publicId: Scalars['String']['input'];
}>;

export type DeleteReportMutation = {
  __typename?: 'Mutation';
  delete_report: { __typename?: 'Report'; id: string; public_id: string };
};

export type GetReportQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetReportQuery = {
  __typename?: 'Query';
  report: { __typename?: 'Report'; id: string; type: ReportTypeEnum; name: string; visibility: ReportVisibilityEnum };
};

export type Report_FromGetReportsFragment = {
  __typename?: 'Report';
  id: string;
  name: string;
  type: ReportTypeEnum;
  public_id: string;
  prepared_for?: string | null;
  created_date: string;
  visibility: ReportVisibilityEnum;
  share_token: string;
  client_user?: {
    __typename?: 'ClientUser';
    first_name?: string | null;
    last_name?: string | null;
    deleted_date?: string | null;
    avatar?: { __typename?: 'S3Object'; key: string } | null;
  } | null;
};

export type GetReportsQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  sortedOrder: SortedOrderEnum;
}>;

export type GetReportsQuery = {
  __typename?: 'Query';
  reports: {
    __typename?: 'Reports';
    total_count: number;
    items: Array<{
      __typename?: 'Report';
      id: string;
      name: string;
      type: ReportTypeEnum;
      public_id: string;
      prepared_for?: string | null;
      created_date: string;
      visibility: ReportVisibilityEnum;
      share_token: string;
      client_user?: {
        __typename?: 'ClientUser';
        first_name?: string | null;
        last_name?: string | null;
        deleted_date?: string | null;
        avatar?: { __typename?: 'S3Object'; key: string } | null;
      } | null;
    }>;
  };
};

export type GhgTypeBreakdownItemFragment = {
  __typename?: 'TypeBreakdownItem';
  label: string;
  co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
  biogenic: { __typename?: 'BiogenicBreakdown'; co2: { __typename?: 'EmissionNumber'; value: number; unit: string } };
  methodology: {
    __typename?: 'GhgMethodology';
    sources: Array<{
      __typename?: 'GhgSource';
      name: string;
      year?: number | null;
      version?: string | null;
      revision?: string | null;
    }>;
    geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
    routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
    methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
  };
};

export type GhgBreakdownItemFragment = {
  __typename?: 'GhgBreakdownItem';
  label: string;
  co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
  ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
};

export type GhgResultsFieldsFragment = {
  __typename?: 'GhgReportResults';
  total_co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
  scope_breakdown: Array<{
    __typename?: 'ScopeBreakdown';
    label: string;
    co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
  }>;
  type_breakdown: {
    __typename?: 'TypeBreakdown';
    scope_1?: Array<{
      __typename?: 'TypeBreakdownItem';
      label: string;
      co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      biogenic: {
        __typename?: 'BiogenicBreakdown';
        co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
      };
      methodology: {
        __typename?: 'GhgMethodology';
        sources: Array<{
          __typename?: 'GhgSource';
          name: string;
          year?: number | null;
          version?: string | null;
          revision?: string | null;
        }>;
        geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
      };
    }> | null;
    scope_2?: Array<{
      __typename?: 'TypeBreakdownItem';
      label: string;
      co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      biogenic: {
        __typename?: 'BiogenicBreakdown';
        co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
      };
      methodology: {
        __typename?: 'GhgMethodology';
        sources: Array<{
          __typename?: 'GhgSource';
          name: string;
          year?: number | null;
          version?: string | null;
          revision?: string | null;
        }>;
        geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
      };
    }> | null;
    scope_3?: Array<{
      __typename?: 'TypeBreakdownItem';
      label: string;
      co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      biogenic: {
        __typename?: 'BiogenicBreakdown';
        co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
      };
      methodology: {
        __typename?: 'GhgMethodology';
        sources: Array<{
          __typename?: 'GhgSource';
          name: string;
          year?: number | null;
          version?: string | null;
          revision?: string | null;
        }>;
        geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
      };
    }> | null;
  };
  ghg_breakdown: {
    __typename?: 'GhgBreakdown';
    scope_1?: Array<{
      __typename?: 'GhgBreakdownItem';
      label: string;
      co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
    }> | null;
    scope_2?: Array<{
      __typename?: 'GhgBreakdownItem';
      label: string;
      co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
    }> | null;
    scope_3?: Array<{
      __typename?: 'GhgBreakdownItem';
      label: string;
      co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
    }> | null;
  };
};

export type GenerateGhgReportQueryVariables = Exact<{
  baseFilters: GhgReportFiltersInput;
}>;

export type GenerateGhgReportQuery = {
  __typename?: 'Query';
  generate_ghg_report: {
    __typename?: 'GhgReportResults';
    emissions_range_start_date?: string | null;
    emissions_range_end_date?: string | null;
    total_co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
    scope_breakdown: Array<{
      __typename?: 'ScopeBreakdown';
      label: string;
      co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
    }>;
    type_breakdown: {
      __typename?: 'TypeBreakdown';
      scope_1?: Array<{
        __typename?: 'TypeBreakdownItem';
        label: string;
        co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
        biogenic: {
          __typename?: 'BiogenicBreakdown';
          co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
        };
        methodology: {
          __typename?: 'GhgMethodology';
          sources: Array<{
            __typename?: 'GhgSource';
            name: string;
            year?: number | null;
            version?: string | null;
            revision?: string | null;
          }>;
          geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        };
      }> | null;
      scope_2?: Array<{
        __typename?: 'TypeBreakdownItem';
        label: string;
        co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
        biogenic: {
          __typename?: 'BiogenicBreakdown';
          co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
        };
        methodology: {
          __typename?: 'GhgMethodology';
          sources: Array<{
            __typename?: 'GhgSource';
            name: string;
            year?: number | null;
            version?: string | null;
            revision?: string | null;
          }>;
          geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        };
      }> | null;
      scope_3?: Array<{
        __typename?: 'TypeBreakdownItem';
        label: string;
        co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
        biogenic: {
          __typename?: 'BiogenicBreakdown';
          co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
        };
        methodology: {
          __typename?: 'GhgMethodology';
          sources: Array<{
            __typename?: 'GhgSource';
            name: string;
            year?: number | null;
            version?: string | null;
            revision?: string | null;
          }>;
          geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
        };
      }> | null;
    };
    ghg_breakdown: {
      __typename?: 'GhgBreakdown';
      scope_1?: Array<{
        __typename?: 'GhgBreakdownItem';
        label: string;
        co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
        ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
      }> | null;
      scope_2?: Array<{
        __typename?: 'GhgBreakdownItem';
        label: string;
        co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
        ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
      }> | null;
      scope_3?: Array<{
        __typename?: 'GhgBreakdownItem';
        label: string;
        co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
        ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
      }> | null;
    };
  };
};

export type GetGhgReportQueryVariables = Exact<{
  publicId: Scalars['String']['input'];
}>;

export type GetGhgReportQuery = {
  __typename?: 'Query';
  ghg_report: {
    __typename?: 'GhgReport';
    id: string;
    public_id: string;
    name: string;
    prepared_for?: string | null;
    type: ReportTypeEnum;
    visibility: ReportVisibilityEnum;
    version_number: number;
    share_token: string;
    created_date: string;
    filters: {
      __typename?: 'GhgReportFilters';
      start_date?: string | null;
      end_date?: string | null;
      import_id?: string | null;
      client_identifier?: string | null;
    };
    results: {
      __typename?: 'GhgReportResults';
      total_co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      scope_breakdown: Array<{
        __typename?: 'ScopeBreakdown';
        label: string;
        co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      }>;
      type_breakdown: {
        __typename?: 'TypeBreakdown';
        scope_1?: Array<{
          __typename?: 'TypeBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          biogenic: {
            __typename?: 'BiogenicBreakdown';
            co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
          };
          methodology: {
            __typename?: 'GhgMethodology';
            sources: Array<{
              __typename?: 'GhgSource';
              name: string;
              year?: number | null;
              version?: string | null;
              revision?: string | null;
            }>;
            geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          };
        }> | null;
        scope_2?: Array<{
          __typename?: 'TypeBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          biogenic: {
            __typename?: 'BiogenicBreakdown';
            co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
          };
          methodology: {
            __typename?: 'GhgMethodology';
            sources: Array<{
              __typename?: 'GhgSource';
              name: string;
              year?: number | null;
              version?: string | null;
              revision?: string | null;
            }>;
            geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          };
        }> | null;
        scope_3?: Array<{
          __typename?: 'TypeBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          biogenic: {
            __typename?: 'BiogenicBreakdown';
            co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
          };
          methodology: {
            __typename?: 'GhgMethodology';
            sources: Array<{
              __typename?: 'GhgSource';
              name: string;
              year?: number | null;
              version?: string | null;
              revision?: string | null;
            }>;
            geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          };
        }> | null;
      };
      ghg_breakdown: {
        __typename?: 'GhgBreakdown';
        scope_1?: Array<{
          __typename?: 'GhgBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
        }> | null;
        scope_2?: Array<{
          __typename?: 'GhgBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
        }> | null;
        scope_3?: Array<{
          __typename?: 'GhgBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
        }> | null;
      };
    };
    client: {
      __typename?: 'Client';
      legal_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    };
    client_user?: {
      __typename?: 'ClientUser';
      first_name?: string | null;
      last_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    } | null;
  };
};

export type SaveGhgReportMutationVariables = Exact<{
  baseFilters: GhgReportFiltersInput;
  input: SaveReportInput;
  generatedDate: Scalars['DateTimeISO']['input'];
}>;

export type SaveGhgReportMutation = {
  __typename?: 'Mutation';
  save_ghg_report: {
    __typename?: 'GhgReport';
    public_id: string;
    results: {
      __typename?: 'GhgReportResults';
      total_co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      scope_breakdown: Array<{
        __typename?: 'ScopeBreakdown';
        label: string;
        co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
      }>;
      type_breakdown: {
        __typename?: 'TypeBreakdown';
        scope_1?: Array<{
          __typename?: 'TypeBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          biogenic: {
            __typename?: 'BiogenicBreakdown';
            co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
          };
          methodology: {
            __typename?: 'GhgMethodology';
            sources: Array<{
              __typename?: 'GhgSource';
              name: string;
              year?: number | null;
              version?: string | null;
              revision?: string | null;
            }>;
            geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          };
        }> | null;
        scope_2?: Array<{
          __typename?: 'TypeBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          biogenic: {
            __typename?: 'BiogenicBreakdown';
            co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
          };
          methodology: {
            __typename?: 'GhgMethodology';
            sources: Array<{
              __typename?: 'GhgSource';
              name: string;
              year?: number | null;
              version?: string | null;
              revision?: string | null;
            }>;
            geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          };
        }> | null;
        scope_3?: Array<{
          __typename?: 'TypeBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          biogenic: {
            __typename?: 'BiogenicBreakdown';
            co2: { __typename?: 'EmissionNumber'; value: number; unit: string };
          };
          methodology: {
            __typename?: 'GhgMethodology';
            sources: Array<{
              __typename?: 'GhgSource';
              name: string;
              year?: number | null;
              version?: string | null;
              revision?: string | null;
            }>;
            geocoding_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            routing_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
            methodology_breakdown: Array<{ __typename?: 'GhgPercentageBreakdown'; type: string; percentage: number }>;
          };
        }> | null;
      };
      ghg_breakdown: {
        __typename?: 'GhgBreakdown';
        scope_1?: Array<{
          __typename?: 'GhgBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
        }> | null;
        scope_2?: Array<{
          __typename?: 'GhgBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
        }> | null;
        scope_3?: Array<{
          __typename?: 'GhgBreakdownItem';
          label: string;
          co2e: { __typename?: 'EmissionNumber'; value: number; unit: string };
          ghg: { __typename?: 'EmissionNumber'; value: number; unit: string };
        }> | null;
      };
    };
  };
};

export type GlecBreakdownFieldsFragment = {
  __typename?: 'GlecReportBreakdown';
  label: string;
  percentage: number;
  co2e: number;
  activity?: number | null;
  activity_unit?: string | null;
  intensity?: number | null;
  intensity_unit?: string | null;
};

export type GlecReportByModeFieldsFragment = {
  __typename?: 'GlecReportByMode';
  mode: EmissionModeEnum;
  main_data_type?: string | null;
  items: Array<{
    __typename?: 'GlecReportByModeItem';
    co2e: number;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
};

export type GlecResultsFieldsFragment = {
  __typename?: 'GlecReportResults';
  total_co2e: number;
  total_activity?: number | null;
  total_activity_unit?: string | null;
  total_intensity?: number | null;
  total_intensity_unit?: string | null;
  breakdown_by_type: Array<{
    __typename?: 'GlecReportBreakdown';
    label: string;
    percentage: number;
    co2e: number;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
  breakdown_by_scope: Array<{
    __typename?: 'GlecReportBreakdown';
    label: string;
    percentage: number;
    co2e: number;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
  breakdown_by_mode: Array<{
    __typename?: 'GlecReportBreakdown';
    label: string;
    percentage: number;
    co2e: number;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
  breakdown_by_carriage: Array<{
    __typename?: 'GlecReportBreakdown';
    label: string;
    percentage: number;
    co2e: number;
    activity?: number | null;
    activity_unit?: string | null;
    intensity?: number | null;
    intensity_unit?: string | null;
  }>;
  breakdown_by_data_type: Array<{ __typename?: 'GlecReportBreakdown'; label: string; percentage: number }>;
  report_by_mode: Array<{
    __typename?: 'GlecReportByMode';
    mode: EmissionModeEnum;
    main_data_type?: string | null;
    items: Array<{
      __typename?: 'GlecReportByModeItem';
      co2e: number;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
  }>;
};

export type GenerateGlecReportQueryVariables = Exact<{
  baseFilters: GlecReportFiltersInput;
}>;

export type GenerateGlecReportQuery = {
  __typename?: 'Query';
  generate_glec_report: {
    __typename?: 'GlecReportResults';
    emissions_range_start_date?: string | null;
    emissions_range_end_date?: string | null;
    total_co2e: number;
    total_activity?: number | null;
    total_activity_unit?: string | null;
    total_intensity?: number | null;
    total_intensity_unit?: string | null;
    breakdown_by_type: Array<{
      __typename?: 'GlecReportBreakdown';
      label: string;
      percentage: number;
      co2e: number;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
    breakdown_by_scope: Array<{
      __typename?: 'GlecReportBreakdown';
      label: string;
      percentage: number;
      co2e: number;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
    breakdown_by_mode: Array<{
      __typename?: 'GlecReportBreakdown';
      label: string;
      percentage: number;
      co2e: number;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
    breakdown_by_carriage: Array<{
      __typename?: 'GlecReportBreakdown';
      label: string;
      percentage: number;
      co2e: number;
      activity?: number | null;
      activity_unit?: string | null;
      intensity?: number | null;
      intensity_unit?: string | null;
    }>;
    breakdown_by_data_type: Array<{ __typename?: 'GlecReportBreakdown'; label: string; percentage: number }>;
    report_by_mode: Array<{
      __typename?: 'GlecReportByMode';
      mode: EmissionModeEnum;
      main_data_type?: string | null;
      items: Array<{
        __typename?: 'GlecReportByModeItem';
        co2e: number;
        activity?: number | null;
        activity_unit?: string | null;
        intensity?: number | null;
        intensity_unit?: string | null;
      }>;
    }>;
  };
};

export type GetGlecReportQueryVariables = Exact<{
  publicId: Scalars['String']['input'];
}>;

export type GetGlecReportQuery = {
  __typename?: 'Query';
  glec_report: {
    __typename?: 'GlecReport';
    id: string;
    public_id: string;
    name: string;
    prepared_for?: string | null;
    type: ReportTypeEnum;
    visibility: ReportVisibilityEnum;
    version_number: number;
    share_token: string;
    created_date: string;
    filters: {
      __typename?: 'GlecReportFilters';
      start_date?: string | null;
      end_date?: string | null;
      import_id?: string | null;
      client_identifier?: string | null;
      supply_chain_coverage: string;
      confirmed: boolean;
      confirmed_text?: string | null;
    };
    results: {
      __typename?: 'GlecReportResults';
      total_co2e: number;
      total_activity?: number | null;
      total_activity_unit?: string | null;
      total_intensity?: number | null;
      total_intensity_unit?: string | null;
      breakdown_by_type: Array<{
        __typename?: 'GlecReportBreakdown';
        label: string;
        percentage: number;
        co2e: number;
        activity?: number | null;
        activity_unit?: string | null;
        intensity?: number | null;
        intensity_unit?: string | null;
      }>;
      breakdown_by_scope: Array<{
        __typename?: 'GlecReportBreakdown';
        label: string;
        percentage: number;
        co2e: number;
        activity?: number | null;
        activity_unit?: string | null;
        intensity?: number | null;
        intensity_unit?: string | null;
      }>;
      breakdown_by_mode: Array<{
        __typename?: 'GlecReportBreakdown';
        label: string;
        percentage: number;
        co2e: number;
        activity?: number | null;
        activity_unit?: string | null;
        intensity?: number | null;
        intensity_unit?: string | null;
      }>;
      breakdown_by_carriage: Array<{
        __typename?: 'GlecReportBreakdown';
        label: string;
        percentage: number;
        co2e: number;
        activity?: number | null;
        activity_unit?: string | null;
        intensity?: number | null;
        intensity_unit?: string | null;
      }>;
      breakdown_by_data_type: Array<{ __typename?: 'GlecReportBreakdown'; label: string; percentage: number }>;
      report_by_mode: Array<{
        __typename?: 'GlecReportByMode';
        mode: EmissionModeEnum;
        main_data_type?: string | null;
        items: Array<{
          __typename?: 'GlecReportByModeItem';
          co2e: number;
          activity?: number | null;
          activity_unit?: string | null;
          intensity?: number | null;
          intensity_unit?: string | null;
        }>;
      }>;
    };
    client: {
      __typename?: 'Client';
      legal_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    };
    client_user?: {
      __typename?: 'ClientUser';
      first_name?: string | null;
      last_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    } | null;
  };
};

export type SaveGlecReportMutationVariables = Exact<{
  baseFilters: GlecReportFiltersInput;
  input: SaveReportInput;
  generatedDate: Scalars['DateTimeISO']['input'];
}>;

export type SaveGlecReportMutation = {
  __typename?: 'Mutation';
  save_glec_report: {
    __typename?: 'GlecReport';
    public_id: string;
    results: {
      __typename?: 'GlecReportResults';
      total_co2e: number;
      total_activity?: number | null;
      total_activity_unit?: string | null;
      total_intensity?: number | null;
      total_intensity_unit?: string | null;
      breakdown_by_type: Array<{
        __typename?: 'GlecReportBreakdown';
        label: string;
        percentage: number;
        co2e: number;
        activity?: number | null;
        activity_unit?: string | null;
        intensity?: number | null;
        intensity_unit?: string | null;
      }>;
      breakdown_by_scope: Array<{
        __typename?: 'GlecReportBreakdown';
        label: string;
        percentage: number;
        co2e: number;
        activity?: number | null;
        activity_unit?: string | null;
        intensity?: number | null;
        intensity_unit?: string | null;
      }>;
      breakdown_by_mode: Array<{
        __typename?: 'GlecReportBreakdown';
        label: string;
        percentage: number;
        co2e: number;
        activity?: number | null;
        activity_unit?: string | null;
        intensity?: number | null;
        intensity_unit?: string | null;
      }>;
      breakdown_by_carriage: Array<{
        __typename?: 'GlecReportBreakdown';
        label: string;
        percentage: number;
        co2e: number;
        activity?: number | null;
        activity_unit?: string | null;
        intensity?: number | null;
        intensity_unit?: string | null;
      }>;
      breakdown_by_data_type: Array<{ __typename?: 'GlecReportBreakdown'; label: string; percentage: number }>;
      report_by_mode: Array<{
        __typename?: 'GlecReportByMode';
        mode: EmissionModeEnum;
        main_data_type?: string | null;
        items: Array<{
          __typename?: 'GlecReportByModeItem';
          co2e: number;
          activity?: number | null;
          activity_unit?: string | null;
          intensity?: number | null;
          intensity_unit?: string | null;
        }>;
      }>;
    };
  };
};

export type ItemisedEmissionsResultsFieldsFragment = {
  __typename?: 'ItemisedEmissionsReportResults';
  total_shipment: number;
  total_shipment_leg: number;
  total_co2e: number;
  total_activity: number;
  total_activity_unit: string;
  total_intensity: number;
  total_intensity_unit: string;
  overall_quality: number;
  breakdown_by_type: Array<{
    __typename?: 'ItemisedEmissionsReportBreakdownByType';
    label: EmissionDetailTypeEnum;
    percentage?: number | null;
    co2e: number;
  }>;
  breakdown_by_shipment: Array<{
    __typename?: 'ItemisedEmissionsReportBreakdownByShipment';
    id: string;
    shipment_identifier?: string | null;
    mode: EmissionModeEnum;
    date: string;
    from_address?: string | null;
    from_display?: string | null;
    from_code?: string | null;
    to_address?: string | null;
    to_display?: string | null;
    to_code?: string | null;
    distance_km?: number | null;
    weight_kg: number;
    teu?: number | null;
    co2e: number;
    activity: number;
    activity_unit: string;
    intensity: number;
    intensity_unit: string;
    from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
    breakdown_by_type: Array<{
      __typename?: 'ItemisedEmissionsReportBreakdownByType';
      label: EmissionDetailTypeEnum;
      co2e: number;
    }>;
  }>;
};

export type GenerateItemisedEmissionsReportQueryVariables = Exact<{
  baseFilters: ItemisedEmissionsReportFiltersInput;
}>;

export type GenerateItemisedEmissionsReportQuery = {
  __typename?: 'Query';
  generate_itemised_emissions_report: {
    __typename?: 'ItemisedEmissionsReportResults';
    emissions_range_start_date?: string | null;
    emissions_range_end_date?: string | null;
    total_shipment: number;
    total_shipment_leg: number;
    total_co2e: number;
    total_activity: number;
    total_activity_unit: string;
    total_intensity: number;
    total_intensity_unit: string;
    overall_quality: number;
    breakdown_by_type: Array<{
      __typename?: 'ItemisedEmissionsReportBreakdownByType';
      label: EmissionDetailTypeEnum;
      percentage?: number | null;
      co2e: number;
    }>;
    breakdown_by_shipment: Array<{
      __typename?: 'ItemisedEmissionsReportBreakdownByShipment';
      id: string;
      shipment_identifier?: string | null;
      mode: EmissionModeEnum;
      date: string;
      from_address?: string | null;
      from_display?: string | null;
      from_code?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      to_code?: string | null;
      distance_km?: number | null;
      weight_kg: number;
      teu?: number | null;
      co2e: number;
      activity: number;
      activity_unit: string;
      intensity: number;
      intensity_unit: string;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
      breakdown_by_type: Array<{
        __typename?: 'ItemisedEmissionsReportBreakdownByType';
        label: EmissionDetailTypeEnum;
        co2e: number;
      }>;
    }>;
  };
};

export type GetItemisedEmissionsReportQueryVariables = Exact<{
  publicId: Scalars['String']['input'];
}>;

export type GetItemisedEmissionsReportQuery = {
  __typename?: 'Query';
  itemised_emissions_report: {
    __typename?: 'ItemisedEmissionsReport';
    id: string;
    public_id: string;
    name: string;
    prepared_for?: string | null;
    type: ReportTypeEnum;
    visibility: ReportVisibilityEnum;
    version_number: number;
    share_token: string;
    created_date: string;
    filters: {
      __typename?: 'ItemisedEmissionsReportFilters';
      start_date?: string | null;
      end_date?: string | null;
      import_id?: string | null;
      client_identifier: string;
      shipment_identifier: string;
    };
    results: {
      __typename?: 'ItemisedEmissionsReportResults';
      total_shipment: number;
      total_shipment_leg: number;
      total_co2e: number;
      total_activity: number;
      total_activity_unit: string;
      total_intensity: number;
      total_intensity_unit: string;
      overall_quality: number;
      breakdown_by_type: Array<{
        __typename?: 'ItemisedEmissionsReportBreakdownByType';
        label: EmissionDetailTypeEnum;
        percentage?: number | null;
        co2e: number;
      }>;
      breakdown_by_shipment: Array<{
        __typename?: 'ItemisedEmissionsReportBreakdownByShipment';
        id: string;
        shipment_identifier?: string | null;
        mode: EmissionModeEnum;
        date: string;
        from_address?: string | null;
        from_display?: string | null;
        from_code?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        to_code?: string | null;
        distance_km?: number | null;
        weight_kg: number;
        teu?: number | null;
        co2e: number;
        activity: number;
        activity_unit: string;
        intensity: number;
        intensity_unit: string;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
        breakdown_by_type: Array<{
          __typename?: 'ItemisedEmissionsReportBreakdownByType';
          label: EmissionDetailTypeEnum;
          co2e: number;
        }>;
      }>;
    };
    client: { __typename?: 'Client'; avatar?: { __typename?: 'S3Object'; key: string } | null };
    client_user?: { __typename?: 'ClientUser'; first_name?: string | null; last_name?: string | null } | null;
  };
};

export type SaveItemisedEmissionsReportMutationVariables = Exact<{
  baseFilters: ItemisedEmissionsReportFiltersInput;
  input: SaveReportInput;
  generatedDate: Scalars['DateTimeISO']['input'];
}>;

export type SaveItemisedEmissionsReportMutation = {
  __typename?: 'Mutation';
  save_itemised_emissions_report: {
    __typename?: 'ItemisedEmissionsReport';
    public_id: string;
    results: {
      __typename?: 'ItemisedEmissionsReportResults';
      total_shipment: number;
      total_shipment_leg: number;
      total_co2e: number;
      total_activity: number;
      total_activity_unit: string;
      total_intensity: number;
      total_intensity_unit: string;
      overall_quality: number;
      breakdown_by_type: Array<{
        __typename?: 'ItemisedEmissionsReportBreakdownByType';
        label: EmissionDetailTypeEnum;
        percentage?: number | null;
        co2e: number;
      }>;
      breakdown_by_shipment: Array<{
        __typename?: 'ItemisedEmissionsReportBreakdownByShipment';
        id: string;
        shipment_identifier?: string | null;
        mode: EmissionModeEnum;
        date: string;
        from_address?: string | null;
        from_display?: string | null;
        from_code?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        to_code?: string | null;
        distance_km?: number | null;
        weight_kg: number;
        teu?: number | null;
        co2e: number;
        activity: number;
        activity_unit: string;
        intensity: number;
        intensity_unit: string;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
        breakdown_by_type: Array<{
          __typename?: 'ItemisedEmissionsReportBreakdownByType';
          label: EmissionDetailTypeEnum;
          co2e: number;
        }>;
      }>;
    };
  };
};

export type UpdateReportNameMutationVariables = Exact<{
  publicId: Scalars['String']['input'];
  reportName: Scalars['String']['input'];
}>;

export type UpdateReportNameMutation = {
  __typename?: 'Mutation';
  update_report_name: { __typename?: 'Report'; id: string; public_id: string; name: string };
};

export type UpdateReportVisibilityMutationVariables = Exact<{
  publicId: Scalars['String']['input'];
  visibility: ReportVisibilityEnum;
}>;

export type UpdateReportVisibilityMutation = {
  __typename?: 'Mutation';
  update_report_visibility: { __typename?: 'Report'; id: string; public_id: string; visibility: ReportVisibilityEnum };
};

export type GetUntrackedEmissionQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetUntrackedEmissionQuery = {
  __typename?: 'Query';
  untracked_emission: {
    __typename?: 'Emission';
    id: string;
    public_id: string;
    import_id?: string | null;
    type: EmissionTypeEnum;
    co2e: number;
    intensity_normalized?: number | null;
    activity_normalized?: number | null;
    activity_normalized_unit?: EmissionFactorUnitEnum | null;
    date: string;
    created_date: string;
    distance_km?: number | null;
    sequence_no?: number | null;
    level?: number | null;
    mode: EmissionModeEnum;
    weight_kg?: number | null;
    teu?: number | null;
    no_of_pax?: number | null;
    context_stringify?: string | null;
    source_type_name: EmissionSourceTypeEnum;
    has_parent: boolean;
    parent_id?: string | null;
    parent_public_id?: string | null;
    parent_child_count?: number | null;
    share_token: string;
    visibility?: EmissionVisibilityEnum | null;
    shipment_reference_number_metadata_key: string;
    shipment_reference_number_metadata_value?: string | null;
    client: {
      __typename?: 'Client';
      legal_name?: string | null;
      id: string;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    };
    meta_data?: Array<{ __typename?: 'Metadata'; key: string; display: string; value: string }> | null;
    quality?: {
      __typename?: 'EmissionQuality';
      overall?: EmissionQualityScoreEnum | null;
      origin_destination?: EmissionQualityScoreEnum | null;
      weight?: EmissionQualityScoreEnum | null;
      emission_intensity_factor?: EmissionQualityScoreEnum | null;
    } | null;
    parent_quality?: { __typename?: 'EmissionParentQuality'; overall?: number | null } | null;
    emission_children: Array<{
      __typename?: 'Emission';
      id: string;
      public_id: string;
      type: EmissionTypeEnum;
      mode: EmissionModeEnum;
      co2e: number;
      date: string;
      distance_km?: number | null;
      weight_kg?: number | null;
      teu?: number | null;
      context_stringify?: string | null;
      sequence_no?: number | null;
      level?: number | null;
      quality?: {
        __typename?: 'EmissionQuality';
        overall?: EmissionQualityScoreEnum | null;
        origin_destination?: EmissionQualityScoreEnum | null;
        weight?: EmissionQualityScoreEnum | null;
        emission_intensity_factor?: EmissionQualityScoreEnum | null;
      } | null;
      issues?: Array<{
        __typename?: 'EmissionIssue';
        param: string;
        input_value?: string | null;
        assumed_value?: string | null;
        recommendations: Array<{
          __typename?: 'EmissionIssueRecommendation';
          action: RecommendationAction;
          params: Array<string>;
        }>;
      }> | null;
      freight?: {
        __typename?: 'EmissionFreight';
        carriage: EmissionFreightCarriageEnum;
        vehicle: EmissionFreightVehicleEnum;
        vehicle_code?: string | null;
        vehicle_category: EmissionFreightVehicleCategoryEnum;
        fuel_type?: EmissionFreightFuelTypeEnum | null;
        fuel_consumption?: number | null;
        fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
        vessel_id?: string | null;
        vessel_name?: string | null;
        carrier_code?: string | null;
        carrier_name?: string | null;
        aircraft_code?: string | null;
        flight_no?: string | null;
        emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
        load_factor?: number | null;
        load_type?: EmissionFreightLoadTypeEnum | null;
        empty_running?: number | null;
        cargo_type: EmissionFreightCargoTypeEnum;
        is_refrigerated: boolean;
        from_address?: string | null;
        from_code?: string | null;
        from_country_code?: string | null;
        from_country_name?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_code?: string | null;
        to_country_code?: string | null;
        to_display?: string | null;
        route_details?: {
          __typename?: 'RouteDetails';
          is_urban?: boolean | null;
          is_motorway?: boolean | null;
          is_ferry?: boolean | null;
          is_train?: boolean | null;
          country_code_alpha_2?: string | null;
          path?: string | null;
          stops?: Array<{
            __typename?: 'RouteStop';
            code?: string | null;
            stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
          }> | null;
        } | null;
        fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      } | null;
      freight_aggregated?: {
        __typename?: 'EmissionFreightAggregated';
        start_date: string;
        end_date: string;
        vehicle: EmissionFreightAggregatedVehicleEnum;
        vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
        vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
        fuel_type: EmissionFreightAggregatedFuelTypeEnum;
        fuel_consumption: number;
        fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
        fuel_used: number;
        fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
        load_factor: number;
        distance_km: number;
        weight_kg: number;
        vehicle_id?: string | null;
        fleet_id?: string | null;
      } | null;
      flights?: {
        __typename?: 'EmissionFlights';
        no_of_pax: number;
        cabin_class?: EmissionFlightsClassEnum | null;
        airline?: string | null;
        aircraft?: string | null;
        flight_no?: string | null;
        from_code: string;
        from_display?: string | null;
        to_code: string;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
      } | null;
      last_mile?: {
        __typename?: 'EmissionLastMile';
        vehicle: EmissionLastMileVehicleEnum;
        vehicle_category: EmissionLastMileVehicleCategoryEnum;
        fuel_type?: EmissionLastMileFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
      } | null;
      ride_hailing?: {
        __typename?: 'EmissionRideHailing';
        vehicle: EmissionRideHailingVehicleEnum;
        vehicle_category: EmissionRideHailingVehicleCategoryEnum;
        fuel_type?: EmissionRideHailingFuelTypeEnum | null;
        make?: string | null;
        model?: string | null;
        no_of_pax?: number | null;
        from_address?: string | null;
        from_display?: string | null;
        to_address?: string | null;
        to_display?: string | null;
        from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
      } | null;
      details: Array<{
        __typename?: 'EmissionDetail';
        co2e: number;
        co2?: number | null;
        ch4?: number | null;
        n2o?: number | null;
        nox?: number | null;
        so2?: number | null;
        pm?: number | null;
        nmhc?: number | null;
        type?: EmissionDetailTypeEnum | null;
        subtype?: EmissionDetailSubtypeEnum | null;
        scope?: EmissionDetailScopeEnum | null;
        category?: EmissionDetailCategoryEnum | null;
      }>;
      emission_children: Array<{
        __typename?: 'Emission';
        id: string;
        public_id: string;
        type: EmissionTypeEnum;
        mode: EmissionModeEnum;
        co2e: number;
        date: string;
        distance_km?: number | null;
        weight_kg?: number | null;
        context_stringify?: string | null;
        sequence_no?: number | null;
        level?: number | null;
        issues?: Array<{
          __typename?: 'EmissionIssue';
          param: string;
          input_value?: string | null;
          assumed_value?: string | null;
          recommendations: Array<{
            __typename?: 'EmissionIssueRecommendation';
            action: RecommendationAction;
            params: Array<string>;
          }>;
        }> | null;
        freight?: {
          __typename?: 'EmissionFreight';
          carriage: EmissionFreightCarriageEnum;
          vehicle: EmissionFreightVehicleEnum;
          vehicle_code?: string | null;
          vehicle_category: EmissionFreightVehicleCategoryEnum;
          fuel_type?: EmissionFreightFuelTypeEnum | null;
          fuel_consumption?: number | null;
          fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
          vessel_id?: string | null;
          vessel_name?: string | null;
          carrier_code?: string | null;
          carrier_name?: string | null;
          aircraft_code?: string | null;
          flight_no?: string | null;
          emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
          load_factor?: number | null;
          load_type?: EmissionFreightLoadTypeEnum | null;
          empty_running?: number | null;
          cargo_type: EmissionFreightCargoTypeEnum;
          is_refrigerated: boolean;
          from_address?: string | null;
          from_code?: string | null;
          from_country_code?: string | null;
          from_country_name?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_code?: string | null;
          to_country_code?: string | null;
          to_display?: string | null;
          route_details?: {
            __typename?: 'RouteDetails';
            is_urban?: boolean | null;
            is_motorway?: boolean | null;
            is_ferry?: boolean | null;
            is_train?: boolean | null;
            country_code_alpha_2?: string | null;
            path?: string | null;
          } | null;
          fuel_identifier?: {
            __typename?: 'EmissionFreightFuelIdentifier';
            code: string;
            source?: string | null;
          } | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
        } | null;
        freight_aggregated?: {
          __typename?: 'EmissionFreightAggregated';
          start_date: string;
          end_date: string;
          vehicle: EmissionFreightAggregatedVehicleEnum;
          vehicle_code: EmissionFreightAggregatedVehicleCodeEnum;
          vehicle_category: EmissionFreightAggregatedVehicleCategoryEnum;
          fuel_type: EmissionFreightAggregatedFuelTypeEnum;
          fuel_consumption: number;
          fuel_consumption_unit: EmissionFreightAggregatedFuelConsumptionUnitNormalisedEnum;
          fuel_used: number;
          fuel_used_unit: EmissionFreightAggregatedFuelUsedUnitNormalisedEnum;
          load_factor: number;
          distance_km: number;
          weight_kg: number;
          vehicle_id?: string | null;
          fleet_id?: string | null;
        } | null;
        flights?: {
          __typename?: 'EmissionFlights';
          no_of_pax: number;
          cabin_class?: EmissionFlightsClassEnum | null;
          airline?: string | null;
          aircraft?: string | null;
          flight_no?: string | null;
          from_code: string;
          from_display?: string | null;
          to_code: string;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
        } | null;
        last_mile?: {
          __typename?: 'EmissionLastMile';
          vehicle: EmissionLastMileVehicleEnum;
          vehicle_category: EmissionLastMileVehicleCategoryEnum;
          fuel_type?: EmissionLastMileFuelTypeEnum | null;
          make?: string | null;
          model?: string | null;
          from_address?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
        } | null;
        ride_hailing?: {
          __typename?: 'EmissionRideHailing';
          vehicle: EmissionRideHailingVehicleEnum;
          vehicle_category: EmissionRideHailingVehicleCategoryEnum;
          fuel_type?: EmissionRideHailingFuelTypeEnum | null;
          make?: string | null;
          model?: string | null;
          no_of_pax?: number | null;
          from_address?: string | null;
          from_display?: string | null;
          to_address?: string | null;
          to_display?: string | null;
          from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
          route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
        } | null;
        details: Array<{
          __typename?: 'EmissionDetail';
          co2e: number;
          co2?: number | null;
          ch4?: number | null;
          n2o?: number | null;
          nox?: number | null;
          so2?: number | null;
          pm?: number | null;
          nmhc?: number | null;
          type?: EmissionDetailTypeEnum | null;
          subtype?: EmissionDetailSubtypeEnum | null;
          scope?: EmissionDetailScopeEnum | null;
          category?: EmissionDetailCategoryEnum | null;
        }>;
      }>;
    }>;
    last_mile?: {
      __typename?: 'EmissionLastMile';
      vehicle: EmissionLastMileVehicleEnum;
      vehicle_category: EmissionLastMileVehicleCategoryEnum;
      fuel_type?: EmissionLastMileFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    ride_hailing?: {
      __typename?: 'EmissionRideHailing';
      vehicle: EmissionRideHailingVehicleEnum;
      vehicle_category: EmissionRideHailingVehicleCategoryEnum;
      fuel_type?: EmissionRideHailingFuelTypeEnum | null;
      make?: string | null;
      model?: string | null;
      no_of_pax?: number | null;
      from_address?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    flights?: {
      __typename?: 'EmissionFlights';
      no_of_pax: number;
      cabin_class?: EmissionFlightsClassEnum | null;
      airline?: string | null;
      aircraft?: string | null;
      flight_no?: string | null;
      from_code: string;
      from_display?: string | null;
      to_code: string;
      to_display?: string | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      route_details?: { __typename?: 'RouteDetails'; path?: string | null } | null;
    } | null;
    freight?: {
      __typename?: 'EmissionFreight';
      carriage: EmissionFreightCarriageEnum;
      vehicle: EmissionFreightVehicleEnum;
      vehicle_code?: string | null;
      vehicle_category: EmissionFreightVehicleCategoryEnum;
      fuel_type?: EmissionFreightFuelTypeEnum | null;
      fuel_consumption?: number | null;
      fuel_consumption_unit?: EmissionFreightFuelConsumptionUnitNormalisedEnum | null;
      vessel_id?: string | null;
      vessel_name?: string | null;
      carrier_code?: string | null;
      carrier_name?: string | null;
      aircraft_code?: string | null;
      flight_no?: string | null;
      emission_standard?: EmissionFreightVehicleEmissionStandardEnum | null;
      load_factor?: number | null;
      load_type?: EmissionFreightLoadTypeEnum | null;
      empty_running?: number | null;
      cargo_type: EmissionFreightCargoTypeEnum;
      is_refrigerated: boolean;
      from_address?: string | null;
      from_code?: string | null;
      from_country_code?: string | null;
      from_country_name?: string | null;
      from_display?: string | null;
      to_address?: string | null;
      to_code?: string | null;
      to_country_code?: string | null;
      to_display?: string | null;
      route_details?: {
        __typename?: 'RouteDetails';
        is_urban?: boolean | null;
        is_motorway?: boolean | null;
        is_ferry?: boolean | null;
        is_train?: boolean | null;
        country_code_alpha_2?: string | null;
        path?: string | null;
        stops?: Array<{
          __typename?: 'RouteStop';
          code?: string | null;
          stop_info?: { __typename?: 'RouteStopInfo'; code_alpha_2?: string | null; name?: string | null } | null;
        }> | null;
      } | null;
      fuel_identifier?: { __typename?: 'EmissionFreightFuelIdentifier'; code: string; source?: string | null } | null;
      from_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
      to_coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
    } | null;
    client_user?: {
      __typename?: 'ClientUser';
      first_name?: string | null;
      last_name?: string | null;
      avatar?: { __typename?: 'S3Object'; key: string } | null;
    } | null;
    details: Array<{
      __typename?: 'EmissionDetail';
      co2e: number;
      co2?: number | null;
      ch4?: number | null;
      n2o?: number | null;
      nox?: number | null;
      so2?: number | null;
      pm?: number | null;
      nmhc?: number | null;
      type?: EmissionDetailTypeEnum | null;
      subtype?: EmissionDetailSubtypeEnum | null;
      scope?: EmissionDetailScopeEnum | null;
      category?: EmissionDetailCategoryEnum | null;
    }>;
  };
};

export type CreateProfileMutationVariables = Exact<{
  data: CreateProfileInput;
}>;

export type CreateProfileMutation = {
  __typename?: 'Mutation';
  create_profile: {
    __typename?: 'CreateProfile';
    redirect_to?: string | null;
    user: {
      __typename?: 'User';
      first_name?: string | null;
      last_name?: string | null;
      job_function?: ClientUserJobFunctionEnum | null;
      account_state: ClientUserAccountStateEnum;
    };
  };
};

export type DeleteMeMutationVariables = Exact<{
  pass: Scalars['String']['input'];
}>;

export type DeleteMeMutation = { __typename?: 'Mutation'; delete_me: boolean };

export type UpdateEmissionImportDefaultsMutationVariables = Exact<{
  date_format: Scalars['String']['input'];
  number_format: Scalars['String']['input'];
}>;

export type UpdateEmissionImportDefaultsMutation = {
  __typename?: 'Mutation';
  update_emission_import_defaults: {
    __typename?: 'EmissionImportDefaultFormats';
    date_format: string;
    number_format: string;
  };
};

export type UpdateLanguageMutationVariables = Exact<{
  language?: InputMaybe<LanguageEnum>;
}>;

export type UpdateLanguageMutation = { __typename?: 'Mutation'; update_language?: LanguageEnum | null };

export type UpdateMeMutationVariables = Exact<{
  data: UpdateUserInput;
}>;

export type UpdateMeMutation = {
  __typename?: 'Mutation';
  update_me: {
    __typename?: 'User';
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    avatar?: { __typename?: 'S3Object'; location: string; eTag: string; bucket: string; key: string } | null;
  };
};

export const BuyOffsetting_FromBuyOffsettingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyOffsetting_fromBuyOffsetting' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BuyOffsetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'paymentPageLink' } }],
      },
    },
  ],
} as unknown as DocumentNode<BuyOffsetting_FromBuyOffsettingFragment, unknown>;
export const ConfirmOffsetting_FromBuyOffsettingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConfirmOffsetting_fromBuyOffsetting' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BuyOffsetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'paymentPageLink' } }],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmOffsetting_FromBuyOffsettingFragment, unknown>;
export const ClientMetadataDisplayNames_FromGetClientMetadataDisplayNamesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientMetadataDisplayNames_fromGetClientMetadataDisplayNames' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientMetadataDisplayName' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_display_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientMetadataDisplayNames_FromGetClientMetadataDisplayNamesFragment, unknown>;
export const ClientUserProfile_FromGetClientUsersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetClientUsers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientUserProfile_FromGetClientUsersFragment, unknown>;
export const ClientUser_FromGetClientUsersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUser_fromGetClientUsers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientUserProfile_fromGetClientUsers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'account_state' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetClientUsers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientUser_FromGetClientUsersFragment, unknown>;
export const Client_FromUpdateClientFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Client_fromUpdateClient' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client_function' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Client_FromUpdateClientFragment, unknown>;
export const ClientUserEmailPreference_FromGetClientUserEmailPreferenceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserEmailPreference_fromGetClientUserEmailPreference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUserEmailPreference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientUserEmailPreference_FromGetClientUserEmailPreferenceFragment, unknown>;
export const ClientUserMfa_FromGetClientUserMfaFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserMFA_fromGetClientUserMFA' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUserMFA' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mfa_enabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientUserMfa_FromGetClientUserMfaFragment, unknown>;
export const Country_FromGetCountriesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Country_fromGetCountries' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Country' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Country_FromGetCountriesFragment, unknown>;
export const DataCollection_FromCreateDataCollectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollection_fromCreateDataCollection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollection_FromCreateDataCollectionFragment, unknown>;
export const DataCollection_FromGetDataCollectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollection_fromGetDataCollection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latest_request_updated_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollection_FromGetDataCollectionFragment, unknown>;
export const ClientProfile_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientProfile_FromGetDataCollectionRequestFragment, unknown>;
export const ClientUserProfile_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientUserProfile_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollectionRequestRequestedActivity_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionRequestRequestedActivity_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollectionRequestAcceptedActivity_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionRequestAcceptedActivity_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollectionRequestDeclinedActivity_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionRequestDeclinedActivity_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollectionRequestCancelledActivity_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionRequestCancelledActivity_FromGetDataCollectionRequestFragment, unknown>;
export const EmissionsModeBreakdown_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmissionsModeBreakdown_FromGetDataCollectionRequestFragment, unknown>;
export const EmissionsLifecycleBreakdown_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmissionsLifecycleBreakdown_FromGetDataCollectionRequestFragment, unknown>;
export const EmissionsAggregations_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmissionsAggregations_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollectionRequestDataStagedActivity_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionRequestDataStagedActivity_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollectionRequestSubmittedActivity_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionRequestSubmittedActivity_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollectionRequestRejectedActivity_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionRequestRejectedActivity_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollectionRequestApprovedActivity_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionRequestApprovedActivity_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollectionRequestRemindedActivity_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionRequestRemindedActivity_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollectionRequestDeliveryFailedActivity_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionRequestDeliveryFailedActivity_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollectionRequestViewedActivity_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionRequestViewedActivity_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollectionRequestRequestFailedActivity_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionRequestRequestFailedActivity_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollection_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollection_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollection_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollectionRequest_FromGetDataCollectionRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequest_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DataCollectionRequestDeliveryFailedActivity_fromGetDataCollectionRequest',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DataCollectionRequestRequestFailedActivity_fromGetDataCollectionRequest',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollection_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollection_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollectionRequest_FromGetDataCollectionRequestFragment, unknown>;
export const DataCollection_FromGetDataCollectionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollection_fromGetDataCollections' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latest_request_updated_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataCollection_FromGetDataCollectionsFragment, unknown>;
export const ApiKey_FromGetApiKeysFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiKey_fromGetApiKeys' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ApiKey' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'api_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApiKey_FromGetApiKeysFragment, unknown>;
export const ApiLog_FromGetApiLogsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiLog_fromGetApiLogs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ApiLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'api_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'response_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'request' } },
          { kind: 'Field', name: { kind: 'Name', value: 'response' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApiLog_FromGetApiLogsFragment, unknown>;
export const NetworkMemberFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetworkMember' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetworkMemberFragment, unknown>;
export const DirectoryEntryMetaDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DirectoryEntryMetaDataFragment, unknown>;
export const DirectoryReferenceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DirectoryReferenceFragment, unknown>;
export const DirectoryDomainFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DirectoryDomainFragment, unknown>;
export const DirectoryContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DirectoryContactFragment, unknown>;
export const DirectoryEntryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntryMetaData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'references' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryReference' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryDomain' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_member' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DirectoryEntryFragment, unknown>;
export const SupplierDirectoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SupplierDirectory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntryMetaData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'references' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryReference' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryDomain' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_member' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SupplierDirectoryFragment, unknown>;
export const EmissionQuality_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionQuality' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin_destination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_intensity_factor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmissionQuality_FromGetEmissionFragment, unknown>;
export const EmissionParentQuality_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionParentQuality' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }] },
    },
  ],
} as unknown as DocumentNode<EmissionParentQuality_FromGetEmissionFragment, unknown>;
export const EmissionIssue_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmissionIssue_FromGetEmissionFragment, unknown>;
export const Base_FreightEmission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Base_FreightEmission_FromGetEmissionFragment, unknown>;
export const FreightEmissionWithStops_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stop_info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code_alpha_2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightEmissionWithStops_FromGetEmissionFragment, unknown>;
export const FreightAggregatedEmission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightAggregatedEmission_FromGetEmissionFragment, unknown>;
export const FlightsEmission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FlightsEmission_FromGetEmissionFragment, unknown>;
export const LastMileEmission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LastMileEmission_FromGetEmissionFragment, unknown>;
export const RideHailingEmission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RideHailingEmission_FromGetEmissionFragment, unknown>;
export const EmissionDetail_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmissionDetail_FromGetEmissionFragment, unknown>;
export const FreightEmission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FreightEmission_FromGetEmissionFragment, unknown>;
export const SegmentEmissions_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SegmentEmissions_FromGetEmissionFragment, unknown>;
export const ChildEmissions_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionQuality' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin_destination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_intensity_factor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stop_info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code_alpha_2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChildEmissions_FromGetEmissionFragment, unknown>;
export const Emission_FromGetEmissionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Emission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'has_parent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_child_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionQuality' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin_destination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_intensity_factor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stop_info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code_alpha_2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionParentQuality' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Emission_FromGetEmissionFragment, unknown>;
export const Freight_FromGetEmissionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Freight_fromGetEmissions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Freight_FromGetEmissionsFragment, unknown>;
export const EmissionChild_FromGetEmissionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionChild_fromGetEmissions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionChildDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Freight_fromGetEmissions' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Freight_fromGetEmissions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmissionChild_FromGetEmissionsFragment, unknown>;
export const Emission_FromGetEmissionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Emission_fromGetEmissions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'well_known_metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supplier_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lsp_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipment_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionChild_fromGetEmissions' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Freight_fromGetEmissions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionChild_fromGetEmissions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionChildDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Freight_fromGetEmissions' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Emission_FromGetEmissionsFragment, unknown>;
export const EmissionVisibility_FromUpdateEmissionVisibilityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionVisibility_fromUpdateEmissionVisibility' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmissionVisibility_FromUpdateEmissionVisibilityFragment, unknown>;
export const Import_FromGetImportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Import_fromGetImport' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Import' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processed_rows_success_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processed_rows_error_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'has_success_file' } },
          { kind: 'Field', name: { kind: 'Name', value: 'has_error_file' } },
          { kind: 'Field', name: { kind: 'Name', value: 'has_success_file_xlsx' } },
          { kind: 'Field', name: { kind: 'Name', value: 'has_error_file_xlsx' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_client_key' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'withEmissionsByClientKey' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_supplier_key' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'withEmissionsBySupplierKey' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'supplier_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Import_FromGetImportFragment, unknown>;
export const Import_FromGetImportCsvErrorSignedUrlFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Import_fromGetImportCsvErrorSignedUrl' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Import' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'processed_rows_error_file_url' } }],
      },
    },
  ],
} as unknown as DocumentNode<Import_FromGetImportCsvErrorSignedUrlFragment, unknown>;
export const Import_FromGetImportCsvXlsxErrorSignedUrlFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Import_fromGetImportCsvXlsxErrorSignedUrl' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Import' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'processed_rows_error_file_url_xlsx' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processed_rows_error_file_url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Import_FromGetImportCsvXlsxErrorSignedUrlFragment, unknown>;
export const Import_FromGetImportsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Import_fromGetImports' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Import' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filesize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vertical' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'location' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processed_rows_success_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processed_rows_error_count' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Import_FromGetImportsFragment, unknown>;
export const ClientIntegration_FromGetClientIntegrationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientIntegration_fromGetClientIntegration' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientIntegration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary_system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status_update_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status_update_requested_by' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientIntegration_FromGetClientIntegrationFragment, unknown>;
export const OffsettingLink_FromGetDefaultOffsettingLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OffsettingLink_fromGetDefaultOffsettingLink' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OffsettingLink_FromGetDefaultOffsettingLinkFragment, unknown>;
export const OffsettingLink_FromGetOffsettingLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OffsettingLink_fromGetOffsettingLink' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_default' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portfolio_version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocated_weight_kg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reserved_weight_kg' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'show_confirmation_page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'redirect_url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created_by' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updated_by' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OffsettingLink_FromGetOffsettingLinkFragment, unknown>;
export const OffsettingLink_FromGetOffsettingLinksFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OffsettingLink_fromGetOffsettingLinks' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_default' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portfolio_version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocated_weight_kg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reserved_weight_kg' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OffsettingLink_FromGetOffsettingLinksFragment, unknown>;
export const SaveOffsettingLink_FromSaveOffsettingLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SaveOffsettingLink_fromSaveOffsettingLink' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'show_confirmation_page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'redirect_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveOffsettingLink_FromSaveOffsettingLinkFragment, unknown>;
export const OffsettingLink_FromUpdateDefaultOffsettingLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OffsettingLink_fromUpdateDefaultOffsettingLink' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_default' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OffsettingLink_FromUpdateDefaultOffsettingLinkFragment, unknown>;
export const Order_FromGetOrderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Order_fromGetOrder' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Order' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commission_percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small_order_fee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small_order_threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issued_on_behalf_of' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status_timeline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_user_first_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_user_avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'impact_link_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'media' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'certificate_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Order_FromGetOrderFragment, unknown>;
export const Order_FromGetOrdersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Order_fromGetOrders' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Order' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Order_FromGetOrdersFragment, unknown>;
export const PlanItemPrice_FromGetPlansFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlanItemPrice_fromGetPlans' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PlanItemPrice' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'list_price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sale_price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount_percentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlanItemPrice_FromGetPlansFragment, unknown>;
export const Plan_FromGetPlansFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan_fromGetPlans' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_enhanced' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'item_prices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PlanItemPrice_fromGetPlans' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlanItemPrice_fromGetPlans' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PlanItemPrice' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'list_price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sale_price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount_percentage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Plan_FromGetPlansFragment, unknown>;
export const Portfolio_FromBuyAgainPortfoliosFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio_fromBuyAgainPortfolios' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'public_id' } }],
      },
    },
  ],
} as unknown as DocumentNode<Portfolio_FromBuyAgainPortfoliosFragment, unknown>;
export const Portfolio_FromGetPortfolioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio_fromGetPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocated_weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reserved_weight_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coordinates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalAllocated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Portfolio_FromGetPortfolioFragment, unknown>;
export const KeyFact_FromGetPortfolioFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeyFact_fromGetPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PortfolioKeyFact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key_facts' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KeyFact_FromGetPortfolioFragment, unknown>;
export const Portfolio_FromGetPortfoliosFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio_fromGetPortfolios' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocated_weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reserved_weight_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalAllocated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Portfolio_FromGetPortfoliosFragment, unknown>;
export const Project_FromGetProjectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_fromGetProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'developer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_short' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonplan_assessment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'data_stringify' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sdgs' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Project_FromGetProjectFragment, unknown>;
export const Project_FromGetProjectsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_fromGetProjects' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'developer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_short' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonplan_assessment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'data_stringify' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sdgs' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Project_FromGetProjectsFragment, unknown>;
export const Report_FromGetReportsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report_fromGetReports' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Report' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prepared_for' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Report_FromGetReportsFragment, unknown>;
export const GhgTypeBreakdownItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgTypeBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TypeBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'biogenic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'methodology' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geocoding_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'routing_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'methodology_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GhgTypeBreakdownItemFragment, unknown>;
export const GhgBreakdownItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GhgBreakdownItemFragment, unknown>;
export const GhgResultsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2e' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_3' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_3' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgTypeBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TypeBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'biogenic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'methodology' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geocoding_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'routing_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'methodology_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GhgResultsFieldsFragment, unknown>;
export const GlecBreakdownFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecBreakdownFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlecBreakdownFieldsFragment, unknown>;
export const GlecReportByModeFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecReportByModeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportByMode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'main_data_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlecReportByModeFieldsFragment, unknown>;
export const GlecResultsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_scope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_carriage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_data_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecReportByModeFields' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecBreakdownFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecReportByModeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportByMode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'main_data_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlecResultsFieldsFragment, unknown>;
export const ItemisedEmissionsResultsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemisedEmissionsResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemisedEmissionsReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipment_leg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_shipment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipment_identifier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'from_coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'to_coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quality' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'breakdown_by_type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ItemisedEmissionsResultsFieldsFragment, unknown>;
export const BuyOffsettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'buyOffsetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BuyOffsettingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buy_offsetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuyOffsetting_fromBuyOffsetting' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyOffsetting_fromBuyOffsetting' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BuyOffsetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'paymentPageLink' } }],
      },
    },
  ],
} as unknown as DocumentNode<BuyOffsettingMutation, BuyOffsettingMutationVariables>;
export const CacheTransactionIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cacheTransactionId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hosted_page_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cache_transaction_id' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hosted_page_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hosted_page_id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CacheTransactionIdMutation, CacheTransactionIdMutationVariables>;
export const CancelOffsettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cancelOffsetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelOffsettingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancel_offsetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelOffsettingMutation, CancelOffsettingMutationVariables>;
export const ConfirmOffsettingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'confirmOffsetting' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConfirmOffsettingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirm_offsetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConfirmOffsetting_fromBuyOffsetting' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConfirmOffsetting_fromBuyOffsetting' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BuyOffsetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'paymentPageLink' } }],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmOffsettingMutation, ConfirmOffsettingMutationVariables>;
export const ComputeFreightEmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ComputeFreightEmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'compute_freight_emission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Emission_fromGetEmission' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionQuality' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin_destination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_intensity_factor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionParentQuality' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stop_info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code_alpha_2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Emission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'has_parent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_child_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ComputeFreightEmissionMutation, ComputeFreightEmissionMutationVariables>;
export const CreateClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateClientInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'create_client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'client_function' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'redirect_to' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateClientMutation, CreateClientMutationVariables>;
export const DeleteClientMetadataDisplayNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteClientMetadataDisplayName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'metadata_key' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'metadata_value' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_metadata_display_name' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'metadata_key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'metadata_key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'metadata_value' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'metadata_value' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientMetadataDisplayNames_fromGetClientMetadataDisplayNames' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientMetadataDisplayNames_fromGetClientMetadataDisplayNames' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientMetadataDisplayName' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_display_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteClientMetadataDisplayNameMutation, DeleteClientMetadataDisplayNameMutationVariables>;
export const GetClientBillingDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getClientBillingDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_billing_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'country_code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'municipality' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vat' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'prefix' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientBillingDetailsQuery, GetClientBillingDetailsQueryVariables>;
export const GetClientOffsettingCommissionRateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getClientOffsettingCommissionRate' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'offsetting_commission_percentage' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientOffsettingCommissionRateQuery, GetClientOffsettingCommissionRateQueryVariables>;
export const GetClientManagePaymentSourcesHostedPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientManagePaymentSourcesHostedPage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_manage_payment_sources_hosted_page' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pass_thru_content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expires_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resource_version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'business_entity_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientManagePaymentSourcesHostedPageQuery,
  GetClientManagePaymentSourcesHostedPageQueryVariables
>;
export const GetClientMetadataDisplayNamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getClientMetadataDisplayNames' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'metadata_key' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'metadata_display_names' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'metadata_key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'metadata_key' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientMetadataDisplayNames_fromGetClientMetadataDisplayNames' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientMetadataDisplayNames_fromGetClientMetadataDisplayNames' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientMetadataDisplayName' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_display_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientMetadataDisplayNamesQuery, GetClientMetadataDisplayNamesQueryVariables>;
export const GetClientOrganizationDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientOrganizationDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_metadata_keys_with_count' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_function' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'branding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'brandColour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accentColour' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'auto_transshipment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emission_sharing_visibility' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ferry_load_characteristics' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'road_routing_ferry_preference' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sea_routing_restricted_areas' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientOrganizationDetailsQuery, GetClientOrganizationDetailsQueryVariables>;
export const GetClientPaymentSourcesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientPaymentSources' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort_by' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortConfig' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status_filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientPaymentSourceStatusFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_payment_sources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort_by' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status_filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status_filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'next_offset' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payment_sources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'card' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expiry_month' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expiry_year' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'last_four_digits' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientPaymentSourcesQuery, GetClientPaymentSourcesQueryVariables>;
export const GetClientShipmentReferenceNumberMetadataKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getClientShipmentReferenceNumberMetadataKey' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientShipmentReferenceNumberMetadataKeyQuery,
  GetClientShipmentReferenceNumberMetadataKeyQueryVariables
>;
export const GetClientSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientSubscription' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'next_billing_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan_item_price' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'period' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'is_discontinued' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'metered_quantity' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientSubscriptionQuery, GetClientSubscriptionQueryVariables>;
export const GetClientTeamSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientTeamSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'domains' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'team_access' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientTeamSettingsQuery, GetClientTeamSettingsQueryVariables>;
export const GetClientUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientUser_fromGetClientUsers' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetClientUsers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUser_fromGetClientUsers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientUserProfile_fromGetClientUsers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'account_state' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientUsersQuery, GetClientUsersQueryVariables>;
export const UpdateClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateClientInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_client' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Client_fromUpdateClient' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Client_fromUpdateClient' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'client_function' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateClientMutation, UpdateClientMutationVariables>;
export const UpdateClientAfterPaymentMethodChangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateClientAfterPaymentMethodChange' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'update_client_after_payment_method_update' } }],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateClientAfterPaymentMethodChangeMutation,
  UpdateClientAfterPaymentMethodChangeMutationVariables
>;
export const UpdateClientBillingDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateClientBillingDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateClientBillingDetailsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_client_billing_details' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateClientBillingDetailsMutation, UpdateClientBillingDetailsMutationVariables>;
export const UpdateClientBrandingSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateClientBrandingSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateClientBrandingSettingsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_client_branding_settings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'branding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'brandColour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'accentColour' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateClientBrandingSettingsMutation, UpdateClientBrandingSettingsMutationVariables>;
export const UpdateClientMetadataValueDisplayNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateClientMetadataValueDisplayName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'metadata_key' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'metadata_value' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value_display_name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_metadata_value_display_name' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'metadata_key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'metadata_key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'metadata_value' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'metadata_value' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value_display_name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'value_display_name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientMetadataDisplayNames_fromGetClientMetadataDisplayNames' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientMetadataDisplayNames_fromGetClientMetadataDisplayNames' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientMetadataDisplayName' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value_display_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateClientMetadataValueDisplayNameMutation,
  UpdateClientMetadataValueDisplayNameMutationVariables
>;
export const UpdateClientOrganizationPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateClientOrganizationPreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateClientOrganizationPreferencesInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_client_organization_preferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emission_sharing_visibility' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'auto_transshipment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ferry_load_characteristics' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'road_routing_ferry_preference' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sea_routing_restricted_areas' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateClientOrganizationPreferencesMutation,
  UpdateClientOrganizationPreferencesMutationVariables
>;
export const UpdateClientTeamAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateClientTeamAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateClientTeamAccessInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_client_team_access' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'domains' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'team_access' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateClientTeamAccessMutation, UpdateClientTeamAccessMutationVariables>;
export const ApproveClientUserAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveClientUserAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientRoleEnum' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approve_client_user_access' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'role' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApproveClientUserAccessMutation, ApproveClientUserAccessMutationVariables>;
export const CheckVerificationTokenMfaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checkVerificationTokenMFA' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MFACheckVerification' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'check_verification_token_mfa' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckVerificationTokenMfaMutation, CheckVerificationTokenMfaMutationVariables>;
export const DeleteClientUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteClientUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_client_user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteClientUserMutation, DeleteClientUserMutationVariables>;
export const DenyClientUserAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DenyClientUserAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deny_client_user_access' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DenyClientUserAccessMutation, DenyClientUserAccessMutationVariables>;
export const DismissClientUserTipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DismissClientUserTip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUserTipTypeEnum' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dismiss_client_user_tip' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DismissClientUserTipMutation, DismissClientUserTipMutationVariables>;
export const GetClientUserEmailPreferenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientUserEmailPreference' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'get_email_preferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserEmailPreference_fromGetClientUserEmailPreference' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserEmailPreference_fromGetClientUserEmailPreference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUserEmailPreference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientUserEmailPreferenceQuery, GetClientUserEmailPreferenceQueryVariables>;
export const GetClientUserMfaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientUserMFA' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'get_mfa' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientUserMFA_fromGetClientUserMFA' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserMFA_fromGetClientUserMFA' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUserMFA' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mfa_enabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientUserMfaQuery, GetClientUserMfaQueryVariables>;
export const GetClientUserTipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientUserTip' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUserTipTypeEnum' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user_tip' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientUserTipQuery, GetClientUserTipQueryVariables>;
export const InviteClientUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InviteClientUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InviteClientUsersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invite_client_users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientUser_fromGetClientUsers' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetClientUsers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUser_fromGetClientUsers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientUserProfile_fromGetClientUsers' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'role' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'account_state' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteClientUsersMutation, InviteClientUsersMutationVariables>;
export const SendVerificationTokenMfaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendVerificationTokenMFA' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MFASendVerification' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'send_verification_token_mfa' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendVerificationTokenMfaMutation, SendVerificationTokenMfaMutationVariables>;
export const SetClientUserEmailPreferenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetClientUserEmailPreference' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUserEmailPreferenceInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_email_preferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetClientUserEmailPreferenceMutation, SetClientUserEmailPreferenceMutationVariables>;
export const UpdateMfaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMFA' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateMFAInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_mfa' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mfa_enabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMfaMutation, UpdateMfaMutationVariables>;
export const UpdateClientUserRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateClientUserRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateClientUserRoleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_client_user_role' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateClientUserRoleMutation, UpdateClientUserRoleMutationVariables>;
export const ConfirmCsvFileForCalculationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmCSVFileForCalculation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirm_csv_file_for_calculation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'import_id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmCsvFileForCalculationMutation, ConfirmCsvFileForCalculationMutationVariables>;
export const UploadEmissionsFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadEmissionsFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'vertical' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ImportVerticalEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateFormat' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'numberFormat' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload_emissions_file' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vertical' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'vertical' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date_format' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateFormat' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'number_format' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'numberFormat' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'file_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'row_count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'row' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'column' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadEmissionsFileMutation, UploadEmissionsFileMutationVariables>;
export const UploadFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'folder' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'S3FolderEnum' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upload_file' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'folder' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'folder' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadFileMutation, UploadFileMutationVariables>;
export const GetCountriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCountries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Country_fromGetCountries' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Country_fromGetCountries' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Country' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCountriesQuery, GetCountriesQueryVariables>;
export const GetDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'token' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDashboardQuery, GetDashboardQueryVariables>;
export const CreateDataCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createDataCollection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateDataCollectionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'create_data_collection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollection_fromCreateDataCollection' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollection_fromCreateDataCollection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDataCollectionMutation, CreateDataCollectionMutationVariables>;
export const GetDataCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDataCollection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DataCollection_fromGetDataCollection' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollection_fromGetDataCollection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latest_request_updated_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recipients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataCollectionQuery, GetDataCollectionQueryVariables>;
export const GetDataCollectionRequestInboundDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDataCollectionRequestInbound' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection_request_inbound' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequest_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollection_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequest_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DataCollectionRequestDeliveryFailedActivity_fromGetDataCollectionRequest',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DataCollectionRequestRequestFailedActivity_fromGetDataCollectionRequest',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollection_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataCollectionRequestInboundQuery, GetDataCollectionRequestInboundQueryVariables>;
export const GetDataCollectionRequestOutboundDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDataCollectionRequestOutbound' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection_request_outbound' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequest_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollection_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequest_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DataCollectionRequestDeliveryFailedActivity_fromGetDataCollectionRequest',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DataCollectionRequestRequestFailedActivity_fromGetDataCollectionRequest',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollection_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataCollectionRequestOutboundQuery, GetDataCollectionRequestOutboundQueryVariables>;
export const GetDataCollectionRequestReviewDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDataCollectionRequestReviewData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'get_data_collection_request_review_data' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emissions_by_mode' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emissions_by_lifecycle' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data_collection_request' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DataCollectionRequest_fromGetDataCollectionRequest' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollection_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequest_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DataCollectionRequestDeliveryFailedActivity_fromGetDataCollectionRequest',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DataCollectionRequestRequestFailedActivity_fromGetDataCollectionRequest',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollection_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataCollectionRequestReviewDataQuery, GetDataCollectionRequestReviewDataQueryVariables>;
export const GetDataCollectionRequestReviewInventoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDataCollectionRequestReviewInventory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortedColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionSortedColumnEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortedOrderEnum' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'get_data_collection_request_review_inventory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_column' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortedColumn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Emission_fromGetEmissions' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pagination_limit' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Freight_fromGetEmissions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionChild_fromGetEmissions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionChildDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Freight_fromGetEmissions' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Emission_fromGetEmissions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'well_known_metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supplier_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lsp_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipment_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionChild_fromGetEmissions' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDataCollectionRequestReviewInventoryQuery,
  GetDataCollectionRequestReviewInventoryQueryVariables
>;
export const GetDataCollectionRequestTotalAndIndexedEmissionsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDataCollectionRequestTotalAndIndexedEmissionsCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection_request_total_and_indexed_emissions_copied_count' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total_submitted_emissions_count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_indexed_copied_emissions_count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDataCollectionRequestTotalAndIndexedEmissionsCountQuery,
  GetDataCollectionRequestTotalAndIndexedEmissionsCountQueryVariables
>;
export const GetDataCollectionRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDataCollectionRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortedColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestsSortedColumnEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortedOrderEnum' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection_requests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_column' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortedColumn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DataCollectionRequest_fromGetDataCollectionRequest' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollection_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequest_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DataCollectionRequestDeliveryFailedActivity_fromGetDataCollectionRequest',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DataCollectionRequestRequestFailedActivity_fromGetDataCollectionRequest',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollection_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataCollectionRequestsQuery, GetDataCollectionRequestsQueryVariables>;
export const GetDataCollectionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDataCollections' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortedColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionSortedColumnEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortedOrderEnum' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_column' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortedColumn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DataCollection_fromGetDataCollections' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollection_fromGetDataCollections' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latest_request_updated_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDataCollectionsQuery, GetDataCollectionsQueryVariables>;
export const ReviewDataCollectionRecipientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'reviewDataCollectionRecipients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewDataCollectionRecipientsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'review_data_collection_recipients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewDataCollectionRecipientsMutation, ReviewDataCollectionRecipientsMutationVariables>;
export const TransitionDataCollectionRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'transitionDataCollectionRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'event' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestEventEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'supplier_key' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transition_data_collection_request' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'event' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'event' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'note' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'supplier_key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'supplier_key' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequest_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'avatar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsModeBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionsAggregations' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsModeBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_lifecycle' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsLifecycleBreakdown_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_aggregations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionsAggregations_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DataCollectionRequestDeliveryFailedActivity' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequestRequestFailedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'event' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollection_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientProfile_fromGetDataCollectionRequest' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientUserProfile_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period_end_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DataCollectionRequest_fromGetDataCollectionRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DataCollectionRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRequestedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestAcceptedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDeclinedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestSubmittedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRejectedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestApprovedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestRemindedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestDataStagedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestCancelledActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DataCollectionRequestDeliveryFailedActivity_fromGetDataCollectionRequest',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollectionRequestViewedActivity_fromGetDataCollectionRequest' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'DataCollectionRequestRequestFailedActivity_fromGetDataCollectionRequest',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data_collection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DataCollection_fromGetDataCollectionRequest' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransitionDataCollectionRequestMutation, TransitionDataCollectionRequestMutationVariables>;
export const DeleteTestModeDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteTestModeData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'delete_test_mode_data' } }],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTestModeDataMutation, DeleteTestModeDataMutationVariables>;
export const GetApiInformationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApiInformation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'api_information' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApiInformationQuery, GetApiInformationQueryVariables>;
export const GetApiKeysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApiKeys' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'test' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'api_keys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'test' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'test' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApiKey_fromGetApiKeys' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiKey_fromGetApiKeys' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ApiKey' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'api_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApiKeysQuery, GetApiKeysQueryVariables>;
export const GetApiLogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAPILog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'api_log' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApiLog_fromGetApiLogs' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiLog_fromGetApiLogs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ApiLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'api_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'response_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'request' } },
          { kind: 'Field', name: { kind: 'Name', value: 'response' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApiLogQuery, GetApiLogQueryVariables>;
export const GetApiLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAPILogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'descend' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'exclusive_start_key' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'api_logs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'descend' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'descend' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'exclusive_start_key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'exclusive_start_key' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApiLog_fromGetApiLogs' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'last_evaluated_key' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApiLog_fromGetApiLogs' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ApiLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'api_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'response_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'request' } },
          { kind: 'Field', name: { kind: 'Name', value: 'response' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApiLogsQuery, GetApiLogsQueryVariables>;
export const PopulateTestModeDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'populateTestModeData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'populate_test_mode_data' } }],
      },
    },
  ],
} as unknown as DocumentNode<PopulateTestModeDataMutation, PopulateTestModeDataMutationVariables>;
export const RollApiKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RollApiKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'test' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roll_api_key' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'test' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'test' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RollApiKeyMutation, RollApiKeyMutationVariables>;
export const AssociateSupplierWithNetworkMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AssociateSupplierWithNetworkMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'directory_entry_public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'network_member_public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'associate_with_network_member' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'directory_entry_public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'directory_entry_public_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'network_member_public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'network_member_public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntry' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntryMetaData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'references' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryReference' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryDomain' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_member' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssociateSupplierWithNetworkMemberMutation,
  AssociateSupplierWithNetworkMemberMutationVariables
>;
export const NetworkMembersMatchingDomainsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NetworkMembersMatchingDomains' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'directory_entry_public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_members_matching_domains' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'directory_entry_public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'directory_entry_public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NetworkMember' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetworkMember' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Client' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetworkMembersMatchingDomainsQuery, NetworkMembersMatchingDomainsQueryVariables>;
export const ClientDirectoryEntriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientDirectoryEntries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_directories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntry' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntryMetaData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'references' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryReference' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryDomain' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_member' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientDirectoryEntriesQuery, ClientDirectoryEntriesQueryVariables>;
export const ClientDirectoryEntryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientDirectoryEntry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_directory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntry' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntryMetaData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'references' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryReference' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryDomain' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_member' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientDirectoryEntryQuery, ClientDirectoryEntryQueryVariables>;
export const CreateClientContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateClientContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'directory_entry_public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'create_client_contact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'directory_entry_public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'directory_entry_public_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first_name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last_name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateClientContactMutation, CreateClientContactMutationVariables>;
export const CreateClientDirectoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createClientDirectory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clientKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domains' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'create_client_directory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clientKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domains' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'domains' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntry' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntryMetaData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'references' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryReference' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryDomain' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_member' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateClientDirectoryMutation, CreateClientDirectoryMutationVariables>;
export const CreateSupplierContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSupplierContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'directory_entry_public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'create_supplier_contact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'directory_entry_public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'directory_entry_public_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first_name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last_name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSupplierContactMutation, CreateSupplierContactMutationVariables>;
export const CreateSupplierDirectoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSupplierDirectory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'supplierKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domains' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'create_supplier_directory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'supplierKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'supplierKey' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domains' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'domains' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntry' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntryMetaData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'references' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryReference' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryDomain' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_member' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSupplierDirectoryMutation, CreateSupplierDirectoryMutationVariables>;
export const DeleteClientContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteClientContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contact_public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_client_contact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contact_public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contact_public_id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteClientContactMutation, DeleteClientContactMutationVariables>;
export const DeleteSupplierContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSupplierContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contact_public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_supplier_contact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contact_public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contact_public_id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSupplierContactMutation, DeleteSupplierContactMutationVariables>;
export const ClientContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contact_public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_contact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contact_public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contact_public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientContactQuery, ClientContactQueryVariables>;
export const SupplierContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SupplierContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contact_public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplier_contact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contact_public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contact_public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SupplierContactQuery, SupplierContactQueryVariables>;
export const SupplierDirectoryEntriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SupplierDirectoryEntries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplier_directories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SupplierDirectory' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SupplierDirectory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntryMetaData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'references' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryReference' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryDomain' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_member' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SupplierDirectoryEntriesQuery, SupplierDirectoryEntriesQueryVariables>;
export const SupplierDirectoryEntryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SupplierDirectoryEntry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplier_directory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntry' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntryMetaData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'references' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryReference' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryDomain' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_member' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SupplierDirectoryEntryQuery, SupplierDirectoryEntryQueryVariables>;
export const UpdateClientContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateClientContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contact_public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_client_contact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contact_public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contact_public_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first_name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last_name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateClientContactMutation, UpdateClientContactMutationVariables>;
export const UpdateClientDirectoryEntryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateClientDirectoryEntry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reference' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domains' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_client_directory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reference' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reference' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domains' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'domains' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntry' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntryMetaData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'references' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryReference' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryDomain' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_member' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateClientDirectoryEntryMutation, UpdateClientDirectoryEntryMutationVariables>;
export const UpdateClientDirectoryEntryMetadataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateClientDirectoryEntryMetadata' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'meta_data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntryMetadataInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_client_directory_metadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meta_data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'meta_data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntry' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntryMetaData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'references' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryReference' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryDomain' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_member' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateClientDirectoryEntryMetadataMutation,
  UpdateClientDirectoryEntryMetadataMutationVariables
>;
export const UpdateSupplierContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSupplierContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contact_public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_supplier_contact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contact_public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contact_public_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first_name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first_name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last_name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'last_name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSupplierContactMutation, UpdateSupplierContactMutationVariables>;
export const UpdateSupplierDirectoryEntryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSupplierDirectoryEntry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reference' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domains' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_supplier_directory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reference' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reference' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domains' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'domains' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntry' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntryMetaData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'references' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryReference' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryDomain' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_member' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSupplierDirectoryEntryMutation, UpdateSupplierDirectoryEntryMutationVariables>;
export const UpdateSupplierDirectoryEntryMetadataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSupplierDirectoryEntryMetadata' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'meta_data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntryMetadataInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_supplier_directory_metadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'meta_data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'meta_data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntry' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntryMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryDomain' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryDomain' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'directoryEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DirectoryEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryEntryMetaData' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'references' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryReference' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domains' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryDomain' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'directoryContact' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network_member' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSupplierDirectoryEntryMetadataMutation,
  UpdateSupplierDirectoryEntryMetadataMutationVariables
>;
export const GetEmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Emission_fromGetEmission' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionQuality' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin_destination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_intensity_factor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionParentQuality' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stop_info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code_alpha_2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Emission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'has_parent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_child_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEmissionQuery, GetEmissionQueryVariables>;
export const GetEmissionFiltersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmissionFilters' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'emission_metadata_keys' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_co2e_range' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'histogram' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'doc_count' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_distance_range' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'histogram' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'doc_count' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_weight_range' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'histogram' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'doc_count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEmissionFiltersQuery, GetEmissionFiltersQueryVariables>;
export const GetEmissionMetadataKeysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmissionMetadataKeys' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emission_metadata_keys' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetEmissionMetadataKeysQuery, GetEmissionMetadataKeysQueryVariables>;
export const GetEmissionMetadataValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmissionMetadataValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'importId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_metadata_values' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'importId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'importId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEmissionMetadataValuesQuery, GetEmissionMetadataValuesQueryVariables>;
export const GetEmissionMetadataValuesWithoutCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmissionMetadataValuesWithoutCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_metadata_values' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEmissionMetadataValuesWithoutCountQuery,
  GetEmissionMetadataValuesWithoutCountQueryVariables
>;
export const GetEmissionPropertyValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmissionPropertyValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFilterPropertiesEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupKey' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFilterPropertiesEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_property_values' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group_key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group_value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEmissionPropertyValuesQuery, GetEmissionPropertyValuesQueryVariables>;
export const GetEmissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'types' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionTypeEnum' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modes' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionModeEnum' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activityTypeModePairs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activityTypeVehicleCodePairs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortedColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionSortedColumnEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortedOrderEnum' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'metadata' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MetadataInput' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'co2e' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RangeInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'distanceKm' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RangeInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'weightKg' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RangeInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRange' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRangeInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'importIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'types' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'types' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'modes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'modes' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activity_type_mode_pairs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'activityTypeModePairs' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activity_type_vehicle_code_pairs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'activityTypeVehicleCodePairs' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_column' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortedColumn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'metadata' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'metadata' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'co2e' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'co2e' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distance_km' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'distanceKm' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'weight_kg' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'weightKg' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date_range' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateRange' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'import_ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'importIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Emission_fromGetEmissions' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pagination_limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count_unfiltered' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Freight_fromGetEmissions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionChild_fromGetEmissions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionChildDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Freight_fromGetEmissions' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Emission_fromGetEmissions' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDocument' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'well_known_metadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supplier_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lsp_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipment_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionChild_fromGetEmissions' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEmissionsQuery, GetEmissionsQueryVariables>;
export const GetEmissionsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEmissionsCount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_count' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total_count_unfiltered' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEmissionsCountQuery, GetEmissionsCountQueryVariables>;
export const UpdateEmissionVisibilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEmissionVisibility' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionVisibilityEnum' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_emission_visibility' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'visibility' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmissionVisibility_fromUpdateEmissionVisibility' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionVisibility_fromUpdateEmissionVisibility' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEmissionVisibilityMutation, UpdateEmissionVisibilityMutationVariables>;
export const GetHomeEmissionsPerDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHomeEmissionsPerDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'view' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'HomeEmissionPerDateViewEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupByEmissionMode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home_emissions_per_date' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'view' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'view' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupByEmissionMode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupByEmissionMode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'breakdown' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'grouping' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emissionMode' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'calendarInterval' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHomeEmissionsPerDateQuery, GetHomeEmissionsPerDateQueryVariables>;
export const GetImportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getImport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'withEmissionsByClientKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'withEmissionsBySupplierKey' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'import' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Import_fromGetImport' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Import_fromGetImport' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Import' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processed_rows_success_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processed_rows_error_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'has_success_file' } },
          { kind: 'Field', name: { kind: 'Name', value: 'has_error_file' } },
          { kind: 'Field', name: { kind: 'Name', value: 'has_success_file_xlsx' } },
          { kind: 'Field', name: { kind: 'Name', value: 'has_error_file_xlsx' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e_total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_client_key' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'withEmissionsByClientKey' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emissions_by_supplier_key' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'withEmissionsBySupplierKey' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'supplier_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetImportQuery, GetImportQueryVariables>;
export const GetImportCsvErrorSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getImportCsvErrorSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'import' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Import_fromGetImportCsvErrorSignedUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Import_fromGetImportCsvErrorSignedUrl' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Import' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'processed_rows_error_file_url' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetImportCsvErrorSignedUrlQuery, GetImportCsvErrorSignedUrlQueryVariables>;
export const GetImportCsvSuccessSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getImportCsvSuccessSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'custom_schema_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'import' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processed_rows_success_file_url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'custom_schema_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'custom_schema_id' } },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetImportCsvSuccessSignedUrlQuery, GetImportCsvSuccessSignedUrlQueryVariables>;
export const GetImportCsvXlsxErrorSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getImportCsvXlsxErrorSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'import' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Import_fromGetImportCsvXlsxErrorSignedUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Import_fromGetImportCsvXlsxErrorSignedUrl' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Import' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'processed_rows_error_file_url_xlsx' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processed_rows_error_file_url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetImportCsvXlsxErrorSignedUrlQuery, GetImportCsvXlsxErrorSignedUrlQueryVariables>;
export const GetImportCsvXlsxSuccessSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getImportCsvXlsxSuccessSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'custom_schema_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'import' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processed_rows_success_file_url_xlsx' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'custom_schema_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'custom_schema_id' } },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processed_rows_success_file_url' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'custom_schema_id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'custom_schema_id' } },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetImportCsvXlsxSuccessSignedUrlQuery, GetImportCsvXlsxSuccessSignedUrlQueryVariables>;
export const GetImportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getImports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortedColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ImportSortedColumnEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortedOrderEnum' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_column' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortedColumn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Import_fromGetImports' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Import_fromGetImports' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Import' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filesize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vertical' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'location' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processed_rows_success_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processed_rows_error_count' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetImportsQuery, GetImportsQueryVariables>;
export const GetClientIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getClientIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_integration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientIntegration_fromGetClientIntegration' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientIntegration_fromGetClientIntegration' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientIntegration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary_system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status_update_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status_update_requested_by' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientIntegrationQuery, GetClientIntegrationQueryVariables>;
export const GetClientIntegrationLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getClientIntegrationLogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_integration_logs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'next_token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streams' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstEventTimestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastEventTimestamp' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'events' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientIntegrationLogsQuery, GetClientIntegrationLogsQueryVariables>;
export const GetClientIntegrationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getClientIntegrations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_integrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientIntegration_fromGetClientIntegration' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientIntegration_fromGetClientIntegration' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientIntegration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondary_system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status_update_date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status_update_requested_by' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientIntegrationsQuery, GetClientIntegrationsQueryVariables>;
export const GetInvoiceDownloadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInvoiceDownloadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoice_download_url' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid_until' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetInvoiceDownloadUrlQuery, GetInvoiceDownloadUrlQueryVariables>;
export const GetInvoicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInvoices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'issued_at' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'total' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'next_offset' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const GetDefaultOffsettingLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDefaultOffsettingLink' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'default_offsetting_link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OffsettingLink_fromGetDefaultOffsettingLink' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OffsettingLink_fromGetDefaultOffsettingLink' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDefaultOffsettingLinkQuery, GetDefaultOffsettingLinkQueryVariables>;
export const GetOffsettingLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOffsettingLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offsetting_link' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OffsettingLink_fromGetOffsettingLink' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OffsettingLink_fromGetOffsettingLink' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_default' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portfolio_version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocated_weight_kg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reserved_weight_kg' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'show_confirmation_page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'redirect_url' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created_by' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updated_by' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOffsettingLinkQuery, GetOffsettingLinkQueryVariables>;
export const GetOffsettingLinksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOffsettingLinks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'offsetting_links' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OffsettingLink_fromGetOffsettingLinks' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OffsettingLink_fromGetOffsettingLinks' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_default' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portfolio_version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allocated_weight_kg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reserved_weight_kg' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOffsettingLinksQuery, GetOffsettingLinksQueryVariables>;
export const SaveOffsettingLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveOffsettingLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'settings' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLinkSettingsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'save_offsetting_link' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'settings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'settings' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SaveOffsettingLink_fromSaveOffsettingLink' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SaveOffsettingLink_fromSaveOffsettingLink' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'show_confirmation_page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'redirect_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveOffsettingLinkMutation, SaveOffsettingLinkMutationVariables>;
export const UpdateOffsettingLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOffsettingLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'settings' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLinkSettingsInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_offsetting_link' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'settings' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'settings' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SaveOffsettingLink_fromSaveOffsettingLink' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SaveOffsettingLink_fromSaveOffsettingLink' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_default' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'show_confirmation_page' } },
          { kind: 'Field', name: { kind: 'Name', value: 'redirect_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOffsettingLinkMutation, UpdateOffsettingLinkMutationVariables>;
export const UpdateDefaultOffsettingLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDefaultOffsettingLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_default_offsetting_link' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OffsettingLink_fromUpdateDefaultOffsettingLink' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OffsettingLink_fromUpdateDefaultOffsettingLink' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OffsettingLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_active' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_default' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDefaultOffsettingLinkMutation, UpdateDefaultOffsettingLinkMutationVariables>;
export const GetOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Order_fromGetOrder' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Order_fromGetOrder' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Order' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commission_percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small_order_fee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small_order_threshold' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'issued_on_behalf_of' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status_timeline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source_type_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_user_first_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_user_avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'impact_link_id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'media' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'certificate_url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrderQuery, GetOrderQueryVariables>;
export const GetOrderFiltersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrderFilters' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order_co2e_range' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'histogram' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'doc_count' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'order_metadata_keys' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrderFiltersQuery, GetOrderFiltersQueryVariables>;
export const GetOrderMetadataValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrderMetadataValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order_metadata_values' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrderMetadataValuesQuery, GetOrderMetadataValuesQueryVariables>;
export const GetOrderPropertyValuesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrderPropertyValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderFilterPropertiesEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order_property_values' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrderPropertyValuesQuery, GetOrderPropertyValuesQueryVariables>;
export const GetOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'co2e' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'RangeInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateRange' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateRangeInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderStatusEnum' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumbers' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'metadata' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MetadataInput' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortedColumn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderSortedColumnEnum' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortedOrderEnum' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'co2e' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'co2e' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date_range' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dateRange' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoice_numbers' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumbers' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'metadata' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'metadata' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_column' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortedColumn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Order_fromGetOrders' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pagination_limit' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Order_fromGetOrders' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Order' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portfolio_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetPlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPlans' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Plan_fromGetPlans' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlanItemPrice_fromGetPlans' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PlanItemPrice' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'list_price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sale_price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discount_percentage' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Plan_fromGetPlans' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Plan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_enhanced' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'item_prices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PlanItemPrice_fromGetPlans' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPlansQuery, GetPlansQueryVariables>;
export const SelectPlanItemHostedPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SelectPlanItemHostedPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planItemPriceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'select_plan_item_hosted_page' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'plan_item_price_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'planItemPriceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pass_thru_content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expires_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'resource_version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'business_entity_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelectPlanItemHostedPageQuery, SelectPlanItemHostedPageQueryVariables>;
export const SelectPlanItemHostedPageAcknowledgeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SelectPlanItemHostedPageAcknowledge' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hostedPageId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'select_plan_item_hosted_page_acknowledge' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hosted_page_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hostedPageId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelectPlanItemHostedPageAcknowledgeQuery,
  SelectPlanItemHostedPageAcknowledgeQueryVariables
>;
export const GetBuyAgainPortfoliosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBuyAgainPortfolios' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buy_again_portfolios' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Portfolio_fromBuyAgainPortfolios' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio_fromBuyAgainPortfolios' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'public_id' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetBuyAgainPortfoliosQuery, GetBuyAgainPortfoliosQueryVariables>;
export const GetPortfolioDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPortfolio' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolio' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portfolio' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Portfolio_fromGetPortfolio' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'keyFacts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'KeyFact_fromGetPortfolio' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio_fromGetPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocated_weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reserved_weight_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coordinates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalAllocated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeyFact_fromGetPortfolio' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PortfolioKeyFact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key_facts' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPortfolioQuery, GetPortfolioQueryVariables>;
export const GetPortfoliosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPortfolios' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'has_offsets_available' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portfolios' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'has_offsets_available' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'has_offsets_available' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Portfolio_fromGetPortfolios' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Portfolio_fromGetPortfolios' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Portfolio' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price_per_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allocated_weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reserved_weight_kg' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allocations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'project' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'project_type' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'distribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalAllocated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
                { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPortfoliosQuery, GetPortfoliosQueryVariables>;
export const GetProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Project_fromGetProject' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_fromGetProject' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'developer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_short' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonplan_assessment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'data_stringify' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sdgs' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectQuery, GetProjectQueryVariables>;
export const GetProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjects' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Project_fromGetProjects' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_fromGetProjects' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Project' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'developer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_short' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'carbonplan_assessment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'data_stringify' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sdgs' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'avoidance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_date' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectsQuery, GetProjectsQueryVariables>;
export const DeleteReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteReportMutation, DeleteReportMutationVariables>;
export const GetReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReportQuery, GetReportQueryVariables>;
export const GetReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortedOrderEnum' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'size' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sorted_order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortedOrder' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Report_fromGetReports' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total_count' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Report_fromGetReports' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Report' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prepared_for' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReportsQuery, GetReportsQueryVariables>;
export const GenerateGhgReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'generateGhgReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'baseFilters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgReportFiltersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generate_ghg_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'baseFilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'baseFilters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgResultsFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emissions_range_start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emissions_range_end_date' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgTypeBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TypeBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'biogenic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'methodology' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geocoding_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'routing_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'methodology_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2e' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_3' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_3' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateGhgReportQuery, GenerateGhgReportQueryVariables>;
export const GetGhgReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGhgReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepared_for' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version_number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'client_identifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgResultsFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgTypeBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TypeBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'biogenic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'methodology' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geocoding_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'routing_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'methodology_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2e' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_3' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_3' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGhgReportQuery, GetGhgReportQueryVariables>;
export const SaveGhgReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveGhgReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'baseFilters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgReportFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SaveReportInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'generatedDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTimeISO' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'save_ghg_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'baseFilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'baseFilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'generatedDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'generatedDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgResultsFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgTypeBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TypeBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'biogenic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'methodology' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revision' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geocoding_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'routing_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'methodology_breakdown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgBreakdownItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgBreakdownItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GhgResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GhgReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'total_co2e' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scope_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'co2e' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_3' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgTypeBreakdownItem' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ghg_breakdown' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_1' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scope_3' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GhgBreakdownItem' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveGhgReportMutation, SaveGhgReportMutationVariables>;
export const GenerateGlecReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'generateGlecReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'baseFilters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportFiltersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generate_glec_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'baseFilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'baseFilters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecResultsFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emissions_range_start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emissions_range_end_date' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecBreakdownFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecReportByModeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportByMode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'main_data_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_scope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_carriage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_data_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecReportByModeFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateGlecReportQuery, GenerateGlecReportQueryVariables>;
export const GetGlecReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGlecReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'glec_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepared_for' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version_number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'client_identifier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'supply_chain_coverage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'confirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'confirmed_text' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecResultsFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecBreakdownFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecReportByModeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportByMode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'main_data_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_scope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_carriage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_data_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecReportByModeFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGlecReportQuery, GetGlecReportQueryVariables>;
export const SaveGlecReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveGlecReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'baseFilters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SaveReportInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'generatedDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTimeISO' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'save_glec_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'baseFilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'baseFilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'generatedDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'generatedDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecResultsFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecBreakdownFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportBreakdown' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecReportByModeFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportByMode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'main_data_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GlecResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GlecReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_scope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_carriage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecBreakdownFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_data_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report_by_mode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GlecReportByModeFields' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveGlecReportMutation, SaveGlecReportMutationVariables>;
export const GenerateItemisedEmissionsReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'generateItemisedEmissionsReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'baseFilters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemisedEmissionsReportFiltersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generate_itemised_emissions_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'baseFilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'baseFilters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemisedEmissionsResultsFields' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emissions_range_start_date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emissions_range_end_date' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemisedEmissionsResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemisedEmissionsReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipment_leg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_shipment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipment_identifier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'from_coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'to_coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quality' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'breakdown_by_type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GenerateItemisedEmissionsReportQuery, GenerateItemisedEmissionsReportQueryVariables>;
export const GetItemisedEmissionsReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getItemisedEmissionsReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemised_emissions_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prepared_for' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version_number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'client_identifier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shipment_identifier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemisedEmissionsResultsFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client_user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemisedEmissionsResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemisedEmissionsReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipment_leg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_shipment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipment_identifier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'from_coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'to_coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quality' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'breakdown_by_type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetItemisedEmissionsReportQuery, GetItemisedEmissionsReportQueryVariables>;
export const SaveItemisedEmissionsReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveItemisedEmissionsReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'baseFilters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemisedEmissionsReportFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SaveReportInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'generatedDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTimeISO' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'save_itemised_emissions_report' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'baseFilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'baseFilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'generatedDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'generatedDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemisedEmissionsResultsFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemisedEmissionsResultsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemisedEmissionsReportResults' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_shipment_leg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_activity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total_intensity_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overall_quality' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'percentage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'breakdown_by_shipment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shipment_identifier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'from_coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'to_coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
                { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
                { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity_unit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intensity_unit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quality' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'breakdown_by_type' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveItemisedEmissionsReportMutation, SaveItemisedEmissionsReportMutationVariables>;
export const UpdateReportNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateReportName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportName' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_report_name' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'report_name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateReportNameMutation, UpdateReportNameMutationVariables>;
export const UpdateReportVisibilityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateReportVisibility' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportVisibilityEnum' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_report_visibility' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publicId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'visibility' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'visibility' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateReportVisibilityMutation, UpdateReportVisibilityMutationVariables>;
export const GetUntrackedEmissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUntrackedEmission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'untracked_emission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'public_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'public_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Emission_fromGetEmission' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionQuality' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'overall' } },
          { kind: 'Field', name: { kind: 'Name', value: 'origin_destination' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_intensity_factor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionParentQuality' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overall' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionIssue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'param' } },
          { kind: 'Field', name: { kind: 'Name', value: 'input_value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assumed_value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recommendations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'params' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'carriage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fuel_identifier' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'source' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vessel_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'carrier_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emission_standard' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'empty_running' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cargo_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'is_refrigerated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_country_name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_country_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stop_info' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'code_alpha_2' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreightAggregated' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'start_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_consumption_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_used_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'load_factor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fleet_id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFlights' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cabin_class' } },
          { kind: 'Field', name: { kind: 'Name', value: 'airline' } },
          { kind: 'Field', name: { kind: 'Name', value: 'aircraft' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flight_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_code' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionLastMile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionRideHailing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vehicle_category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fuel_type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'make' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'from_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'from_display' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to_address' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'to_coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coordinates' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'to_display' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'path' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ch4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'n2o' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nox' } },
          { kind: 'Field', name: { kind: 'Name', value: 'so2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nmhc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subtype' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EmissionFreight' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Base_FreightEmission_fromGetEmission' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'route_details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'is_urban' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_motorway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_ferry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_train' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country_code_alpha_2' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'issues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionIssue_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight_aggregated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightAggregatedEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SegmentEmissions_fromGetEmission' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Emission_fromGetEmission' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Emission' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'import_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'co2e' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intensity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity_normalized_unit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'distance_km' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence_no' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meta_data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weight_kg' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teu' } },
          { kind: 'Field', name: { kind: 'Name', value: 'no_of_pax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'context_stringify' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_quality' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionParentQuality_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emission_children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ChildEmissions_fromGetEmission' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last_mile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastMileEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ride_hailing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RideHailingEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FlightsEmission_fromGetEmission' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FreightEmissionWithStops_fromGetEmission' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source_type_name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'key' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmissionDetail_fromGetEmission' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'has_parent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_public_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_child_count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'share_token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipment_reference_number_metadata_value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUntrackedEmissionQuery, GetUntrackedEmissionQueryVariables>;
export const CreateProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateProfileInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'create_profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'job_function' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'account_state' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'redirect_to' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProfileMutation, CreateProfileMutationVariables>;
export const DeleteMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pass' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'delete_me' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pass' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pass' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteMeMutation, DeleteMeMutationVariables>;
export const UpdateEmissionImportDefaultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEmissionImportDefaults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date_format' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'number_format' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_emission_import_defaults' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date_format' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'date_format' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'number_format' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'number_format' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date_format' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number_format' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEmissionImportDefaultsMutation, UpdateEmissionImportDefaultsMutationVariables>;
export const UpdateLanguageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateLanguage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'LanguageEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_language' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'language' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateLanguageMutation, UpdateLanguageMutationVariables>;
export const UpdateMeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMe' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_me' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eTag' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bucket' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMeMutation, UpdateMeMutationVariables>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ComputeFreightEmissions: [
      'ComputeFreightEmissionsWithDistanceAndWeightAndVehicle',
      'ComputeFreightEmissionsWithLocationsAndWeightAndVehicle',
    ],
    DataCollectionRequestActivity: [
      'DataCollectionRequestAcceptedActivity',
      'DataCollectionRequestApprovedActivity',
      'DataCollectionRequestCancelledActivity',
      'DataCollectionRequestDataStagedActivity',
      'DataCollectionRequestDeclinedActivity',
      'DataCollectionRequestDeliveryFailedActivity',
      'DataCollectionRequestRejectedActivity',
      'DataCollectionRequestRemindedActivity',
      'DataCollectionRequestRequestFailedActivity',
      'DataCollectionRequestRequestedActivity',
      'DataCollectionRequestSubmittedActivity',
      'DataCollectionRequestViewedActivity',
    ],
    FreightRoute: ['FreightRouteStops', 'FreightRouteVia'],
    LocationFreight: ['LocationAddress', 'LocationCode', 'LocationCoordinates'],
    ViaLocation: ['LocationCode', 'LocationCoordinates'],
  },
};
export default result;
